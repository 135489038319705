import './AnalyticsPage.css';
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import AnimatedNumber from 'animated-number-react';
import { FiltersPopup } from './FiltersPopup/FiltersPopup';
import { useProject } from '../../provider/ProjectProvider';
import { parseResponse, sortByDate } from '../../services/analytics.service';
import { useFetchAnalyticsMutation } from '../../services/analyticsService';
import { useNotification } from '../../hooks/useNotification';
import { useLoader } from '../../provider/LoaderProvider';
import { AreaCharts } from './AreaChart/AreaChart';
import Multiselect from 'multiselect-react-dropdown';
import constants from './constants';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../components/CardContainer/CardContainer';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useAppSelector } from '../../store';
import { ProjectSelectFilterPopup } from '../../components/ProjectSelectFilterPopup/ProjectSelectFilterPopup';
import { useTheme } from '../../provider/ThemeProvider';
import { clsx } from 'clsx';
import { getErrorMessage } from '../../utils/get-error-message';
// import {BarChart2} from "./BarChart/BarChart";

const AnalyticsPage = () => {
  const { projects, getAllTeamMembers } = useProject();
  const notification = useNotification();
  const loader = useLoader();
  const [teamMembers, setTeamMembers] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [incomingTickets, setIncomingTickets] = useState(0);
  const [resolvedTickets, setResolvedTickets] = useState(0);
  const [request, setRequest] = useState(constants.initialRequest);
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { setTheme } = useTheme();
  const [
    fetchAnalytics,
    {
      data: fetchAnalyticsRes,
      isLoading,
      isError: isFetchAnalyticsError,
      error: fetchAnalyticsError,
      isSuccess,
      isFetching,
    },
  ] = useFetchAnalyticsMutation();
  const { projectId = projects[0].id, projectType } = useAppSelector(
    (s) => s.projectsSlices,
  );

  const agentRef = useRef();
  const formatSummary = (value) => parseInt(value.toFixed(0));

  // use MutationObserver to track on dropdown list closed
  useEffect(() => {
    if (!agentRef.current) {
      return;
    }

    const observer = new MutationObserver(() => {
      const isHidden =
        agentRef.current.searchWrapper.current.nextElementSibling.classList.contains(
          'displayNone',
        );

      if (isHidden) {
        const agents = agentRef.current.getSelectedItems();

        if (!agents.length) {
          return;
        }

        const isSubdomainMetric = request.metrics[0].startsWith('subdomain_');
        const isTeamPerformance = agents[0].full_name === 'Team Performance';

        if (isTeamPerformance) {
          setRequest({
            ...request,
            metrics: isSubdomainMetric
              ? request.metrics
              : [`subdomain_${request.metrics[0]}`],
            agents: teamMembers
              .map((user) => {
                const currentProjectCrmData = user.crm_data.find(
                  (data) => data.project_id === +projectId,
                );

                return (
                  currentProjectCrmData && currentProjectCrmData.crm_user_id
                );
              })
              .filter(Boolean),
          });

          return;
        }

        setRequest({
          ...request,
          metrics: isSubdomainMetric
            ? [request.metrics[0].replace('subdomain_', '')]
            : request.metrics,
          agents: agents
            .map((user) => {
              const currentProjectCrmData = user.crm_data.find(
                (data) => data.project_id === +projectId,
              );

              return currentProjectCrmData && currentProjectCrmData.crm_user_id;
            })
            .filter(Boolean),
        });
      }
    });
    observer.observe(
      agentRef.current.searchWrapper.current.nextElementSibling,
      { attributes: true },
    );

    return () => observer.disconnect();
  });

  useEffect(() => {
    (async () => {
      loader.show();
      const response = await getAllTeamMembers(projectId);
      loader.hide();

      if (response instanceof Error) {
        return response.message && notification.error(response.message);
      }

      const crmUsers = response.filter((user) => {
        const crmData = user.user.crm_data || [];
        const currentProjectCrmData = crmData.find(
          (data) => data.project_id === +projectId,
        );

        return Boolean(
          currentProjectCrmData && currentProjectCrmData.crm_user_id,
        );
      });

      setTeamMembers(
        crmUsers.map(({ user }) => {
          return {
            ...user,
            full_name: `${user.first_name} ${user.last_name}`,
          };
        }),
      );

      const agents = crmUsers.map((user) => {
        const crmData = user.user.crm_data;
        const currentProjectCrmData = crmData.find(
          (data) => data.project_id === +projectId,
        );

        return currentProjectCrmData.crm_user_id;
      });

      setRequest({
        ...request,
        agents,
        project_id: +projectId,
      });
    })();
  }, [projectId]);

  useEffect(() => {
    const isEmptyAgent = !Boolean(request.agents && request.agents.length);

    if (isEmptyAgent) {
      return clearPage();
    }

    fetchAnalytics(request);
  }, [request]);

  useEffect(() => {
    if (isLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      let parsedResponse;
      const isEmptyData = !Boolean(
        fetchAnalyticsRes.data && Object.keys(fetchAnalyticsRes.data).length,
      );

      const channels = {
        incoming: fetchAnalyticsRes?.meta?.incoming_tickets?.channels,
        resolved: fetchAnalyticsRes?.meta?.resolved_tickets,
      };
      const tickets = {
        resolved: channels.resolved
          ? channels.resolved.web +
            channels.resolved.email +
            channels.resolved.api
          : 0,
        incoming: channels.incoming
          ? channels.incoming.web +
            channels.incoming.email +
            channels.incoming.api
          : 0,
      };

      if (isEmptyData) {
        clearPage();
        setResolvedTickets(tickets.resolved);
        setIncomingTickets(tickets.incoming);
        return;
      }
      setResolvedTickets(tickets.resolved);
      setIncomingTickets(tickets.incoming);

      if (fetchAnalyticsRes.isByUser) {
        parsedResponse = {};

        for (let key in fetchAnalyticsRes.parsedResponse) {
          const userStatistic = fetchAnalyticsRes.parsedResponse[key];
          const teamMember = teamMembers.find((teamMember) => {
            return teamMember.crm_data.find(
              (crmData) => crmData.crm_user_id === key,
            );
          });
          const fullName = `${teamMember.first_name} ${teamMember.last_name}`;

          parsedResponse[fullName] = userStatistic;
        }
      } else {
        parsedResponse = fetchAnalyticsRes.parsedResponse;
      }

      setAnalyticsData(parsedResponse);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isFetchAnalyticsError) {
      notification.error(getErrorMessage(fetchAnalyticsError));
    }
  }, [isFetchAnalyticsError]);

  useEffect(() => {
    setTheme('blue');
  }, []);

  const clearPage = () => {
    setAnalyticsData(null);
    setIncomingTickets(0);
    setResolvedTickets(0);
  };

  const onFiltersPopupClose = async (request) => {
    const agents = agentRef.current.getSelectedItems();
    const isTeamPerformance = agents[0].full_name === 'Team Performance';
    const isSubdomainMetric = request.metrics[0].startsWith('subdomain_');

    if (isTeamPerformance) {
      setRequest({
        ...request,
        metrics: isSubdomainMetric
          ? request.metrics
          : [`subdomain_${request.metrics[0]}`],
      });

      return;
    }

    setRequest({
      ...request,
      metrics: isSubdomainMetric
        ? [request.metrics[0].replace('subdomain_', '')]
        : request.metrics,
    });
  };

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx('analytics-page-container', !isLgDown && 'relative')}
        showFilter
        mobileFilterModal={<ProjectSelectFilterPopup id={projectId} />}
      >
        <div className="z-10 gap-[10px] flex flex-col relative">
          <CardContainer className="summary">
            <div className="card-header">
              <div className="title">Summary</div>
              <div className="date-container">
                {`${moment(request.start_date).format('ll')} - ${moment(request.end_date).format('ll')}`}
              </div>
            </div>
            <div className="card-body md:flex-row flex-col md:gap-[10px] gap-[6px]">
              {incomingTickets && resolvedTickets ? (
                <>
                  <div className={'summary-item'}>
                    <p>Incoming tickets</p>
                    <span>
                      <AnimatedNumber
                        value={incomingTickets}
                        formatValue={formatSummary}
                      />
                    </span>
                  </div>
                  <div className={'summary-item'}>
                    <p>Resolved tickets</p>
                    <AnimatedNumber
                      value={resolvedTickets}
                      formatValue={formatSummary}
                    />
                  </div>
                </>
              ) : (
                <div className={'summary-item'}>
                  <p>To display summary, start working in this project.</p>
                </div>
              )}
            </div>
            <div className={'card-footer'}></div>
          </CardContainer>
          <CardContainer>
            <div className="card-header">
              <div className="title">
                Statistics / {constants[request.metrics[0]]}
              </div>
              <div className="edit-button-container"></div>
            </div>
            <div className="card-body">
              <div className={'controls'}>
                <Multiselect
                  ref={agentRef}
                  className={'agents-multiselect'}
                  options={[{ full_name: 'Team Performance' }, ...teamMembers]} // Options to display in the dropdown
                  showCheckbox={true}
                  avoidHighlightFirstOption={true}
                  closeOnSelect={false}
                  hidePlaceholder={true}
                  placeholder={'Please Select Agents'}
                  selectedValues={[{ full_name: 'Team Performance' }]} // Preselected value to persist in dropdown
                  onSelect={(selectedList, selectedItem) => {
                    if (selectedItem.full_name === 'Team Performance') {
                      selectedList.length = 0;
                      selectedList.push(selectedItem);
                      return;
                    }

                    if (selectedList[0].full_name === 'Team Performance') {
                      selectedList.shift();
                    }
                  }}
                  selectedValueDecorator={(string) =>
                    string === 'Team Performance'
                      ? string
                      : string.split(' ')[0]
                  }
                  displayValue="full_name"
                />
                <FiltersPopup request={request} onClose={onFiltersPopupClose} />
              </div>
              <div className={'chart h-full w-full mt-4'}>
                {request.data_visualization === 'linear' && analyticsData && (
                  <AreaCharts
                    request={request}
                    data={analyticsData}
                    metric={request.metrics[0]}
                  />
                )}
                {/*{(request.data_visualization === 'histogram' && analyticsData) && <BarChart2 request={request} data={analyticsData}/> }*/}
              </div>
            </div>
            {!analyticsData && (
              <div className={'card-body'}>
                <div className={'summary-item'}>
                  <p>To display summary, start working in this project.</p>
                </div>
              </div>
            )}
            <div className="card-footer"></div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default AnalyticsPage;
