import moment from 'moment/moment';

const constants = {
  delay_reply_time: 'Delay Reply Time',
  ticket_closed_time: 'Full Resolution Time',
  reply_time: 'Reply Time',
  first_reply_time: 'First Reply Time',
  subdomain_ticket_closed_time: 'Full Resolution Time',
  subdomain_first_reply_time: 'First Reply Time',
  subdomain_delay_reply_time: 'Reply Time',
  initialRequest: {
    project_id: null,
    date_range: 'day',
    time_axis: 'minutes',
    start_date: moment('05/01/2024').format('YYYY-MM-DD'),
    end_date: moment('06/30/2024').format('YYYY-MM-DD'),
    type_of_statistic: 'quantiles',
    quantiles: ['Q4'],
    metrics: ['subdomain_ticket_closed_time'],
    agents: [],
    data_visualization: 'linear',
  },
};

export default constants;
