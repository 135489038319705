import React, { useState } from 'react';
import Button from '../Button/Button';
import { EditSubscriptionForm } from './EditSubscriptionForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import EditButtonIcon from 'src/assets/img/edit-button-icon.svg';

export const EditSubscriptionPopup = ({
  project,
  disabled = false,
  triggerIcon = (
    <Button className="transparent">
      <span className="max-md:hidden">Edit Subscription</span>
      <img src={EditButtonIcon} />
    </Button>
  ),
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        {triggerIcon}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit subscription plan</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <EditSubscriptionForm project={project} setOpen={setOpen} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            style={{ width: 'auto' }}
            type="submit"
            form="edit-sub-plan"
            className="blue"
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
