import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import Button from 'src/components/Button/Button';
import React from 'react';
import { CompanyIcon } from 'src/assets/icons/companyIcon';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { useTheme } from 'src/provider/ThemeProvider';
import { useAppSelector } from 'src/store';

export const CompanySelectModal = ({
  disabled,
  accounts,
  changeAccountHandler,
  storageSelectedAccount,
}) => {
  const { managedAccountId } = useAppSelector((s) => s.companySlices);

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      managedAccountId: managedAccountId,
    },
    resolver: yupResolver(
      object({
        managedAccountId: stringScheme({ required: false }).label(
          'Company Name',
        ),
      }),
    ),
  });
  const selectedAccountId = watch('managedAccountId');

  const { config: themeConfig } = useTheme();

  const resetHandler = (open) => {
    if (open) {
      setValue('managedAccountId', Number(managedAccountId));
    }
  };

  const submit = async (data) => {
    changeAccountHandler(data.managedAccountId);
  };

  const disableCondition =
    Number(selectedAccountId) === Number(managedAccountId);

  return (
    <Dialog onOpenChange={resetHandler}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <div className="flex max-h-[45px] h-screen items-center gap-2.5 py-3.5 px-2.5 border rounded border-ebony transition border-opacity-10 [&_p]:hover:opacity-100 hover:border-opacity-100 [&_svg]:hover:opacity-100">
          <CompanyIcon
            fill={'#05050D'}
            fillInner={themeConfig.color}
            className="opacity-50 transition"
          />
          <p
            title={storageSelectedAccount?.company_name}
            className="text-ebony opacity-50 text-xs font-medium transition max-w-40 overflow-hidden overflow-ellipsis text-nowrap"
          >
            {storageSelectedAccount
              ? storageSelectedAccount?.company_name
              : 'Select company'}
          </p>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Choose a company</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <form onSubmit={handleSubmit(submit)} id="company-form">
            <Controller
              control={control}
              name="managedAccountId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <RadioGroup
                  onChange={onChange}
                  onValueChange={onChange}
                  defaultValue={value}
                  className="flex flex-col space-y-1"
                >
                  {accounts.map((account) => (
                    <div
                      key={account.id}
                      className="flex items-center space-x-5 space-y-0"
                    >
                      <RadioGroupItem
                        value={account.account.id}
                        id={`${account.account.company_name}-${account.account.id}`}
                      />
                      <label
                        htmlFor={`${account.account.company_name}-${account.account.id}`}
                        className="cursor-pointer"
                      >
                        <p className="font-medium text-base text-ebony opacity-70 hover:opacity-100 transition">
                          {account.account.company_name}
                        </p>
                      </label>
                    </div>
                  ))}
                  <div
                    className={clsx(
                      'flex items-center space-x-5 space-y-0',
                      !managedAccountId && 'pointer-events-none opacity-40',
                    )}
                  >
                    <RadioGroupItem value={''} id="reset" />
                    <label htmlFor="reset" className="cursor-pointer">
                      <p className="font-medium text-base text-ebony opacity-70 hover:opacity-100 transition">
                        Reset
                      </p>
                    </label>
                  </div>
                </RadioGroup>
              )}
            />
          </form>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button className="transparent black" style={{ width: 'auto' }}>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild autoFocus type="submit" form="company-form">
            <Button
              disabled={disableCondition}
              className="blue"
              style={{ width: 'auto' }}
            >
              Choose
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
