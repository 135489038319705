import './AreaChart.css';
import React from 'react';
import moment from 'moment';
import {
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import constants from './../constants';

const colors = [
  '#233FFF',
  '#F01C68',
  '#FFD800',
  '#00AE84',
  '#E605FF',
  '#75F3FB',
  '#FF8A00',
  '#33F932',
  '#7B08C1',
  '#9A7B4D',
];

const assignZeros = (data, usersNames) => {
  return data.map((item) => {
    usersNames.forEach((userName) => {
      if (!item[userName]) {
        item[userName] = 0;
      }
    });

    return item;
  });
};
const getEmptyData = (startDate, endDate, type) => {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let result = [];

  for (let i = 0; i < diff; i++) {
    let date = moment(startDate).add(i, type).format('ll');
    result.push({
      date,
    });
  }

  return result;
};

export const AreaCharts = ({ request, data, metric }) => {
  const metricName = constants[metric];
  const isByUser = !Array.isArray(data);
  let chartData = [];
  let usersNames;

  if (isByUser) {
    Object.keys(data).forEach((userName) => {
      data[userName].forEach((userData) => {
        const chartDate = chartData.find((chartDate) => {
          return chartDate.date === userData.date;
        });

        if (chartDate) {
          chartDate[userName] = userData[metricName];
        } else {
          chartData.push({
            [userName]: userData[metricName],
            date: userData.date,
          });
        }
      });
    });
  } else {
    data.forEach((dataItem) => {
      const chartDate = chartData.find((chartDate) => {
        return chartDate.date === dataItem.date;
      });
      if (chartDate) {
        chartDate[metricName] = dataItem[metricName];
      } else {
        chartData.push({
          [metricName]: dataItem[metricName],
          date: dataItem.date,
        });
      }
    });
  }

  let usersnames = {};

  chartData.forEach((data) => {
    let { date, ...usernames } = data;
    usersnames = {
      ...usersnames,
      ...usernames,
    };
  });

  usersNames = Object.keys(usersnames);
  chartData = assignZeros(chartData, usersNames);

  const tooltipFormatter = (value, name, props) => {
    const duration = moment.duration(value, request['time_axis']);
    const isDayRange = request['time_axis'] === 'days';
    const days = duration.days();
    const utc = moment.utc(duration.asMilliseconds());

    if (days) {
      return utc.format(isDayRange ? `[${days}d]` : `[${days}d] HH:mm:ss`);
    }

    return utc.format(isDayRange && !days ? '[0d]' : 'HH:mm:ss');
  };

  return (
    chartData &&
    chartData.length && (
      <>
        <ResponsiveContainer width={'100%'} height={550}>
          <AreaChart
            width={'100%'}
            height={550}
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              {usersNames.map((name, index) => {
                return (
                  <linearGradient
                    key={`color${name.replace(/ /g, '')}`}
                    id={`color${name.replace(/ /g, '')}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={`${colors[index]}`}
                      stopOpacity={0.2}
                    />
                    <stop
                      offset="95%"
                      stopColor={`${colors[index]}`}
                      stopOpacity={0}
                    />
                  </linearGradient>
                );
              })}
            </defs>
            <XAxis dataKey="date" />
            <YAxis />
            <CartesianGrid vertical={false} strokeDasharray="1 1" />
            <Tooltip
              formatter={tooltipFormatter}
              labelStyle={{
                color: '#FFF',
                fontFamily: 'Bai Jamjuree',
                fontSize: '14px',
              }}
              contentStyle={{
                borderRadius: '5px',
                background: '#05050D',
              }}
              itemStyle={{
                fontFamily: 'Bai Jamjuree',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
              }}
            />
            <ReferenceLine
              x={
                chartData[parseInt((chartData.length / 2).toFixed(0)) - 1].date
              }
              stroke="#CCCCCC"
              strokeDasharray="1 1"
            />
            <Legend
              verticalAlign="top"
              height={36}
              iconSize={10}
              iconType="circle"
            />
            {usersNames.map((name, index) => {
              return (
                <Area
                  connectNulls
                  key={`area${name.replace(/ /g, '')}`}
                  type="linear"
                  dataKey={name}
                  stroke={`${colors[index]}`}
                  fillOpacity={1}
                  animationBegin={500}
                  fill={`url(#${`color${name.replace(/ /g, '')}`})`}
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>
      </>
    )
  );
};
