import React from 'react';

export const TooltipContentPlans = ({ item }) => {
  return (
    <div className="flex flex-col gap-1 hyphens-auto ">
      <p>
        <b>Description:</b> {item?.description}
      </p>
      <p>
        <b>Request limit:</b> {item?.request_limit}
      </p>
      <p>
        <b>Price:</b> {item?.price}
      </p>
      <p>
        <b>Plan type:</b> {item?.plan_type}
      </p>
      <p>
        <b>Extra request price:</b> {item?.extra_request_price}
      </p>
      <p>
        <b>Is unlimited:</b> {item?.is_unlimited ? 'Yes' : 'No'}
      </p>
    </div>
  );
};
