import React from 'react';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

const PageContentBreadcrumbs = ({
  projectBreadcrumbsContent,
  isProjectBreadcrumbs,
}) => {
  return (
    <div className="breadcrumbs py-4 z-40">
      <Breadcrumbs
        projectBreadcrumbsContent={projectBreadcrumbsContent}
        isProjectBreadcrumbs={isProjectBreadcrumbs}
      />
    </div>
  );
};

export default PageContentBreadcrumbs;
