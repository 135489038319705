import React, { useEffect, useState } from 'react';
import Input from '../Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useChangeSubscriptionMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useLoader } from 'src/provider/LoaderProvider';
import { useSubscriptionPlans } from 'src/hooks/useSubscriptionPlans';

export const EditSubscriptionForm = ({ project, setOpen }) => {
  const [plans, setPlans] = useState([]);
  const notification = useNotification();
  const loader = useLoader();

  const { Plans, plansAllIsFetching } = useSubscriptionPlans();

  const [
    changeSubscription,
    {
      isLoading: changeSubscriptionIsLoading,
      isError: changeSubscriptionIsError,
      error: changeSubscriptionError,
      isSuccess: changeSubscriptionIsSuccess,
    },
  ] = useChangeSubscriptionMutation();

  useEffect(() => {
    if (changeSubscriptionIsLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [changeSubscriptionIsLoading]);

  useEffect(() => {
    if (changeSubscriptionIsError) {
      notification.error(getErrorMessage(changeSubscriptionError));
    }
  }, [changeSubscriptionIsError]);

  useEffect(() => {
    if (changeSubscriptionIsSuccess) {
      notification.success('Project plan was successfully updated!');
      setOpen(false);
    }
  }, [changeSubscriptionIsSuccess]);

  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      project_plan: project.project_plan.plan.name,
      plan: {
        label: project.project_plan.plan.name,
        id: project.project_plan.plan.id,
        price: project.project_plan.plan.price,
        extra_request_price: project.project_plan.plan.extra_request_price,
        request_limit: project.project_plan.plan.request_limit,
        target_service: project.project_plan.plan.target_service,
      },
    },
    resolver: yupResolver(
      object({
        project_plan: stringScheme({ required: false }).label('Current Plan'),
        plan: object({
          label: stringScheme({ required: false }),
          id: stringScheme({ required: false }),
          price: stringScheme({ required: false }),
          extra_request_price: stringScheme({ required: false }),
          target_service: stringScheme({ required: false }),
          request_limit: stringScheme({ required: false }),
        })
          .required()
          .label('Plan'),
      }),
    ),
  });

  const planWatch = watch('plan');

  useEffect(() => {
    if (Boolean(Plans?.length)) {
      function sortPlan(a, b) {
        if (a.label === project.project_plan.plan.name) return -1;
        if (b.label === project.project_plan.plan.name) return 1;
        return 0;
      }
      const productPlanSort =
        Plans?.length &&
        Plans.filter(
          (item) =>
            item.target_service === project?.project_plan?.plan.target_service,
        );
      setValue('plan', {
        label: productPlanSort.sort(sortPlan)[0].label,
        id: productPlanSort.sort(sortPlan)[0].id,
      });

      setPlans(productPlanSort.sort(sortPlan));
    }
  }, [Plans]);

  const submit = (formValues) => {
    if (isDirty) {
      changeSubscription({
        new_plan_id: Number(formValues.plan.id),
        project_id: project.id,
        target_service: formValues.plan.target_service,
        changeable_project_plan_id: project.project_plan.plan.id,
      });
    } else {
      notification.info('Nothing changed');
    }
  };

  return (
    <form id="edit-sub-plan" onSubmit={handleSubmit(submit)}>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="project_plan"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Current Plan'}
              placeholder={'Current Plan'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
              readonly
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <LoaderWrapper
          isLoading={plansAllIsFetching}
          loader={
            <div className="flex flex-col">
              <label className="pt-1">Plan</label>
              <Skeleton className="w-full h-[52px]" />
            </div>
          }
        >
          <Controller
            control={control}
            name="plan"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'select'}
                selectOptions={plans}
                label={'New Plan'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={(e) => {
                  onChange({
                    label: e.label,
                    id: e.id,
                    price: e.price,
                    extra_request_price: e.extra_request_price,
                    request_limit: e.request_limit,
                    target_service: e.target_service,
                  });
                }}
                onBlur={() => trigger('plan')}
              />
            )}
          />
        </LoaderWrapper>
      </div>
      <div className={'input-container'}>
        <Input
          readonly
          type={'text'}
          label={'AI replies'}
          placeholder={''}
          value={
            planWatch?.request_limit ||
            project.project_plan.plan.request_limit ||
            ''
          }
          isValid
        />
      </div>
      <div className={'input-container'}>
        <Input
          readonly
          type={'text'}
          label={'Extra reply cost'}
          placeholder={''}
          value={
            planWatch?.extra_request_price ||
            project.project_plan.plan.extra_request_price ||
            ''
          }
          isValid
        />
      </div>
      <div className={'input-container'}>
        <Input
          readonly
          type={'text'}
          label={'Monthly Fee'}
          placeholder={'499 $'}
          value={planWatch?.price || project.project_plan.plan.price || ''}
          isValid
        />
      </div>
    </form>
  );
};
