import React from 'react';

export const FolderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
  >
    <path
      d="M2.4 12C2.015 12 1.68553 11.8655 1.4116 11.5964C1.13767 11.3274 1.00047 11.0036 1 10.625V2.375C1 1.99687 1.1372 1.67329 1.4116 1.40425C1.686 1.13521 2.01547 1.00046 2.4 1H6.6L8 2.375H13.6C13.985 2.375 14.3147 2.50975 14.5891 2.77925C14.8635 3.04875 15.0005 3.37233 15 3.75V10.625C15 11.0031 14.863 11.3269 14.5891 11.5964C14.3152 11.8659 13.9855 12.0005 13.6 12H2.4ZM2.4 10.625H13.6V3.75H7.4225L6.0225 2.375H2.4V10.625Z"
      fill="#05050D"
      stroke="#05050D"
      strokeWidth="0.2"
    />
  </svg>
);
