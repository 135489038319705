export const DateRangeOptions = [
  {
    value: 'day',
    label: 'Days',
  },
  {
    value: 'week',
    label: 'Weeks',
  },
  {
    value: 'month',
    label: 'Months',
  },
];
