export const PauseIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.3C11.4794 14.3 14.3 11.4794 14.3 8C14.3 4.52061 11.4794 1.7 8 1.7C4.52061 1.7 1.7 4.52061 1.7 8C1.7 11.4794 4.52061 14.3 8 14.3ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#05050D"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6999 11L5.6999 5L7.3999 5L7.3999 11L5.6999 11Z"
        fill="#05050D"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6999 11L8.6999 5L10.3999 5L10.3999 11L8.6999 11Z"
        fill="#05050D"
        fillOpacity="0.5"
      />
    </svg>
  );
};
