import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object } from 'yup';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import {
  useActivateSubscriptionMutation,
  useSubscriptionAddItemMutation,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { Checkbox } from 'src/components/ui/checkbox';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { stringScheme } from 'src/utils/validation-schemes';

const emptyVal = { id: null, target_service: '' };

export const ActivateSubscriptionPlanPopupForm = ({
  closePopup,
  projSubscriptions,
}) => {
  const notification = useNotification();
  const loader = useLoader();

  const { data: account, isError: accountIsError } = useGetAccountsQuery();

  const [
    activateSubscription,
    {
      isLoading: activateSubscriptionIsLoading,
      isError: activateSubscriptionIsError,
      error: activateSubscriptionError,
      isSuccess: activateSubscriptionIsSuccess,
    },
  ] = useActivateSubscriptionMutation();

  const [
    addSubscription,
    {
      isLoading: addSubscriptionIsLoading,
      isError: addSubscriptionIsError,
      error: addSubscriptionError,
      isSuccess: addSubscriptionIsSuccess,
    },
  ] = useSubscriptionAddItemMutation();

  useEffect(() => {
    if (activateSubscriptionIsLoading || addSubscriptionIsLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [activateSubscriptionIsLoading, addSubscriptionIsLoading]);

  useEffect(() => {
    if (activateSubscriptionIsError) {
      notification.error(getErrorMessage(activateSubscriptionError));
    }
    if (addSubscriptionIsError) {
      notification.error(getErrorMessage(addSubscriptionError));
    }
  }, [activateSubscriptionIsError, addSubscriptionIsError]);

  useEffect(() => {
    if (activateSubscriptionIsSuccess) {
      notification.success('Subscription plan was successfully activated!');
      closePopup();
    }
    if (addSubscriptionIsSuccess) {
      notification.success('Subscription plan was successfully activated!');
      closePopup();
    }
  }, [activateSubscriptionIsSuccess, addSubscriptionIsSuccess]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: !account?.stripe_subscription_id
      ? {
          subscription_items: [],
        }
      : {
          project_plan_id: emptyVal,
        },
    resolver: yupResolver(
      object(
        !account?.stripe_subscription_id
          ? {
              subscription_items: array(object({ project_plan_id: number() }))
                .min(1, 'You must select at least one project.')
                .required()
                .label('Subscription plan'),
            }
          : {
              project_plan_id: object({
                id: number().required().label('Subscription plan'),
                target_service: stringScheme({ required: true }),
              }),
            },
      ),
    ),
  });

  const submit = (formValues) => {
    if (!account?.stripe_subscription_id) {
      activateSubscription({
        subscription_items: formValues.subscription_items,
      });
    } else {
      addSubscription({
        project_plan_id: formValues.project_plan_id.id,
        target_service: formValues.project_plan_id.target_service,
      });
    }
  };

  const activateSubscriptionCheckBoxHandler = (
    checked,
    onChange,
    value,
    project,
  ) => {
    checked
      ? onChange([
          ...value,
          {
            project_plan_id: Number(project.project_plan.id),
          },
        ])
      : onChange(
          value?.filter(
            (value) => value.project_plan_id !== project.project_plan.id,
          ),
        );
  };

  const addSubscriptionCheckBoxHandler = (checked, onChange, project) => {
    onChange(
      checked
        ? {
            id: project.project_plan.id,
            target_service: project.project_plan.plan.target_service || '',
          }
        : emptyVal,
    );
  };

  return (
    <form id="add-subscription-plan-form" onSubmit={handleSubmit(submit)}>
      <div className={'input-container flex flex-col gap-5 relative'}>
        <p className="text-sm font-semibold text-ebony">Choose projects:</p>
        <Controller
          control={control}
          name={
            !account?.stripe_subscription_id
              ? 'subscription_items'
              : 'project_plan_id'
          }
          render={({ field: { onChange, value } }) =>
            projSubscriptions.map((project) => (
              <div key={project.id} className="flex items-center space-x-2">
                <Checkbox
                  id={`sub-${project.id}`}
                  checked={
                    !account?.stripe_subscription_id
                      ? value?.project_plan_id?.includes(project.id)
                      : value.id === project.project_plan.id
                  }
                  onCheckedChange={(checked) => {
                    !account?.stripe_subscription_id
                      ? activateSubscriptionCheckBoxHandler(
                          checked,
                          onChange,
                          value,
                          project,
                        )
                      : addSubscriptionCheckBoxHandler(
                          checked,
                          onChange,
                          project,
                        );
                  }}
                />
                <label
                  htmlFor={`sub-${project.id}`}
                  className="text-base text-ebony opacity-50 cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {project?.project_plan?.plan?.name} / {project?.name}
                </label>
              </div>
            ))
          }
        />
        {!account?.stripe_subscription_id
          ? errors?.subscription_items?.message && (
              <p className="text-sm font-semibold text-error absolute top-full mt-3">
                {errors.subscription_items.message}
              </p>
            )
          : errors?.project_plan_id?.id.message && (
              <p className="text-sm font-semibold text-error absolute top-full mt-3">
                {errors?.project_plan_id?.id.message}
              </p>
            )}
      </div>
    </form>
  );
};
