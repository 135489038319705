import React from 'react';
import PageContentBreadcrumbs from './PageContentBreadcrumbs';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { clsx } from 'clsx';
import { MenuBarComponent } from 'src/components/PageContentWrapper/MenuBarComponent';
import { ChangePasswordModal } from 'src/components/ChangePasswordModal/ChangePasswordModal';

const PageContentWrapper = ({
  className = '',
  children,
  mobileFilterModal,
  isMobileMarginTop = true,
  projectBreadcrumbsContent,
  isProjectBreadcrumbs,
}) => {
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const isAccountLocation = location.pathname.includes('account');

  return (
    <div
      className={clsx(
        className,
        'container relative z-1 flex flex-col gap-[10px] px-4 pb-[20px] md:px-[26px] md:pb-[40px]',
        isMobileMarginTop && isLgDown && 'mt-[-95px]',
        !isLgDown && 'mt-[-65px]',
      )}
    >
      {!isLgDown && <MenuBarComponent />}
      {isLgDown && (
        <>
          <div className="flex flex-row justify-between items-center z-10">
            <PageContentBreadcrumbs
              isProjectBreadcrumbs={isProjectBreadcrumbs}
              isLgDown={isLgDown}
              projectBreadcrumbsContent={projectBreadcrumbsContent}
            />
            {mobileFilterModal}
            {isAccountLocation && <ChangePasswordModal isMobile />}
          </div>
          <div
            className={clsx(
              'z-0 w-full h-full bg-[rgba(241,246,246,0.8)] backdrop-blur-[20px] rounded-t-2xl absolute left-0',
              !isMobileMarginTop && '!-z-10',
            )}
          />
        </>
      )}
      {children}
    </div>
  );
};

export default PageContentWrapper;
