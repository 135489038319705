import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import React, { useEffect, useRef } from 'react';
import Button from '../../../components/Button/Button';
import { useParams } from 'react-router-dom';
import { AddIcon } from '../../../assets/img/add-icon';
import { CreateProjectForm } from './CreateProjectForm';
import CloseIcon from '../../../assets/img/close-icon';

const CreateProjectModalButton = () => {
  return (
    <Button className="blue !text-white !h-16 !mt-0 !rounded-md flex !justify-between">
      <span>Create new project</span>
      <AddIcon className="mr-2" strokeColor={'#07B5C0'} />
    </Button>
  );
};
export const CreateProjectModal = () => {
  const { id } = useParams();
  const closeRef = useRef(null);

  useEffect(() => {
    if (id) {
      closeRef.current();
    }
  }, [id, closeRef]);

  return (
    <>
      <Popup trigger={CreateProjectModalButton} modal nested lockScroll={true}>
        {(close) => {
          closeRef.current = close;
          return (
            <div className="modal">
              <div className="header">
                <div className="title">Create and run your new project</div>
                <div className="close-button" onClick={close}>
                  <CloseIcon stroke="#B8C1C6" />
                </div>
              </div>
              <div className="content flex flex-col gap-2.5">
                <CreateProjectForm cancelFunc={close} />
              </div>
            </div>
          );
        }}
      </Popup>
    </>
  );
};
