import React, { useEffect, useState } from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import AnimateHeight from 'react-animate-height';
import { CurrentSubscriptionPlans } from './CurrentSubscriptionPlans';
import { ExpandIcon } from 'src/assets/icons/expandIcon';
import { CurrentSubscriptionPlanPopup } from './CurrentSubscriptionPlanPopup/CurrentSubscriptionPlanPopup';
import { useNotification } from 'src/hooks/useNotification';
import {
  useGetProjectsSubscriptionsQuery,
  useGetSubscriptionPermissionQuery,
  useSetSubscriptionPermissionMutation,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { ActivateSubscriptionPlanPopup } from './ActivateSubscriptionPlan/ActivateSubscriptionPlanPopup';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { Switch } from 'src/components/ui/switch';
import { useLoader } from 'src/provider/LoaderProvider';
import { useAppSelector } from 'src/store';

export const CurrentSubscriptionPlansSection = () => {
  const [subscriptionHeight, setSubscriptionHeight] = useState('auto');
  const [permission, setPermission] = useState(false);

  const { companyChanged } = useAppSelector((s) => s.companySlices);

  const loader = useLoader();
  const notification = useNotification();

  const { data: userInfo } = useGetUsersMeQuery();

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  const {
    data: subPermissionData,
    isFetching: subPermissionDataIsFetching,
    isError: subPermissionDataIsError,
    error: subPermissionDataError,
  } = useGetSubscriptionPermissionQuery({});

  const [
    setSubPermission,
    {
      isLoading: setSubPermissionIsLoading,
      isError: setSubPermissionIsError,
      error: setSubPermissionError,
      isSuccess: setSubPermissionIsSuccess,
    },
  ] = useSetSubscriptionPermissionMutation();

  const {
    data: projectsSubscriptions = [],
    error: projectsSubscriptionsError,
    isError: projectsSubscriptionsIsError,
    isFetching: projectsSubscriptionsIsFetching,
  } = useGetProjectsSubscriptionsQuery(
    {},
    { refetchOnMountOrArgChange: companyChanged },
  );

  useEffect(() => {
    if (setSubPermissionIsLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [setSubPermissionIsLoading]);

  useEffect(() => {
    if (subPermissionData) {
      setPermission(subPermissionData.approved);
    }
  }, [subPermissionData]);

  useEffect(() => {
    if (projectsSubscriptionsIsError) {
      notification.error(getErrorMessage(projectsSubscriptionsError));
    }
    if (setSubPermissionIsError) {
      notification.error(getErrorMessage(setSubPermissionError));
    }
    if (subPermissionDataIsError) {
      notification.error(
        `Get subscription permissions error: ${getErrorMessage(subPermissionDataError)}`,
      );
    }
  }, [
    projectsSubscriptionsIsError,
    setSubPermissionIsError,
    subPermissionDataIsError,
  ]);

  useEffect(() => {
    if (setSubPermissionIsSuccess) {
      notification.success('Permission was changed successfully!');
    }
  }, [setSubPermissionIsSuccess]);

  const projSubscriptions = projectsSubscriptions?.filter(
    (project) => !!project?.project_plan,
  );

  const projSubscriptionsFilteredByActive = projSubscriptions?.filter(
    (project) =>
      project?.project_plan.status !== 'active' &&
      project?.project_plan.status !== 'pending',
  );

  const changePermissionHandler = (val) => {
    setPermission(val);
    setSubPermission({ approved: val });
  };

  const disabledActiveSubBtn =
    !isCosupportManager && !subPermissionData?.approved;

  return (
    <CardContainer className="integration">
      <div className="card-header z-50">
        <div
          className={subscriptionHeight ? 'title' : 'title collapsed'}
          onClick={() => setSubscriptionHeight(subscriptionHeight ? 0 : 'auto')}
        >
          <div className={'yellow-counter'}>
            {projSubscriptions ? projSubscriptions?.length : 0}
          </div>
          <span>Current subscription plans</span> <ExpandIcon />
        </div>
        <div className="add-button-container">
          <CurrentSubscriptionPlanPopup />
        </div>
      </div>
      <AnimateHeight height={subscriptionHeight}>
        <div className="card-body">
          <CurrentSubscriptionPlans
            projSubscriptions={projSubscriptions}
            projectsSubscriptions={projectsSubscriptions}
            projectsSubscriptionsIsFetching={projectsSubscriptionsIsFetching}
          />
        </div>
      </AnimateHeight>
      {Boolean(projSubscriptions?.length) && (
        <div className="flex gap-2.5 flex-col items-center justify-center">
          <ActivateSubscriptionPlanPopup
            disabled={
              !Boolean(projSubscriptionsFilteredByActive?.length) ||
              disabledActiveSubBtn
            }
            projSubscriptions={projSubscriptionsFilteredByActive}
          />
          {isCosupportManager && (
            <div className="flex items-center space-x-2">
              <Switch
                disabled={subPermissionDataIsFetching}
                id="airplane-mode"
                checked={permission}
                onCheckedChange={changePermissionHandler}
              />
              <label htmlFor="airplane-mode">
                Project plan subscription permission
              </label>
            </div>
          )}
        </div>
      )}
    </CardContainer>
  );
};
