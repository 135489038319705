import React, { useState } from 'react';
import Button from '../../Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { FolderIcon } from 'src/assets/icons/FolderIcon';
import Input from 'src/components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';

export const AddFolderPopup = ({
  addFolder,
  activeBranch,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const folders = activeBranch.children.filter(({ type }) => type === 'folder');
  const {
    handleSubmit,
    control,
    setError,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      folder_name: '',
    },
    resolver: yupResolver(
      object({
        folder_name: stringScheme({ required: true, minLength: 3 }).label(
          'Folder name',
        ),
      }),
    ),
  });

  const submit = ({ folder_name }) => {
    const exist = folders.some((folder) => folder.name === folder_name);

    if (exist) {
      return setError('folder_name', {
        type: 'manual',
        message: `folder already exists!`,
      });
    }

    addFolder(folder_name);
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          disabled={disabled}
          asChild
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer',
          )}
        >
          <Button className="transparent black gap-[10px]">
            Add folder
            <FolderIcon />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New folder</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="content">
              <form id="add-new-folder" onSubmit={handleSubmit(submit)}>
                <div className={'input-container'}>
                  <Controller
                    control={control}
                    name="folder_name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        type={'text'}
                        label={'Folder Name'}
                        placeholder={'New Folder Name'}
                        invalidMessage={error?.message}
                        value={value}
                        isValid={!error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </form>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button style={{ width: 'auto' }} className="transparent black">
                Cancel
              </Button>
            </DialogClose>
            <Button
              style={{ width: 'auto' }}
              type="submit"
              form="add-new-folder"
              className="purple"
            >
              Done
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
