import React, { useState } from 'react';
import MenuIcon from '../../../assets/img/menu-icon';
import { DrawerComponent } from 'src/components/ui/drawer';
import { Menu } from 'src/components/Menu/Menu';

export const MobileMenuDrawer = () => {
  const [open, setOpen] = useState(false);

  return (
    <DrawerComponent
      setOpen={setOpen}
      open={open}
      darkMode
      drawerTrigger={
        <button className={`h-[20px] w-[20px] p-0`}>
          <MenuIcon className={'burger-icon'} />
        </button>
      }
      drawerContent={<Menu setOpenMobileMenu={setOpen} openMobileMenu={open} />}
    />
  );
};
