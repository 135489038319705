import React from 'react';

export const ExpandIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.6569 12.3431L10 6.68629L4.34315 12.3431"
      stroke="#05050D"
      strokeWidth="2.5"
    />
  </svg>
);
