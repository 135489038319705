import React from 'react';
import AddZendeskIcon from '../../assets/img/add-integration-zendesk-icon.svg';
import { clsx } from 'clsx';

export const AddIntegrationListItem = ({ icon, name, onClick, disabled }) => {
  return (
    <li
      className={clsx(
        disabled && 'pointer-events-none opacity-50',
        'add-integration-list-item',
      )}
      onClick={disabled ? () => {} : onClick}
    >
      <div className="add-integration-icon-container">
        <img src={icon} width={'50px'} />
      </div>
      <div className="add-integration-list-item-text">{name}</div>
      <div className="add-integration-list-item-add">
        <p>Add</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path d="M10 20.5L10 0.500001" stroke="#07B5C0" strokeWidth="2" />
          <line
            x1="1.60275e-07"
            y1="10.3182"
            x2="20"
            y2="10.3182"
            stroke="#07B5C0"
            strokeWidth="2"
          />
        </svg>
      </div>
    </li>
  );
};
