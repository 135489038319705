import React, { useState } from 'react';
import Button from '../../../../components/Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { EditIcon } from 'src/assets/icons/editIcon';

export const ExtendCurrentSubscriptionPlanPopup = ({
  disabled = false,
  submitHandler = () => {},
  title = 'Extend project?',
  description = <p>Are you sure you want to extend project?</p>,
  triggerIcon = (
    <div className="edit-button">
      <EditIcon />
    </div>
  ),
}) => {
  const [open, setOpen] = useState(false);

  const submit = () => {
    submitHandler();
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        {triggerIcon}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">{description}</div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button onClick={submit} style={{ width: 'auto' }} className="green">
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
