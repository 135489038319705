const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH_SPA = {
  ROOT: ROOTS_AUTH,
};

export const PATH_DASHBOARD = {
  ROOT: ROOTS_DASHBOARD,
  PROJECTS: `${ROOTS_DASHBOARD}/projects`,
  PROJECTS_NEW: `${ROOTS_DASHBOARD}/projects/new`,
  ACCOUNT: `${ROOTS_DASHBOARD}/account`,
  SUBSCRIPTION: `${ROOTS_DASHBOARD}/subscription`,
  ANALYTICS: `${ROOTS_DASHBOARD}/analytics`,
};
