import React from 'react';
import { MetricsOptions } from './MetricsOptions';

export const MetricsFilter = ({ value, onChange }) => {
  return (
    <>
      <ul className={'filter-list'}>
        {MetricsOptions.map((metric) => {
          const id = `metric${metric.label.replace(/ /g, '')}`;

          return (
            <li key={metric.value} className={'filter-list-item'}>
              <label htmlFor={id}>
                <input
                  name={'metrics'}
                  value={metric.value}
                  id={id}
                  type="radio"
                  checked={value === metric.value}
                  onChange={() => onChange(metric.value)}
                />
                <p>{metric.label}</p>
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};
