import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../components/CardContainer/CardContainer';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'src/services';
import { AddIntegrationPopup } from 'src/components/AddIntegrationPopup/AddIntegrationPopup';
import { IntegrationsList } from 'src/components/IntegrationsList/IntegrationsList';
import { getErrorMessage } from 'src/utils/get-error-message';

const IntegrationsPage = () => {
  const notification = useNotification();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { id } = useParams();

  const {
    data: currentProject,
    isFetching: isFetchingProject,
    isError,
    error,
  } = useGetProjectByIdQuery({ id: id });

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx(
          'knowledge-base-page-container',
          !isLgDown && 'relative',
        )}
      >
        <div className="z-10 gap-[10px] flex flex-col relative">
          <CardContainer className="integration">
            <div className="card-header">
              <div className="title">
                <span>Integrations</span>
              </div>
              <div className="add-button-container">
                {currentProject && !currentProject.integration && (
                  <AddIntegrationPopup project={currentProject} />
                )}
              </div>
            </div>
            <div className="card-body">
              <IntegrationsList
                isFetchingProject={isFetchingProject}
                isEmpty={Boolean(currentProject?.integration)}
                integrations={[currentProject?.integration]}
              />
            </div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default IntegrationsPage;
