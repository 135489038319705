import { baseApi } from './base.api';
import { ACCOUNTS_URL } from '../constants/api-routes';

export const accountsService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query({
      query: () => ({
        url: `${ACCOUNTS_URL}`,
      }),
      providesTags: ['Accounts'],
    }),
    updateAccounts: build.mutation({
      query: (body) => ({
        url: `${ACCOUNTS_URL}`,
        method: 'PUT',
        body: body,
      }),
      async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedAccount } = await queryFulfilled;
          dispatch(
            accountsService.util.updateQueryData(
              'getAccounts',
              undefined,
              (draft) => {
                Object.assign(draft, updatedAccount);
              },
            ),
          );
        } catch {}
      },
    }),
  }),
});

export const { useGetAccountsQuery, useUpdateAccountsMutation } =
  accountsService;
