import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { baseApi } from '../services';
import { useDispatch, useSelector } from 'react-redux';

import { unauthorizedMiddleware } from './middleware/unauthorized-middleware';
import projectsSlices from './slices/projectsSlices';
import companySlice from './slices/companySlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'company',
  storage,
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  projectsSlices,
  companySlices: persistReducer(persistConfig, companySlice),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      unauthorizedMiddleware,
      baseApi.middleware,
    ]),
});

export const persistor = persistStore(store);
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
