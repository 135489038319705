import Input from '../../Input/Input';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';

export const IntegrationFormZohoSalesIqChat = ({ integration, onSubmit }) => {
  const notification = useNotification();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      project_name: integration?.integration_data?.name,
    },
    resolver: yupResolver(
      object({
        project_name: stringScheme({ required: true }).label('Project Name'),
      }),
    ),
  });

  const submit = (data) => {
    if (isDirty) {
      onSubmit({
        ...integration,
        integration_data: {
          ...integration.integration_data,
          project_name: data.project_name,
        },
      });
    } else {
      notification.info('Nothing changed');
    }
  };

  return (
    <form id="integration-form" onSubmit={handleSubmit(submit)}>
      <div className="content">
        <Controller
          control={control}
          name="project_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Project Name'}
              placeholder={'Project'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      {/*<div className={'input-container'}>*/}
      {/*  <Input*/}
      {/*    type={'text'}*/}
      {/*    label={'Integration Type'}*/}
      {/*    placeholder={'Integration Type'}*/}
      {/*    value={integration.integration_type}*/}
      {/*    readonly={true}*/}
      {/*    isValid={true}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={'input-container'}>*/}
      {/*  <Input*/}
      {/*    type={'text'}*/}
      {/*    label={'Credits'}*/}
      {/*    placeholder={'Credits'}*/}
      {/*    value={integration.integration_data.credits}*/}
      {/*    readonly={true}*/}
      {/*    isValid={true}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={'input-container'}>*/}
      {/*  <Input*/}
      {/*    type={'text'}*/}
      {/*    label={'Subdomain'}*/}
      {/*    placeholder={'Subdomain'}*/}
      {/*    value={subdomain}*/}
      {/*    invalidMessage={subdomain ? 'Invalid Format' : 'Required'}*/}
      {/*    isValid={isSubdomainValid}*/}
      {/*    onChange={(event) => setSubdomain(event.currentTarget.value)}*/}
      {/*    onBlur={() => setIsSubdomainValid(subdomain && subdomain.length > 2)}*/}
      {/*    onFocus={() => setIsSubdomainValid(true)}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className="sub-title items-start md:flex-row flex-col gap-[16px] md:gap-[20px]">*/}
      {/*  <h4>Credentials:</h4>*/}
      {/*</div>*/}
      {/*<div className={'input-container'}>*/}
      {/*  <Input*/}
      {/*    type={'email'}*/}
      {/*    label={'Email'}*/}
      {/*    placeholder={'email@email.com'}*/}
      {/*    invalidMessage={email ? 'Invalid Format' : 'Required'}*/}
      {/*    value={email}*/}
      {/*    isValid={isEmailValid}*/}
      {/*    onChange={(event) => setEmail(event.currentTarget.value)}*/}
      {/*    onBlur={() => validateEmail(false)}*/}
      {/*    onFocus={() => setIsEmailValid(true)}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={'input-container'}>*/}
      {/*  <Input*/}
      {/*    type={'password'}*/}
      {/*    label={'API Token'}*/}
      {/*    placeholder={'••••••••••••••••••••••••••••'}*/}
      {/*    invalidMessage={'Invalid'}*/}
      {/*    isValid={isApiTokenValid}*/}
      {/*    value={apiToken}*/}
      {/*    onChange={(event) => setApiToken(event.currentTarget.value)}*/}
      {/*    onBlur={() => setIsApiTokenValid(apiToken && apiToken.length > 2)}*/}
      {/*    onFocus={() => setIsApiTokenValid(true)}*/}
      {/*  />*/}
      {/*</div>*/}
    </form>
  );
};
