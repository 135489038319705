import React from 'react';

export const FileTxtIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38775 0C8.79861 0.000103258 9.19633 0.148891 9.51125 0.4203L9.625 0.5274L13.4872 4.5C13.7778 4.79882 13.9568 5.19325 13.993 5.6142L14 5.7726V16.2C14.0001 16.6541 13.8334 17.0915 13.5332 17.4245C13.233 17.7575 12.8215 17.9614 12.3812 17.9955L12.25 18H1.75C1.3085 18.0001 0.883254 17.8286 0.559521 17.5199C0.235787 17.2111 0.037488 16.7878 0.00437519 16.335L8.77345e-08 16.2V1.8C-0.000139632 1.34588 0.166606 0.90849 0.46681 0.575507C0.767014 0.242524 1.17849 0.038559 1.61875 0.0045001L1.75 0H8.38775ZM7 1.8H1.75V16.2H12.25V7.2H8.3125C7.98623 7.19998 7.67166 7.07497 7.43013 6.84934C7.18861 6.62371 7.03745 6.31364 7.00612 5.9796L7 5.85V1.8ZM8.75 2.1726V5.4H11.8877L8.75 2.1726Z"
      fill="#3145B0"
    />
    <rect x="3.50024" y="8.64014" width="7" height="1.44" fill="#3145B0" />
    <rect x="3.50024" y="10.8002" width="7" height="1.44" fill="#3145B0" />
    <rect x="3.50024" y="12.9602" width="7" height="1.44" fill="#3145B0" />
  </svg>
);

export const FilePdfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38775 0C8.79861 0.000103258 9.19633 0.148891 9.51125 0.4203L9.625 0.5274L13.4872 4.5C13.7778 4.79882 13.9568 5.19325 13.993 5.6142L14 5.7726V16.2C14.0001 16.6541 13.8334 17.0915 13.5332 17.4245C13.233 17.7575 12.8215 17.9614 12.3812 17.9955L12.25 18H1.75C1.3085 18.0001 0.883254 17.8286 0.559521 17.5199C0.235787 17.2111 0.037488 16.7878 0.00437519 16.335L8.77345e-08 16.2V1.8C-0.000139632 1.34588 0.166606 0.90849 0.46681 0.575507C0.767014 0.242524 1.17849 0.038559 1.61875 0.0045001L1.75 0H8.38775ZM7 1.8H1.75V16.2H12.25V7.2H8.3125C7.98623 7.19998 7.67166 7.07497 7.43013 6.84934C7.18861 6.62371 7.03745 6.31364 7.00612 5.9796L7 5.85V1.8ZM7.8645 8.8632C8.07395 10.234 8.77089 11.4758 9.82012 12.348C10.5962 12.9933 9.88662 14.2569 8.95562 13.8888C7.69692 13.3903 6.30308 13.3903 5.04438 13.8888C4.1125 14.2578 3.40287 12.9933 4.179 12.348C5.22824 11.4758 5.92517 10.234 6.13462 8.8632C6.29037 7.8489 7.70962 7.8498 7.8645 8.8632ZM6.99825 10.9368L6.39625 12.0132H7.60375L6.99825 10.9368ZM8.75 2.1726V5.4H11.8878L8.75 2.1726Z"
      fill="#C80000"
    />
  </svg>
);

export const FileMdIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38775 0C8.79861 0.000103258 9.19633 0.148891 9.51125 0.4203L9.625 0.5274L13.4872 4.5C13.7778 4.79882 13.9568 5.19325 13.993 5.6142L14 5.7726V16.2C14.0001 16.6541 13.8334 17.0915 13.5332 17.4245C13.233 17.7575 12.8215 17.9614 12.3812 17.9955L12.25 18H1.75C1.3085 18.0001 0.883254 17.8286 0.559521 17.5199C0.235787 17.2111 0.037488 16.7878 0.00437519 16.335L8.77345e-08 16.2V1.8C-0.000139632 1.34588 0.166606 0.90849 0.46681 0.575507C0.767014 0.242524 1.17849 0.038559 1.61875 0.0045001L1.75 0H8.38775ZM7 1.8H1.75V16.2H12.25V7.2H8.3125C7.98623 7.19998 7.67166 7.07497 7.43013 6.84934C7.18861 6.62371 7.03745 6.31364 7.00612 5.9796L7 5.85V1.8ZM8.75 2.1726V5.4H11.8877L8.75 2.1726Z"
      fill="#6A31B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.89403 8.49561H3.00708V12.6716H4.01608V10.6161L4.75547 11.9662H5.04249C5.14189 11.9662 5.23443 11.9375 5.31429 11.8773L5.31549 11.8763C5.39505 11.815 5.46254 11.7232 5.52142 11.6142L6.05269 10.6253V12.6716H7.0617V8.49561H6.17387L5.04653 10.6491L3.89403 8.49561ZM9.39668 8.49561H7.45307V12.0055C7.45307 12.195 7.51372 12.3601 7.64176 12.4869C7.76892 12.6129 7.93161 12.6716 8.11687 12.6716H9.41828C9.90875 12.6716 10.3043 12.5433 10.5803 12.2636L10.5811 12.2629C10.8569 11.9792 10.9839 11.5741 10.9839 11.0724V9.98375C10.9839 9.69386 10.9171 9.43218 10.7799 9.20345C10.6464 8.97511 10.4567 8.79962 10.2149 8.67723C9.97614 8.55454 9.70209 8.49561 9.39668 8.49561ZM8.47827 11.706V9.46123H9.37508C9.56209 9.46123 9.70001 9.51638 9.80266 9.61626L9.80415 9.61766C9.90448 9.71249 9.95868 9.84034 9.95868 10.0171V11.0391C9.95868 11.2861 9.90346 11.4478 9.816 11.5486C9.731 11.6467 9.59953 11.706 9.39668 11.706H8.47827Z"
      fill="#6A31B3"
    />
  </svg>
);

export const FileSettingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="8.5"
      stroke="#B8C1C6"
      strokeOpacity="0.4"
    />
    <circle cx="9" cy="5" r="1" fill="#05050D" fillOpacity="0.5" />
    <circle cx="9" cy="9" r="1" fill="#05050D" fillOpacity="0.5" />
    <circle cx="9" cy="13" r="1" fill="#05050D" fillOpacity="0.5" />
  </svg>
);

export const CreateDocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M10.6791 13.1885H1.83686V4.18415H6.99482V2.71045H1.83686C1.02633 2.71045 0.363159 3.37362 0.363159 4.18415V13.0264C0.363159 13.8369 1.02633 14.5001 1.83686 14.5001H10.6791C11.4896 14.5001 12.1528 13.8369 12.1528 13.0264V7.86841H10.6791V13.1885Z"
      fill="#05050D"
    />
    <path
      d="M12.1529 0.5H10.6792V2.71055H8.46863C8.476 2.71792 8.46863 4.18426 8.46863 4.18426H10.6792V6.38744C10.6866 6.39481 12.1529 6.38744 12.1529 6.38744V4.18426H14.3634V2.71055H12.1529V0.5ZM3.31067 5.65796H9.20548V7.13166H3.31067V5.65796ZM3.31067 7.86851V9.34222H9.20548V7.86851H6.99493H3.31067ZM3.31067 10.0791H9.20548V11.5528H3.31067V10.0791Z"
      fill="#05050D"
    />
  </svg>
);

export const MoveContentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
  >
    <path
      d="M7.4 12C7.015 12 6.68553 11.8655 6.4116 11.5964C6.13767 11.3274 6.00047 11.0036 6 10.625V2.375C6 1.99687 6.1372 1.67329 6.4116 1.40425C6.686 1.13521 7.01547 1.00046 7.4 1H11.6L13 2.375H18.6C18.985 2.375 19.3147 2.50975 19.5891 2.77925C19.8635 3.04875 20.0005 3.37233 20 3.75V10.625C20 11.0031 19.863 11.3269 19.5891 11.5964C19.3152 11.8659 18.9855 12.0005 18.6 12H7.4ZM7.4 10.625H18.6V3.75H12.4225L11.0225 2.375H7.4V10.625Z"
      fill="#05050D"
      stroke="#05050D"
      strokeWidth="0.2"
    />
    <rect x="4" y="5" width="4" height="5" fill="white" />
    <path
      d="M2.82837 7.41431L7.82837 7.41431"
      stroke="#05050D"
      strokeWidth="1.5"
    />
    <path
      d="M4.41422 9.82852L2 7.41431L4.41422 5.00009"
      stroke="#05050D"
      strokeWidth="1.5"
    />
  </svg>
);

export const DeleteContentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
  >
    <path
      d="M4.05 11L6 8.97778L7.95 11L9 9.91111L7.05 7.88889L9 5.86667L7.95 4.77778L6 6.8L4.05 4.77778L3 5.86667L4.95 7.88889L3 9.91111L4.05 11ZM2.25 14.5C1.8375 14.5 1.4845 14.3478 1.191 14.0434C0.8975 13.7391 0.7505 13.3727 0.75 12.9444V2.83333H0V1.27778H3.75V0.5H8.25V1.27778H12V2.83333H11.25V12.9444C11.25 13.3722 11.1033 13.7386 10.8097 14.0434C10.5162 14.3483 10.163 14.5005 9.75 14.5H2.25Z"
      fill="#05050D"
    />
  </svg>
);

export const MoveFilesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
  >
    <path
      d="M7.4 12C7.015 12 6.68553 11.8655 6.4116 11.5964C6.13767 11.3274 6.00047 11.0036 6 10.625V2.375C6 1.99687 6.1372 1.67329 6.4116 1.40425C6.686 1.13521 7.01547 1.00046 7.4 1H11.6L13 2.375H18.6C18.985 2.375 19.3147 2.50975 19.5891 2.77925C19.8635 3.04875 20.0005 3.37233 20 3.75V10.625C20 11.0031 19.863 11.3269 19.5891 11.5964C19.3152 11.8659 18.9855 12.0005 18.6 12H7.4ZM7.4 10.625H18.6V3.75H12.4225L11.0225 2.375H7.4V10.625Z"
      fill="#A498FF"
      stroke="#A498FF"
      strokeWidth="0.2"
    />
    <rect x="4" y="5" width="4" height="5" fill="#05050D" />
    <path
      d="M2.82837 7.41406L7.82837 7.41406"
      stroke="#A498FF"
      strokeWidth="1.5"
    />
    <path
      d="M4.41422 9.82828L2 7.41406L4.41422 4.99985"
      stroke="#A498FF"
      strokeWidth="1.5"
    />
  </svg>
);

export const DeleteFilesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
  >
    <path
      d="M4.05 11L6 8.97778L7.95 11L9 9.91111L7.05 7.88889L9 5.86667L7.95 4.77778L6 6.8L4.05 4.77778L3 5.86667L4.95 7.88889L3 9.91111L4.05 11ZM2.25 14.5C1.8375 14.5 1.4845 14.3478 1.191 14.0434C0.8975 13.7391 0.7505 13.3727 0.75 12.9444V2.83333H0V1.27778H3.75V0.5H8.25V1.27778H12V2.83333H11.25V12.9444C11.25 13.3722 11.1033 13.7386 10.8097 14.0434C10.5162 14.3483 10.163 14.5005 9.75 14.5H2.25Z"
      fill="white"
    />
  </svg>
);
