import React from 'react';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import { Accordion } from 'src/components/Accorion/Accrdion';
import { ProjectEditNameContent } from 'src/components/ProjectSettings/Popups/ProjectEditNameContent';
import { ProjectDeleteContent } from 'src/components/ProjectSettings/Popups/ProjectDeleteContent';
import { DeleteIcon } from 'src/assets/img/delete-icon';
import { ProjectActivateContent } from 'src/components/ProjectSettings/Popups/ProjectActivateContent';
import { useParams } from 'react-router-dom';
import { useGetAllProjectsQuery } from 'src/services';

export const SettingsScreen = () => {
  const { id } = useParams();
  const { data: projects } = useGetAllProjectsQuery();

  const currentProject = projects?.find((project) => project.id === +id);
  return (
    <React.Fragment>
      <PageContentWrapper
        projectBreadcrumbsContent={'Settings'}
        className="project-page-container"
        isMobileMarginTop={false}
      >
        <Accordion className={'!bg-white'} title={'Edit project name'}>
          <ProjectEditNameContent onlyForm />
        </Accordion>
        <Accordion disabled className={'!bg-white'} title={'Hibernate project'}>
          <ProjectEditNameContent onlyForm />
        </Accordion>
        <Accordion
          className={'!bg-white'}
          title={
            currentProject?.is_active
              ? 'Deactivate project'
              : 'Activate project'
          }
        >
          <ProjectActivateContent
            onlyForm
            isActive={currentProject?.is_active}
          />
        </Accordion>
        <Accordion
          className={'!bg-white'}
          title={
            <div className="flex items-center gap-3">
              Delete project <DeleteIcon />
            </div>
          }
        >
          <ProjectDeleteContent onlyForm />
        </Accordion>
      </PageContentWrapper>
    </React.Fragment>
  );
};
