export const TaskComplexityOptions = [
  {
    value: 'Q1',
    label: 'Very easy tasks',
  },
  {
    value: 'Q2',
    label: 'Easy tasks',
  },
  {
    value: 'Q3',
    label: 'Moderate tasks',
  },
  {
    value: 'Q4',
    label: 'Complex tasks',
  },
];
