export const ProjectSettingsNames = {
  RENAME: 'rename',
  HIBERNATE: 'hibernate',
  ACTIVATE: 'activate',
  DELETE: 'delete',
};

export const ProjectPlanStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CANCELED: 'canceled',
  PAUSED: 'paused',
  TRIAL: 'trial',
  INCOMPLETE: 'incomplete',
  DRAFT: 'draft',
  PENDING: 'pending',
};
