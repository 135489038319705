import Input from '../../Input/Input';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';

export const IntegrationFormZendeskChat = ({ integration, onSubmit }) => {
  const notification = useNotification();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      project_name: integration?.integration_data?.name,
      subdomain: integration?.integration_data?.subdomain,
      author_display_name: integration?.integration_data?.author_display_name,
      author_avatar_url: integration?.integration_data?.author_avatar_url,
      app_id: integration?.integration_data?.app_id,
      conversation_key_id: integration?.integration_data?.conversation_key_id,
      conversation_secret_key: '',
    },
    resolver: yupResolver(
      object({
        project_name: stringScheme({ required: false }).label('Project name'),
        subdomain: stringScheme({ required: false }).label('Subdomain'),
        author_display_name: stringScheme({ required: false }).label(
          'Author display name',
        ),
        author_avatar_url: stringScheme({ required: false })
          .label('Author avatar url')
          .nullable(),
        app_id: stringScheme({ required: false }).label('App id'),
        conversation_key_id: stringScheme({ required: false }).label(
          'Conversation key id',
        ),
        conversation_secret_key: stringScheme({ required: false }).label(
          'Conversation secret key',
        ),
      }),
    ),
  });

  const submit = (data) => {
    if (isDirty) {
      onSubmit({
        ...integration,
        integration_data: {
          ...integration.integration_data,
          ...data,
        },
      });
    } else {
      notification.info('Nothing changed');
    }
  };

  return (
    <form id="integration-form" onSubmit={handleSubmit(submit)}>
      <div className="content">
        <Controller
          control={control}
          name="project_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Project name'}
              placeholder={'ABC Project'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="subdomain"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Subdomain'}
              placeholder={'Subdomain'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="author_display_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Author name'}
              placeholder={'Some Name'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="app_id"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'App id'}
              placeholder={'App Id'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="conversation_key_id"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Conversation key id'}
              placeholder={'Conversation key id'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="conversation_secret_key"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Conversation secret key'}
              placeholder={'Conversation secret key'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
    </form>
  );
};
