import React from 'react';
import { clsx } from 'clsx';

export const ButtonWithIcon = ({
  children,
  className = '',
  onClick = () => {},
  type,
  form,
  disabled,
  active = false,
  icon,
}) => {
  return (
    <button
      className={clsx(
        className,
        'button flex justify-between items-center px-4 py-5 rounded-md hover:text-[#05050D] radius-md text-sm hover:bg-[#F1F6F6] hover:border-[#B8C1C666]',
        disabled && 'opacity-50 cursor-not-allowed',
        active
          ? 'bg-[#F1F6F6] border-[#B8C1C666] text-[#05050D] cursor-default'
          : 'bg-white border border-[#B8C1C666] text-[#05050D]',
      )}
      onClick={disabled ? null : onClick}
      type={type}
      form={form}
    >
      {children}
      {icon}
    </button>
  );
};
