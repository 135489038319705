import './ProjectSelectFilterPopup.css';
import React, { useState } from 'react';
import { LATEST_VIEWED_PROJECT_ID } from 'src/shared/constants';
import { setProjectId } from 'src/store/slices/projectsSlices';
import { useAppDispatch } from 'src/store';
import Button from '../Button/Button';
import { FilterPopupButton } from '../FilterButton/FilterButton';
import { clsx } from 'clsx';
import { useGetAllProjectsQuery } from 'src/services';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';

export const ProjectSelectFilterPopup = ({ id, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(id);
  const dispatch = useAppDispatch();

  const { data: projects } = useGetAllProjectsQuery();

  const changeProject = (projectId) => {
    setSelectedId(projectId);
  };

  const handleSave = () => {
    localStorage.setItem(LATEST_VIEWED_PROJECT_ID, selectedId);
    dispatch(setProjectId({ projectId: selectedId }));
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <div>
          <FilterPopupButton />
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Filters:</DialogTitle>
        </DialogHeader>
        <div className="project-select-filter modal gap-[10px] flex flex-col">
          <div className="content max-h-[60vh] overflow-auto pr-2">
            <div className={'project-buttons-mobile gap-[6px] flex-col'}>
              {projects &&
                projects.map((project, index) => {
                  const isSelected = selectedId
                    ? project.id === +selectedId
                    : !index && project.id;

                  return (
                    <div
                      key={project.id}
                      onClick={() => {
                        changeProject(project.id);
                      }}
                      className={clsx(
                        'project-button',
                        isSelected && 'selected',
                      )}
                    >
                      {project.name}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button className="transparent black" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="edit-user-form"
            className="blue"
            onClick={handleSave}
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
