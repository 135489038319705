import './Menu.css';
import React from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { GeneralItems } from './GeneralItems';
import { ProductsItems } from './ProductsItems';
import { CompanySelectComponent } from 'src/components/CompanySelectComponent/CompanySelectComponent';

export const Menu = ({ openMobileMenu }) => {
  const isLgDown = useMediaQuery('(max-width: 992px)');

  return (
    <div className={'desktop-menu-container'}>
      <div className={'desktop-menu-header px-9 py-6 border-b border-shark'}>
        {isLgDown ? (
          <CompanySelectComponent isAccordion isLgDown={isLgDown} />
        ) : (
          <>
            <div className={'icon-container'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
              >
                <path
                  d="M0 6C0 2.68629 2.68629 0 6 0H39C42.3137 0 45 2.68629 45 6V39C45 42.3137 42.3137 45 39 45H6C2.68629 45 0 42.3137 0 39V6Z"
                  fill="#75F3FB"
                />
                <path
                  d="M17.0597 13.9656C14.8113 13.9656 12.6003 14.8651 11.0104 16.4663C9.42052 18.0674 8.52734 20.2391 8.52734 22.5035C8.52734 24.7679 9.42052 26.9396 11.0104 28.5407C12.6003 30.1419 14.8113 31.0414 17.0597 31.0414L17.0597 22.5035L17.0597 13.9656Z"
                  fill="#05050D"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.9533 31.0413C32.6656 31.0413 36.4856 27.2188 36.4856 22.5034C36.4856 17.788 32.6656 13.9655 27.9533 13.9655C23.241 13.9655 19.4209 17.788 19.4209 22.5034C19.4209 27.2188 23.241 31.0413 27.9533 31.0413ZM27.9388 25.9926C29.8665 25.9926 31.4293 24.4289 31.4293 22.4998C31.4293 20.5708 29.8665 19.007 27.9388 19.007C26.011 19.007 24.4482 20.5708 24.4482 22.4998C24.4482 24.4289 26.011 25.9926 27.9388 25.9926Z"
                  fill="#05050D"
                />
              </svg>
            </div>
            <div className={'text-container'}>
              <h4>CoSupport AI</h4>
              <p>client cabinet</p>
            </div>
          </>
        )}
      </div>
      {isLgDown ? (
        openMobileMenu && (
          <>
            <GeneralItems isMobile={isLgDown} />
            <ProductsItems isMobile={isLgDown} />
          </>
        )
      ) : (
        <>
          <GeneralItems isMobile={isLgDown} />
          <ProductsItems isMobile={isLgDown} />
        </>
      )}
    </div>
  );
};
