import Input from '../../Input/Input';
import React, { useState } from 'react';

export const IntegrationFormFreshdesk = ({ integration, onSubmit }) => {
  const [apiToken, setApiToken] = useState(
    integration.integration_data.api_key,
  );
  const [subdomain, setSubdomain] = useState(
    integration.integration_data.subdomain,
  );
  const [isSubdomainValid, setIsSubdomainValid] = useState(true);
  const [isApiTokenValid, setIsApiTokenValid] = useState(true);

  const submit = (event) => {
    event.preventDefault();

    const data = {
      ...integration,
      integration_data: {
        ...integration.integration_data,
        api_key: apiToken || '',
        subdomain,
      },
    };

    onSubmit(data);
  };

  return (
    <form id="integration-form" onSubmit={submit}>
      <div className={'input-container'}>
        <Input
          type={'text'}
          label={'Integration Type'}
          placeholder={'Integration Type'}
          value={integration.integration_type}
          readonly={true}
          isValid={true}
        />
      </div>
      <div className={'input-container'}>
        <Input
          type={'text'}
          label={'Subdomain'}
          placeholder={'Subdomain'}
          value={subdomain}
          invalidMessage={subdomain ? 'Invalid Format' : 'Required'}
          isValid={isSubdomainValid}
          onChange={(event) => setSubdomain(event.currentTarget.value)}
          onBlur={() => setIsSubdomainValid(subdomain && subdomain.length > 2)}
          onFocus={() => setIsSubdomainValid(true)}
        />
      </div>
      <div className="sub-title items-start md:flex-row flex-col gap-[16px] md:gap-[20px]">
        <h4>Credentials:</h4>
      </div>
      <div className={'input-container'}>
        <Input
          type={'password'}
          label={'API Key'}
          placeholder={'••••••••••••••••••••••••••••'}
          invalidMessage={'Invalid'}
          isValid={isApiTokenValid}
          value={apiToken}
          onChange={(event) => setApiToken(event.currentTarget.value)}
          onBlur={() => setIsApiTokenValid(apiToken && apiToken.length > 2)}
          onFocus={() => setIsApiTokenValid(true)}
        />
      </div>
    </form>
  );
};
