import './AccountPage.css';
import EditButtonIcon from '../../assets/img/edit-button-icon.svg';
import React, { useEffect, useState } from 'react';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import log from '../../shared/log';
import { useNotification } from 'src/hooks/useNotification';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../components/CardContainer/CardContainer';
import { EMAIL_REGEX } from 'src/shared/constants';
import { useTheme } from 'src/provider/ThemeProvider';
import {
  useGetAccountsQuery,
  useUpdateAccountsMutation,
} from 'src/services/accountsService';
import { getErrorMessage } from 'src/utils/get-error-message';
import {
  useGetUsersMeQuery,
  useUpdateUsersMeMutation,
} from 'src/services/usersService';
import { useLoader } from 'src/provider/LoaderProvider';

const phoneUtil = PhoneNumberUtil.getInstance();
const AccountPage = () => {
  const {
    data: account,
    error: accountError,
    isError: accountIsError,
    isFetching: accountIsFetching,
  } = useGetAccountsQuery();

  const [
    updateAccountInfo,
    {
      isLoading: isLoadingUpdateAccountInfo,
      isFetching: isFetchingAccountInfo,
      isError: isUpdateAccountInfoError,
      error: updateAccountInfoError,
      isSuccess,
    },
  ] = useUpdateAccountsMutation();

  const { data: userInfo } = useGetUsersMeQuery();
  const [
    updateUserInfo,
    {
      isLoading: isLoadingUpdateUserInfo,
      isError: isUpdateUserInfoError,
      error: updateUserInfoError,
      isSuccess: isSuccessUpdateUserInfo,
    },
  ] = useUpdateUsersMeMutation();
  const notification = useNotification();
  const loader = useLoader();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [companyEmail, setCompanyEmail] = useState('');
  const [isCompanyEmailValid, setIsCompanyEmailValid] = useState(true);
  const [accountPhone, setAccountPhone] = useState('');
  const [isAccountPhoneValid, setIsAccountPhoneValid] = useState(true);
  const [userCardDisabled, setUserCardDisabled] = useState(false);
  const [userCardEditMode, setUserCardEditMode] = useState(false);
  const [companyCardDisabled, setCompanyCardDisabled] = useState(false);
  const [companyCardEditMode, setCompanyCardEditMode] = useState(false);
  const { setTheme } = useTheme();

  useEffect(() => {
    if (accountIsError) {
      notification.error(getErrorMessage(accountError));
    }
  }, [accountIsError]);

  useEffect(() => {
    if (
      accountIsFetching ||
      isLoadingUpdateAccountInfo ||
      isLoadingUpdateUserInfo
    ) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [accountIsFetching, isLoadingUpdateAccountInfo, isLoadingUpdateUserInfo]);

  useEffect(() => {
    if (isUpdateAccountInfoError) {
      notification.error(getErrorMessage(updateAccountInfoError));
    }
    if (isUpdateUserInfoError) {
      notification.error(getErrorMessage(updateUserInfoError));
    }
  }, [isUpdateAccountInfoError, isUpdateUserInfoError]);

  useEffect(() => {
    if (account) {
      log('account :', account);
      setCompanyName(account.company_name);
      setCompanyEmail(account.email);
      setAccountPhone(account.phone_number);
    }
  }, [account]);

  useEffect(() => {
    if (userInfo) {
      log('userInfo :', userInfo);

      setEmail(userInfo.email);
      setFirstName(userInfo.first_name);
      setLastName(userInfo.last_name);
    }
  }, [userInfo]);

  useEffect(() => {
    setTheme('green');
  }, []);

  const onNameChange = (event, isFirstName) => {
    isFirstName
      ? setFirstName(event.currentTarget.value)
      : setLastName(event.currentTarget.value);
  };
  const validateName = (isFirstName) => {
    isFirstName
      ? setIsFirstNameValid(firstName && firstName.length > 2)
      : setIsLastNameValid(lastName && lastName.length > 2);
  };
  const validateEmail = (isCompanyEmail) => {
    const isValid = EMAIL_REGEX.test(isCompanyEmail ? companyEmail : email);

    isCompanyEmail ? setIsCompanyEmailValid(isValid) : setIsEmailValid(isValid);
  };
  const validateAccountPhone = (phone) => {
    let isValid = false;

    try {
      isValid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      isValid = false;
    }

    setIsAccountPhoneValid(isValid);
  };
  const resetFields = () => {
    setEmail(userInfo.email);
    setFirstName(userInfo.first_name);
    setLastName(userInfo.last_name);
    setCompanyName(account.company_name);
    setCompanyEmail(account.email);
    setAccountPhone(account.phone_number);
    setIsAccountPhoneValid(true);
    setIsCompanyNameValid(true);
    setIsEmailValid(true);
    setIsFirstNameValid(true);
    setIsLastNameValid(true);
    setIsCompanyEmailValid(true);
  };
  const editCard = (card, isEditMode) => {
    switch (card) {
      case 'user':
        setUserCardEditMode(isEditMode);
        setCompanyCardDisabled(isEditMode);
        return;
      case 'company':
        setCompanyCardEditMode(isEditMode);
        setUserCardDisabled(isEditMode);
        return;
      default:
        return;
    }
  };
  const submitCompany = async (event) => {
    const request = {};

    event.preventDefault();

    const isFormInvalid = [
      isCompanyNameValid,
      isAccountPhoneValid,
      isCompanyEmailValid,
    ].some((isValid) => !Boolean(isValid));

    if (isFormInvalid) {
      return;
    }

    if (account.company_name !== companyName) {
      request.company_name = companyName;
    }

    if (account.email !== companyEmail) {
      request.email = companyEmail;
    }

    if (account.phone_number !== accountPhone) {
      request.phone_number = accountPhone;
    }
    updateAccountInfo(request);

    editCard('company', false);
  };

  const submitUserInfo = async (event) => {
    let request = {};
    event.preventDefault();

    const isFormInvalid = [
      isFirstNameValid,
      isLastNameValid,
      isEmailValid,
    ].some((isValid) => !Boolean(isValid));

    if (isFormInvalid) {
      return;
    }

    if (userInfo.first_name !== firstName) {
      request.first_name = firstName;
    }

    if (userInfo.last_name !== lastName) {
      request.last_name = lastName;
    }

    if (userInfo.email !== email) {
      request.email = email;
    }

    updateUserInfo(request);

    editCard('user', false);
  };
  return (
    <React.Fragment>
      <PageContentWrapper className={'account-page-container'}>
        <div className={'flex md:flex-row flex-col gap-0.625-rem'}>
          <CardContainer
            className={`half-width flex-col ${userCardDisabled ? 'disabled' : ''}`}
          >
            <div className="card-header">
              <div className="title">My Info</div>
              <div className="edit-button-container">
                {!userCardEditMode && (
                  <Button
                    className="transparent"
                    onClick={() => editCard('user', true)}
                  >
                    <span>Edit</span>
                    <img src={EditButtonIcon} />
                  </Button>
                )}
              </div>
            </div>
            <div className="card-body">
              <form id="user-info" onSubmit={submitUserInfo}>
                <div className={'input-container full-width'}>
                  <Input
                    type={'text'}
                    label={'First name'}
                    placeholder={'Josh'}
                    isValid={isFirstNameValid}
                    invalidMessage={firstName ? 'Invalid Format' : 'Required'}
                    value={firstName}
                    onChange={(event) => onNameChange(event, true)}
                    onBlur={() => validateName(true)}
                    onFocus={() => setIsFirstNameValid(true)}
                    readonly={!userCardEditMode}
                  />
                </div>
                <div className={'input-container full-width'}>
                  <Input
                    type={'text'}
                    label={'Last name'}
                    placeholder={'Brawn'}
                    isValid={isLastNameValid}
                    invalidMessage={lastName ? 'Invalid Format' : 'Required'}
                    value={lastName}
                    onChange={(event) => onNameChange(event, false)}
                    onBlur={() => validateName(false)}
                    onFocus={() => setIsLastNameValid(true)}
                    readonly={!userCardEditMode}
                  />
                </div>
                <div className={'input-container full-width'}>
                  <Input
                    type={'email'}
                    label={'Email address'}
                    placeholder={'email@email.com'}
                    invalidMessage={email ? 'Invalid Format' : 'Required'}
                    value={email}
                    isValid={isEmailValid}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                    onBlur={() => validateEmail(false)}
                    onFocus={() => setIsEmailValid(true)}
                    readonly={!userCardEditMode}
                  />
                </div>
              </form>
            </div>
            <div
              className={
                userCardEditMode ? 'card-footer' : 'card-footer hidden'
              }
            >
              <div className="action-buttons">
                <Button
                  className="transparent black"
                  onClick={() => {
                    resetFields();
                    editCard('user', false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" form="user-info" className="blue">
                  Save
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                  >
                    <path
                      d="M12.8359 0L5.15844 7.79167L1.99715 4.58333L0.416504 6.1875L5.15844 11L14.4165 1.60417L12.8359 0Z"
                      fill="#75F3FB"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </CardContainer>
          <CardContainer
            className={`half-width ${companyCardDisabled ? 'disabled' : ''}`}
          >
            <div className="card-header">
              <div className="title">Company</div>
              <div className="edit-button-container">
                {!companyCardEditMode && (
                  <Button
                    className="transparent"
                    onClick={() => editCard('company', true)}
                  >
                    <span>Edit</span>
                    <img src={EditButtonIcon} />
                  </Button>
                )}
              </div>
            </div>
            <div className="card-body">
              <form id="company-info" onSubmit={submitCompany}>
                <div className={'input-container full-width'}>
                  <Input
                    type={'text'}
                    label={'Company Name'}
                    placeholder={'Company'}
                    invalidMessage={companyName ? 'Invalid Format' : 'Required'}
                    value={companyName}
                    isValid={isCompanyNameValid}
                    onChange={(event) =>
                      setCompanyName(event.currentTarget.value)
                    }
                    onBlur={() =>
                      setIsCompanyNameValid(
                        companyName && companyName.length > 2,
                      )
                    }
                    onFocus={() =>
                      companyCardEditMode && setIsCompanyNameValid(true)
                    }
                    readonly={!companyCardEditMode}
                  />
                </div>

                <div className={'input-container full-width'}>
                  <Input
                    type={'phone'}
                    label={'Phone Number'}
                    placeholder={'+1 (555) 455 88 99'}
                    invalidMessage={
                      accountPhone ? 'Invalid Format' : 'Required'
                    }
                    value={accountPhone}
                    isValid={isAccountPhoneValid}
                    onChange={(phone) => setAccountPhone(phone)}
                    onBlur={() => validateAccountPhone(accountPhone)}
                    onFocus={() =>
                      companyCardEditMode && setIsAccountPhoneValid(true)
                    }
                    readonly={!companyCardEditMode}
                  />
                </div>
                <div className={'input-container full-width'}>
                  <Input
                    type={'email'}
                    label={'Company Email'}
                    placeholder={'email@company.com'}
                    invalidMessage={
                      companyEmail ? 'Invalid Format' : 'Required'
                    }
                    value={companyEmail}
                    isValid={isCompanyEmailValid}
                    onChange={(event) =>
                      setCompanyEmail(event.currentTarget.value)
                    }
                    onBlur={() => validateEmail(true)}
                    onFocus={() =>
                      companyCardEditMode && setIsCompanyEmailValid(true)
                    }
                    readonly={!companyCardEditMode}
                  />
                </div>
              </form>
            </div>
            <div className="card-footer">
              {companyCardEditMode && (
                <div className="action-buttons">
                  <Button
                    className="transparent black"
                    onClick={() => {
                      resetFields();
                      editCard('company', false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" form="company-info" className="blue">
                    Save
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="11"
                      viewBox="0 0 15 11"
                      fill="none"
                    >
                      <path
                        d="M12.8359 0L5.15844 7.79167L1.99715 4.58333L0.416504 6.1875L5.15844 11L14.4165 1.60417L12.8359 0Z"
                        fill="#75F3FB"
                      />
                    </svg>
                  </Button>
                </div>
              )}
            </div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default AccountPage;
