import { REFRESH_TOKEN_URL, USERS_ME_URL } from '../shared/constants';
import { environment } from '../env/env';

export const fetchRequest = async (method, url, data, headers, mode) => {
  if (!headers) {
    headers = {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/json; charset=utf-8',
    };
  } else {
    headers = {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/json; charset=utf-8',
      ...headers,
    };
  }

  let isError = false;

  const params = {
    method,
    headers,
  };

  if (data) {
    params.body = JSON.stringify(data);
  }

  if (mode) {
    params.mode = mode;
  }
  return fetch(url, params)
    .then((response) => {
      isError = !response.ok;
      if (!response.ok) {
        return response.json();
      } else {
        if (response.statusText === 'No Content') {
          return response.ok;
        } else {
          return response.json();
        }
      }
    })
    .then((response) => {
      if (!isError) {
        return response;
      } else {
        let errorMessage = '';
        JSON.stringify(response, (key, value) => {
          if (typeof value === 'string') {
            errorMessage += errorMessage ? `, ${value}` : value;
          }
          return value;
        });
        return new Error(errorMessage);
      }
    })
    .catch((error) => {
      console.error(error);
      return new Error(
        'Something went wrong. Please contact our system administrator.',
      );
    });
};

export const fetchRequestWithAccessToken = async (
  method,
  url,
  data,
  headers,
) => {
  let user = localStorage.getItem('user');
  user = user && user !== 'undefined' && JSON.parse(user);
  const access = user && user.access;

  if (!headers) {
    headers = {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${access}`,
    };
  } else {
    headers = {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${access}`,
      ...headers,
    };
  }

  let isError = false;

  const params = {
    method,
    headers,
  };

  if (data) {
    params.body = JSON.stringify(data);
  }

  const response = await fetch(url, params).catch((error) => {
    console.error(error);
    return new Error(
      'Something went wrong. Please contact our system administrator.',
    );
  });
  isError = !response.ok;

  if (response instanceof Error) {
    return response;
  }

  if ([403, 422].includes(response.status)) {
    let tokens = await refreshToken(user.refresh);

    if (tokens.access && tokens.refresh) {
      localStorage.setItem('user', JSON.stringify(tokens));
    } else {
      return tokens;
    }
  }

  if (isError) {
    let errorMessage = '';
    const json = await response.json();
    JSON.stringify(json, (key, value) => {
      if (typeof value === 'string') {
        errorMessage += errorMessage ? `, ${value}` : value;
      }
      return value;
    });

    if (response.status === 401) {
      localStorage.removeItem('user');
      window.location.assign('/auth');
      return;
    }

    return new Error(errorMessage);
  } else {
    if (response.statusText === 'No Content') {
      return response.ok;
    } else {
      return response.json();
    }
  }
};

export const uploadFileWithAccessToken = async (method, url, data, headers) => {
  let user = localStorage.getItem('user');
  user = user && user !== 'undefined' && JSON.parse(user);
  const access = user && user.access;

  if (!headers) {
    headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${access}`,
    };
  } else {
    headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${access}`,
      ...headers,
    };
  }

  let isError = false;

  const params = {
    method,
    headers,
  };

  if (data) {
    params.body = data;
  }

  const response = await fetch(url, params).catch((error) => {
    console.error(error);
    return new Error(
      'Something went wrong. Please contact our system administrator.',
    );
  });
  isError = !response.ok;

  if (response instanceof Error) {
    return response;
  }

  if ([403, 422].includes(response.status)) {
    let tokens = await refreshToken(user.refresh);

    if (tokens.access && tokens.refresh) {
      localStorage.setItem('user', JSON.stringify(tokens));
    } else {
      return tokens;
    }

    // return fetchRequestWithAccessToken(method, url, data, headers);
  }

  if (isError) {
    let errorMessage = '';
    const json = await response.json();
    JSON.stringify(json, (key, value) => {
      if (typeof value === 'string') {
        errorMessage += errorMessage ? `, ${value}` : value;
      }
      return value;
    });
    return new Error(errorMessage);
  } else {
    if (response.statusText === 'No Content') {
      return response.ok;
    } else {
      return response.json();
    }
  }
};

export const refreshToken = async (token) => {
  const url = `${environment.BASE_URL}/${REFRESH_TOKEN_URL}`;

  return fetchRequest('POST', url, { refresh: token });
};
