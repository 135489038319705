import React, { useState } from 'react';
import Button from '../../Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import Input from 'src/components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { getFileExtension } from 'src/utils/fileUtils';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';

export const RenameFilePopup = ({
  renameFile,
  item,
  triggerEl,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(
      object({
        name: stringScheme({ required: true, minLength: 3 }).label('New Name'),
      }),
    ),
  });

  const submit = ({ name }) => {
    const ext = getFileExtension(name);

    if (item.type === 'folder') {
      renameFile(name);
      setOpen(false);

      return;
    }

    if (ext) {
      const allowedExtension = KNOWLEDGE_BASE_FILE_EXTENSIONS.includes(
        `.${ext}`,
      );

      name = allowedExtension ? name : name.replace(`.${ext}`, '.txt');
    } else {
      name = `${name}.txt`;
    }

    renameFile(name);
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          disabled={disabled}
          asChild
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer',
          )}
        >
          {triggerEl}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Rename</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="content">
              <form id="rename-file-folder" onSubmit={handleSubmit(submit)}>
                <div className={'input-container'}>
                  <Controller
                    control={control}
                    name="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        type={'text'}
                        label={'New Name'}
                        placeholder={item.name}
                        invalidMessage={error?.message}
                        value={value}
                        isValid={!error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </form>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button style={{ width: 'auto' }} className="transparent black">
                Cancel
              </Button>
            </DialogClose>
            <Button
              style={{ width: 'auto' }}
              type="submit"
              form="rename-file-folder"
              className="purple"
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
