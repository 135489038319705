import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { useParams } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import Button from '../../Button/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import Input from '../../Input/Input';
import { useGetAllProjectsQuery, useUpdateProjectMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';

export const ProjectEditNameContent = ({
  close = () => {},
  onlyForm = false,
}) => {
  const notification = useNotification();
  const { data: projects } = useGetAllProjectsQuery();
  const { id } = useParams();
  const loader = useLoader();

  const currentProject = projects?.find((project) => project.id === +id);

  const [
    updateProject,
    {
      isLoading,
      isError: isUpdateProjectError,
      error: updateProjectError,
      isSuccess,
    },
  ] = useUpdateProjectMutation();

  useEffect(() => {
    if (!isLoading) {
      loader.hide();
    }
    if (isSuccess) {
      close();
      notification.success('Name was changed!');
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isUpdateProjectError) {
      notification.error(getErrorMessage(updateProjectError));
    }
  }, [isUpdateProjectError]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      projectName: currentProject?.name,
    },
    resolver: yupResolver(
      object({
        projectName: stringScheme({ required: true }).label('New name'),
      }),
    ),
  });

  const submit = (data) => {
    updateProject({
      projectId: id,
      name: data.projectName,
      ai_reply_generation: currentProject.ai_reply_generation,
      ai_text_lense: currentProject.ai_text_lense,
    }).then(() => reset(data));
    loader.show();
  };

  useEffect(() => {
    if (currentProject) {
      setValue('projectName', currentProject?.name);
    }
  }, [currentProject]);

  return (
    <>
      <form>
        <div className="content">
          <Controller
            control={control}
            name="projectName"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'New name'}
                placeholder={'ABC Studio'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
      </form>
      <div className="footer flex justify-end mt-5">
        <div className="help-container"></div>
        <div className="action-buttons flex gap-2.5">
          {!onlyForm && (
            <Button
              className="transparent black min-w-28"
              onClick={() => close()}
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={!isDirty}
            onClick={handleSubmit(submit)}
            type="submit"
            form="edit-user-form"
            className="blue min-w-28"
          >
            Done
          </Button>
        </div>
      </div>
    </>
  );
};
