import '../ProjectPage.css';
import React from 'react';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import { CreateProjectForm } from './CreateProjectForm';

const ProjectPage = () => {
  return (
    <React.Fragment>
      <PageContentWrapper className={'project-page-container'}>
        <CreateProjectForm />
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default ProjectPage;
