export const CompanyIcon = ({
  height = '21',
  width = '16',
  fill = '#05050D',
  fillInner = '#75F3FB',
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="13" height="17" fill={fill} fillOpacity="1" />
      <rect x="2" y="2" width="3" height="3" fill={fillInner} />
      <rect x="2" y="7" width="3" height="3" fill={fillInner} />
      <rect x="2" y="12" width="3" height="3" fill={fillInner} />
      <rect x="8" y="2" width="3" height="3" fill={fillInner} />
      <rect x="8" y="7" width="3" height="3" fill={fillInner} />
      <rect x="8" y="12" width="3" height="3" fill={fillInner} />
    </svg>
  );
};
