import Input from '../../Input/Input';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';

export const IntegrationFormZendeskEmail = ({ integration, onSubmit }) => {
  const notification = useNotification();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      subdomain: integration?.integration_data?.subdomain,
      api_key: '',
      project_name: integration?.integration_data?.name,
    },
    resolver: yupResolver(
      object({
        project_name: stringScheme({ required: false }).label('Project Name'),
        subdomain: stringScheme({ required: false }).label('Subdomain'),
        api_key: stringScheme({ required: false }).label('Api key'),
      }),
    ),
  });

  const submit = (data) => {
    if (isDirty) {
      onSubmit({
        ...integration,
        integration_data: {
          ...integration.integration_data,
          project_name: data.project_name,
          subdomain: data.subdomain,
          api_key: data.api_key,
        },
      });
    } else {
      notification.info('Nothing changed');
    }
  };

  return (
    <form id="integration-form" onSubmit={handleSubmit(submit)}>
      <div className="content">
        <Controller
          control={control}
          name="project_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Project Name'}
              placeholder={'Project'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="content">
        <Controller
          control={control}
          name="subdomain"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Subdomain'}
              placeholder={'Subdomain'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="content">
        <Controller
          control={control}
          name="api_key"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Api key'}
              placeholder={'Api key'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
    </form>
  );
};
