import React, { useState } from 'react';
import Button from 'src/components/Button/Button';
import { CurrentSubscriptionPlanPopupForm } from './CurrentSubscriptionPlanPopupForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import AddButtonIcon from 'src/assets/img/add-button-icon.svg';

export const CurrentSubscriptionPlanPopup = ({ disabled = false }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <Button className="transparent">
          <p>
            Add <span className="max-md:hidden">subscription plan</span>
          </p>
          <img src={AddButtonIcon} alt="add icon" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add subscription plan</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <CurrentSubscriptionPlanPopupForm closePopup={closePopup} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            style={{ width: 'auto' }}
            type="submit"
            form="add-subscription-plan-form"
            className="green"
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
