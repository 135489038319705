import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const Main = () => {
  let user = localStorage.getItem('user');
  user = user && user !== 'undefined' && JSON.parse(user);
  const access = user && user.access;
  const location = useLocation();
  const redirect = access ? '/dashboard' : '/auth';

  return <Navigate to={redirect} state={{ from: location }} replace />;
};

export default Main;
