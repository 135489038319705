import Input from '../../Input/Input';
import React from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';

export const IntegrationFormSlack = ({ integration, onSubmit }) => {
  const notification = useNotification();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      domain: integration?.integration_data?.domain,
      integration_type: integration?.integration_type,
      project_name: integration?.integration_data?.name,
    },
    resolver: yupResolver(
      object({
        project_name: stringScheme({ required: false, min: 2 }).label(
          'Project Name',
        ),
        integration_type: stringScheme({ required: false }),
        domain: stringScheme({ required: false, min: 2 }).label('Domain'),
      }),
    ),
  });

  const submit = (values) => {
    if (isDirty) {
      const { files, ...data } = integration;
      const { name, ...integrationData } = integration.integration_data;

      const request = {
        ...data,
        integration_data: {
          ...integrationData,
          project_name: values.project_name,
          domain: values.domain,
        },
      };
      onSubmit(request);
    } else {
      notification.info('Nothing changed');
    }
  };

  return (
    <>
      <form
        id="integration-form"
        className={'integration-slack'}
        onSubmit={handleSubmit(submit)}
      >
        <div className="content">
          <Controller
            control={control}
            name="integration_type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'Integration Type'}
                placeholder={'Integration Type'}
                invalidMessage={error?.message}
                value={value}
                onChange={onChange}
                readonly={true}
                isValid={true}
              />
            )}
          />
        </div>
        <div className="content">
          <Controller
            control={control}
            name="project_name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'Name'}
                placeholder={'Name'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="content">
          <Controller
            control={control}
            name="domain"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'Domain'}
                placeholder={'Domain'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
      </form>
      <div className={'separator'}></div>
    </>
  );
};
