import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { Calendar } from 'src/components/ui/calendar';

export const PaymentHistoryFilterPopupForm = ({ onSubmit, dateDefault }) => {
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      date: {
        from: '',
        to: '',
      },
    },
    resolver: yupResolver(
      object({
        date: object({
          from: stringScheme({ required: false }).label('Date from'),
          to: stringScheme({ required: false }).label('Date to'),
        }),
      }),
    ),
  });

  const submit = (formValues) => {
    onSubmit(formValues);
  };

  useEffect(() => {
    setValue('date', dateDefault);
  }, [dateDefault]);

  return (
    <form id="aply-date-filter-form" onSubmit={handleSubmit(submit)}>
      <p className="font-semibold text-sm">Choose date range:</p>
      <div className="flex mt-2.5 gap-2.5 items-center justify-center max-laptop:flex-wrap">
        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={value?.from}
              selected={value}
              onSelect={onChange}
              numberOfMonths={2}
            />
          )}
        />
      </div>
    </form>
  );
};
