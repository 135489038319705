import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { ProductsType as ProjectsType } from 'src/constants/products';
import { useAppSelector } from 'src/store';
import { ROUTE_PATHS } from 'src/utils/route-paths';

export const MenuBarComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const isProjectLocation = location.pathname.includes('projects');
  const isAccountLocation = location.pathname.includes('account');
  const isAnalyticsLocation = location.pathname.includes('analytics');
  const isSubscriptionLocation = location.pathname.includes('subscription');
  const isKnowledgeBaseLocation = location.pathname.includes('knowledge-base');
  const isIntegrationsLocation = location.pathname.includes('integrations');
  const { projectType } = useAppSelector((s) => s.projectsSlices);

  useEffect(() => {
    if (isProjectLocation) {
      setActiveIndex(0);
    }
    if (isAnalyticsLocation) {
      setActiveIndex(1);
    }
    if (isKnowledgeBaseLocation) {
      setActiveIndex(2);
    }
    if (isIntegrationsLocation) {
      setActiveIndex(3);
    }
  }, []);
  const menuBarItems = [
    {
      id: 0,
      title: 'Overview',
      href: ROUTE_PATHS.DASHBOARD_PROJECT.replace(':id', id),
      visibleFor: [ProjectsType.agent, ProjectsType.bi, ProjectsType.customer],
    },
    {
      id: 2,
      title: 'Knowledge Base',
      href: ROUTE_PATHS.DASHBOARD_PROJECT_KNOWLEDGE_BASE.replace(':id', id),
      disabled: !Boolean(id),
      visibleFor: [ProjectsType.customer, ProjectsType.bi],
    },
    {
      id: 1,
      title: 'Analytics',
      href: ROUTE_PATHS.DASHBOARD_PROJECT_ANALYTICS.replace(':id', id),
      disabled: !Boolean(id),
      visibleFor: [],
    },
    {
      id: 3,
      title: 'Integrations',
      href: ROUTE_PATHS.DASHBOARD_PROJECT_INTEGRATIONS.replace(':id', id),
      disabled: !Boolean(id),
      visibleFor: [ProjectsType.customer, ProjectsType.agent, ProjectsType.bi],
    },
  ];

  const barHandleClick = (href) => {
    navigate(`${href}${location.search}`);
  };

  const isProject = isProjectLocation ?? isAnalyticsLocation;
  return (
    <div className="absolute flex ml-5 gap-10 -top-12 z-50">
      {isProject ? (
        menuBarItems
          .filter((item) => item.visibleFor?.includes(projectType))
          .map((item, i) => (
            <div
              onClick={() => barHandleClick(item.href)}
              key={item.id}
              className={clsx(
                'flex pb-3.5 border-ebony cursor-pointer transition',
                activeIndex === item.id
                  ? 'border-b-4 pointer-events-none'
                  : 'opacity-50 hover:opacity-100',
                item.disabled && 'opacity-50 pointer-events-none',
              )}
            >
              <p className="font-bold text-[22px]">{item.title}</p>
            </div>
          ))
      ) : (
        <div className="flex pb-3.5 border-b-4 border-ebony cursor-pointer">
          <p className="font-bold text-[22px]">
            {(isAccountLocation && 'Account') ||
              (isSubscriptionLocation && 'Subscription')}
          </p>
        </div>
      )}
    </div>
  );
};
