import './CardContainer.css';
import React from 'react';
import { clsx } from 'clsx';

const CardContainer = ({ children, className = '' }) => {
  return (
    <div className={clsx('card md:p-[20px] p-[16px]', className)}>
      {children}
    </div>
  );
};

export default CardContainer;
