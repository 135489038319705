export const SettingIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8933 9.3415L14.8847 9.3585C14.9191 9.078 14.9535 8.789 14.9535 8.5C14.9535 8.211 14.9277 7.939 14.8933 7.6585L14.9019 7.6755L17 6.0435L14.9105 2.4565L12.4426 3.4425L12.4512 3.451C12.004 3.111 11.5139 2.822 10.9808 2.601H10.9894L10.5938 0H6.40617L6.02782 2.6095H6.03642C5.50329 2.8305 5.01315 3.1195 4.56601 3.4595L4.57461 3.451L2.09813 2.4565L0 6.0435L2.09813 7.6755L2.10673 7.6585C2.07233 7.939 2.04654 8.211 2.04654 8.5C2.04654 8.789 2.07233 9.078 2.11533 9.3585L2.10673 9.3415L0.300961 10.744L0.017198 10.965L2.10673 14.535L4.58321 13.5575L4.56601 13.5235C5.02175 13.872 5.51189 14.161 6.05362 14.382H6.02782L6.41477 17H10.5852C10.5852 17 10.611 16.847 10.6368 16.643L10.9636 14.3905H10.955C11.4881 14.1695 11.9868 13.8805 12.4426 13.532L12.4254 13.566L14.9019 14.5435L16.9914 10.9735C16.9914 10.9735 16.871 10.8715 16.7076 10.7525L14.8933 9.3415ZM8.4957 11.475C6.83611 11.475 5.48609 10.1405 5.48609 8.5C5.48609 6.8595 6.83611 5.525 8.4957 5.525C10.1553 5.525 11.5053 6.8595 11.5053 8.5C11.5053 10.1405 10.1553 11.475 8.4957 11.475Z"
        fill="#05050D"
      />
    </svg>
  );
};
