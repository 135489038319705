import { createSlice } from '@reduxjs/toolkit';
import { LATEST_VIEWED_PROJECT_ID } from 'src/shared/constants';

const localProjectId = localStorage.getItem(LATEST_VIEWED_PROJECT_ID);

const searchParams = new URLSearchParams(window.location.search);
const searchProjectType = searchParams.get('type');

const initialState = {
  projectId: +localProjectId || null,
  projectType: searchProjectType || null,
};

export const projectsSlices = createSlice({
  name: 'projectsSlices',
  initialState,
  reducers: {
    setProjectId(state, action) {
      state.projectId = action.payload.projectId;
      state.projectType = action.payload?.projectType;
    },
  },
});

export const { setProjectId } = projectsSlices.actions;

export default projectsSlices.reducer;
