export const IntegrationTypes = [
  {
    integration_type: 'zendesk',
    integration_data: {
      subdomain: '',
      credits: 0,
      api_token: '',
      auth_email: '',
      via_email: true,
      via_chat: true,
    },
  },
  {
    integration_type: 'freshdesk',
    integration_data: {
      subdomain: '',
      api_key: '',
    },
  },
  {
    integration_type: 'slack',
    integration_data: {
      domain: '',
      project_name: '',
    },
  },
  {
    integration_type: 'zoho_salesiq',
    integration_data: {
      project_name: 'str',
    },
  },
];

export const IntegrationTypeAgent = [
  {
    integration_type: 'zendesk',
    integration_data: {
      subdomain: '',
      credits: 0,
      api_token: '',
      auth_email: '',
      via_email: true,
      via_chat: true,
    },
  },
  {
    integration_type: 'freshdesk',
    integration_data: {
      subdomain: '',
      api_key: '',
    },
  },
];

export const IntegrationTypeCustomer = [
  {
    integration_type: 'zendesk_chat',
    integration_data: {
      project_name: '',
      subdomain: '',
      author_display_name: '',
      author_avatar_url: '',
      app_id: '',
      conversation_key_id: '',
      conversation_secret_key: '',
    },
  },
  {
    integration_type: 'zendesk_email',
    integration_data: {
      project_name: '',
      subdomain: '',
      api_key: '',
    },
  },
  {
    integration_type: 'zoho_salesiq',
    integration_data: {
      project_name: '',
    },
  },
];

export const IntegrationTypeBi = [
  {
    integration_type: 'slack',
    integration_data: {
      domain: '',
      project_name: '',
    },
  },
];
