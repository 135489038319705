import './App.css';
import React from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';
import ResetPassword from './components/ResetPassword/ResetPassword';
import EmailVerify from './components/EmailVerify/EmailVerify';
import Main from './components/Main/Main';
import UninstallExtension from './components/UninstallExtension/UninstallExtension';
import { AuthProvider, RequireAuth } from './provider/AuthProvider';
import { isMobile } from 'react-device-detect';
import AuthPage from './pages/AuthPage/AuthPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import LoginPage from './pages/LoginPage/LoginPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import AccountPage from './pages/AccountPage/AccountPage';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import { ProjectProvider } from './provider/ProjectProvider';
import CreateProjectPage from './pages/ProjectPage/CreateProject/CreateProjectPage';
import { LoaderProvider, Loader } from './provider/LoaderProvider';
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage';
import SubscriptionPage from './pages/SubscriptionPage/SubscriptionPage';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import '@splidejs/react-splide/css';
import { ThemeProvider } from './provider/ThemeProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'src/components/ui/sonner';
import KnowledgeBasePage from 'src/pages/KnowledgeBasePage/KnowledgeBasePage';
import { ROUTE_PATHS } from 'src/utils/route-paths';
import IntegrationsPage from 'src/pages/IntegrationsPage/IntegrationsPage';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <AuthProvider>
            <div
              className={
                isMobile ? 'App_Container mobile' : 'App_Container desktop'
              }
            >
              <ProjectProvider>
                <LoaderProvider>
                  <Loader />
                  <Routes>
                    <Route
                      path={ROUTE_PATHS.RESET_PASSWORD}
                      element={<ResetPassword />}
                    />
                    <Route
                      path={ROUTE_PATHS.EMAIL_VERIFY}
                      element={<EmailVerify />}
                    />
                    <Route
                      path={ROUTE_PATHS.UNINSTALL_EXTENSION}
                      element={<UninstallExtension />}
                    />
                    <Route
                      path={ROUTE_PATHS.DASHBOARD}
                      breadcrumb={'Menu'}
                      element={
                        <RequireAuth redirect={ROUTE_PATHS.AUTH}>
                          <DashboardPage />
                        </RequireAuth>
                      }
                    >
                      <Route
                        path={ROUTE_PATHS.ACCOUNT}
                        breadcrumb={'Account'}
                        element={<AccountPage />}
                      />
                      <Route path={ROUTE_PATHS.PROJECTS} element={<Outlet />}>
                        <Route
                          path={ROUTE_PATHS.DASHBOARD_PROJECT}
                          element={<ProjectPage />}
                        />
                        <Route
                          path={ROUTE_PATHS.DASHBOARD_PROJECT_ANALYTICS}
                          element={
                            <RequireAuth redirect={ROUTE_PATHS.AUTH}>
                              <AnalyticsPage />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path={ROUTE_PATHS.DASHBOARD_PROJECT_KNOWLEDGE_BASE}
                          element={
                            <RequireAuth redirect={ROUTE_PATHS.AUTH}>
                              <KnowledgeBasePage />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path={ROUTE_PATHS.DASHBOARD_PROJECT_INTEGRATIONS}
                          element={
                            <RequireAuth redirect={ROUTE_PATHS.AUTH}>
                              <IntegrationsPage />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path={ROUTE_PATHS.DASHBOARD_PROJECT_NEW}
                          element={<CreateProjectPage />}
                        />
                      </Route>
                      <Route
                        path={ROUTE_PATHS.SUBSCRIPTION}
                        element={<SubscriptionPage />}
                      />
                    </Route>
                    <Route path={ROUTE_PATHS.AUTH} element={<AuthPage />} />
                    <Route
                      path={ROUTE_PATHS.REGISTER}
                      element={<RegisterPage />}
                    />
                    <Route path={ROUTE_PATHS.LOGIN} element={<LoginPage />} />
                    <Route path={ROUTE_PATHS.MAIN} element={<Main />} />
                  </Routes>
                </LoaderProvider>
              </ProjectProvider>
            </div>
            <div id="popup-root"></div>
            <Toaster />
          </AuthProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
