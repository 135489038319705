import React, { useEffect, useMemo } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { ProductsTypeBack } from 'src/constants/products';
import { useGetAllPlansQuery } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { clsx } from 'clsx';
import { TooltipContentPlans } from 'src/components/TooltipsContent/TooltipContentPlans';
import { useLoader } from 'src/provider/LoaderProvider';

export const useSubscriptionPlans = () => {
  const notification = useNotification();
  const loader = useLoader();

  const {
    data: plansAll,
    error: plansAllError,
    isError: plansAllIsError,
    isFetching: plansAllIsFetching,
  } = useGetAllPlansQuery();

  useEffect(() => {
    if (plansAllIsError) {
      notification.error(getErrorMessage(plansAllError));
    }
  }, [plansAllIsError]);

  useEffect(() => {
    if (plansAllIsFetching) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [plansAllIsFetching]);

  const Plans = useMemo(
    () =>
      plansAll?.map((item) => {
        return {
          label: item.name,
          id: item.id,
          price: item?.price,
          extra_request_price: item?.extra_request_price,
          request_limit: item?.request_limit,
          icon: (
            <div
              className={clsx(
                'h-2.5 w-2.5 rounded',
                item.target_service === ProductsTypeBack.agent && 'bg-malibu',
                item.target_service === ProductsTypeBack.bi &&
                  'bg-chartreuse_yellow',
                item.target_service === ProductsTypeBack.customer &&
                  'bg-purple',
              )}
            />
          ),
          disabled: false,
          toolTipDescription: <TooltipContentPlans item={item} />,
          target_service: item.target_service,
        };
      }),
    [plansAll, plansAllIsFetching],
  );

  return { Plans, plansAll, plansAllIsFetching };
};
