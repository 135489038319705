import React, { forwardRef, useState } from 'react';
import { ProjectSettingButton } from '../ProjectSettingButton';
import { EditIcon } from 'src/assets/img/edit-icon';
import { ProjectSettingsNames } from 'src/constants/projects';
import { PauseIcon } from 'src/assets/img/pause-icon';
import { DeleteIcon } from 'src/assets/img/delete-icon';
import { ProjectEditNameContent } from './ProjectEditNameContent';
import { ProjectHibernateContent } from './ProjectHibernateContent';
import { ProjectDeleteContent } from './ProjectDeleteContent';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { useGetAllProjectsQuery } from 'src/services';
import { useParams } from 'react-router-dom';
import { ProjectActivateContent } from 'src/components/ProjectSettings/Popups/ProjectActivateContent';

const ProjectPopupButton = forwardRef(
  ({ disabled, onClick = () => {}, variant, isActive }, ref) => {
    switch (variant) {
      case ProjectSettingsNames.RENAME: {
        return (
          <ProjectSettingButton
            onClick={onClick}
            disabled={disabled}
            title={'Edit project name'}
            icon={<EditIcon />}
          />
        );
      }
      case ProjectSettingsNames.HIBERNATE: {
        return (
          <ProjectSettingButton
            onClick={onClick}
            disabled={true}
            title={'Hibernate project'}
            icon={<PauseIcon />}
          />
        );
      }
      case ProjectSettingsNames.ACTIVATE: {
        return (
          <ProjectSettingButton
            onClick={onClick}
            disabled={disabled}
            title={isActive ? 'Deactivate project' : 'Activate project'}
            icon={<PauseIcon />}
          />
        );
      }
      case ProjectSettingsNames.DELETE: {
        return (
          <ProjectSettingButton
            onClick={onClick}
            disabled={disabled}
            title={'Delete project'}
            icon={<DeleteIcon />}
          />
        );
      }
    }
  },
);

export const ProjectSettingsPopup = ({
  onClickTrigger,
  disableTrigger,
  variant,
}) => {
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const { data: projects } = useGetAllProjectsQuery();

  const currentProject = projects?.find((project) => project.id === +id);

  const closeFunc = () => {
    setOpen(false);
  };

  const content = () => {
    switch (variant) {
      case ProjectSettingsNames.RENAME: {
        return <ProjectEditNameContent close={closeFunc} />;
      }
      case ProjectSettingsNames.HIBERNATE: {
        return <ProjectHibernateContent close={closeFunc} />;
      }
      case ProjectSettingsNames.ACTIVATE: {
        return (
          <ProjectActivateContent
            close={closeFunc}
            isActive={currentProject?.is_active}
          />
        );
      }
      case ProjectSettingsNames.DELETE: {
        return <ProjectDeleteContent close={closeFunc} />;
      }
    }
  };

  const contentTitle = () => {
    switch (variant) {
      case ProjectSettingsNames.RENAME: {
        return 'Edit project name';
      }
      case ProjectSettingsNames.HIBERNATE: {
        return 'Hibernate project';
      }
      case ProjectSettingsNames.ACTIVATE: {
        return currentProject?.is_active
          ? 'Deactivate project'
          : 'Activate project';
      }
      case ProjectSettingsNames.DELETE: {
        return 'Delete project';
      }
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild className={clsx('cursor-pointer')}>
        <ProjectPopupButton
          disabled={disableTrigger}
          onClick={onClickTrigger}
          variant={variant}
          isActive={currentProject?.is_active}
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-start">{contentTitle()}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 pt-2">{content()}</div>
      </DialogContent>
    </Dialog>
  );
};
