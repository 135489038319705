export const EditIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6 16C1.16 16 0.783467 15.8437 0.4704 15.531C0.157333 15.2183 0.000533333 14.842 0 14.402V3.21598C0 2.77653 0.1568 2.40047 0.4704 2.08779C0.784 1.77511 1.16053 1.61851 1.6 1.61798H8.74L7.14 3.21598H1.6V14.402H12.8V8.84894L14.4 7.25094V14.402C14.4 14.8414 14.2435 15.2178 13.9304 15.531C13.6173 15.8442 13.2405 16.0005 12.8 16H1.6ZM4.8 11.206V7.81024L12.14 0.479401C12.3 0.3196 12.48 0.19975 12.68 0.11985C12.88 0.03995 13.08 0 13.28 0C13.4933 0 13.6968 0.03995 13.8904 0.11985C14.084 0.19975 14.2605 0.3196 14.42 0.479401L15.54 1.61798C15.6867 1.77778 15.8 1.95436 15.88 2.14772C15.96 2.34107 16 2.53736 16 2.73658C16 2.93633 15.9635 3.13288 15.8904 3.32624C15.8173 3.5196 15.7005 3.69591 15.54 3.85518L8.2 11.206H4.8ZM6.4 9.60799H7.52L12.16 4.97378L11.6 4.41448L11.02 3.85518L6.4 8.46941V9.60799Z"
        fill="#05050D"
        fillOpacity="0.5"
      />
    </svg>
  );
};
