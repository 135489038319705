export const prepareHeaders = (headers, { getState, endpoint }) => {
  let user = localStorage.getItem('user');
  user = user && user !== 'undefined' && JSON.parse(user);
  const access = user && user.access;
  if (access) headers.set('Authorization', `Bearer ${access}`);
  headers.set('Accept-Language', localStorage.getItem('language') || 'en');
  headers.set(
    'Accept',
    'application/json, application/xml, text/plain, text/html, *.*',
  );
  const UPLOAD_ENDPOINTS = ['addIntegrationFile'];

  if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
    headers.set('Content-Type', 'application/json; charset=utf-8');
  }

  return headers;
};
