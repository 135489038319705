import { boolean, ref, string } from 'yup';
import valid from 'card-validator';

const EMAIL_REGEXP =
  /^[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const emailScheme = (opt) => {
  const rule = string()
    .email('Invalid format')
    .required('Required')
    .matches(EMAIL_REGEXP, 'Invalid format')
    .strict();

  if (opt?.required) return rule.required();

  return rule;
};

export const booleanScheme = (opt) => {
  const rule = boolean();
  if (opt?.required) return rule.required();

  return rule;
};

export const passwordScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value : true;
    })
    .when({
      is: (value) => value.length > 0,
      then: (value) =>
        string()
          .min(opt?.min || 8, `${opt?.minMessage}`)
          .max(opt?.max || 20),
    });

  // .max(opt?.max || 16);
  // .matches(/[0-9]/, 'Password requires a number')
  // .matches(/[a-z]/, 'Password requires a lowercase letter')
  // .matches(/[A-Z]/, 'Password requires an uppercase letter');
};

export const passwordConfirmScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value : true;
    })
    .when('password', (pass, schema) => {
      if (pass[0]) {
        return passwordScheme(schema, { required: true });
      }
      return schema;
    })
    .when({
      is: (value) => value.length > 0,
      then: (value) =>
        string()
          .min(opt?.min || 8)
          .max(opt?.max || 20)
          .oneOf([ref('password')], 'Your passwords do not match.'),
    });
};

export const stringScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value.trim() : true;
    })
    .min(opt?.min || 0, `${opt?.minMessage}`);
};

export const creditCardNumberScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value.trim() : true;
    })
    .test(
      'test-number',
      'Credit Card number is invalid',
      (value) => valid.number(value).isValid,
    );
};
export const expirationCreditCardScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value.trim() : true;
    })
    .test(
      'test-expiration',
      'Credit Card Expiration date is invalid',
      (value) => valid.expirationDate(value).isValid,
    );
};
export const CVVCreditCardScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value.trim() : true;
    })
    .test(
      'test-cvv',
      'CVC format is invalid',
      (value) => valid.cvv(value).isValid,
    );
};
export const holderCreditCardScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value.trim() : true;
    })
    .test(
      'test-holder',
      'Card Holder is invalid',
      (value) => valid.cardholderName(value).isValid,
    );
};

export const phoneNumberScheme = (opt) => {
  return string()
    .test('required', 'Required', (value) => {
      return opt?.required ? !!value : true;
    })
    .min(opt?.min || 5, 'Minimum 5 digits')
    .max(opt?.max || 15, 'Maximum 15 digits');
};
