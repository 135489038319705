import './RegisterPage.css';
import React from 'react';
import { AuthWrapper } from '../../components/AuthWrapper/AuthWrapper';
import { RegisterForm } from './RegisterForm';

const RegisterPage = () => {
  return (
    <AuthWrapper title="Create your account and start to use CoSupport AI client cabinet!">
      <RegisterForm />
    </AuthWrapper>
  );
};

export default RegisterPage;
