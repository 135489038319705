import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import Button from '../../Button/Button';
import { useDeleteProjectMutation, useGetAllProjectsQuery } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';

export const ProjectDeleteContent = ({
  close = () => {},
  onlyForm = false,
}) => {
  const notification = useNotification();
  const { id } = useParams();
  const loader = useLoader();
  const navigate = useNavigate();

  const { data: projects, isFetching: isFetchingProjects } =
    useGetAllProjectsQuery();

  const currentProject = projects?.find((project) => project.id === +id);

  const [
    deleteProject,
    {
      isFetching: isFetchingDelete,
      isError: isDeleteProjectError,
      error: deleteProjectError,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteProjectMutation();

  const deleteHandler = () => {
    deleteProject({
      projectId: id,
      name: currentProject.name,
      ai_reply_generation: currentProject.ai_reply_generation,
      ai_text_lense: currentProject.ai_text_lense,
    }).then(() =>
      notification.success(`Project ${currentProject.name} was deleted!`),
    );
    loader.show();
  };

  useEffect(() => {
    if (!isFetchingDelete) {
      loader.hide();
    }
    if (isSuccessDelete) {
      close();
    }
  }, [isFetchingDelete, isSuccessDelete]);

  useEffect(() => {
    if (isSuccessDelete && !isFetchingProjects) {
      navigate(`${PATH_DASHBOARD.ACCOUNT}`);
    }
  }, [isSuccessDelete, isFetchingProjects]);

  useEffect(() => {
    if (isDeleteProjectError) {
      notification.error(getErrorMessage(deleteProjectError));
    }
  }, [isDeleteProjectError]);

  return (
    <>
      <div className="content">
        <div className="sub-title flex flex-col gap-1.5">
          <b className="text-base text-black">
            Are you sure you want to delete this project irretrievably?
          </b>
          <h4>
            If you want to suspend the project activity for a certain time, use
            the ‘Hibernate project’ option.
          </h4>
        </div>
      </div>
      <div className="footer flex justify-end mt-5">
        <div className="help-container"></div>
        <div className="action-buttons flex gap-2.5">
          {!onlyForm && (
            <Button
              className="transparent black min-w-28"
              onClick={() => close()}
            >
              Cancel
            </Button>
          )}
          <Button className="transparent red min-w-28" onClick={deleteHandler}>
            Yes, delete
          </Button>
        </div>
      </div>
    </>
  );
};
