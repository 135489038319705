import './DashboardPage.css';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import Logo from '../../assets/img/icon-128.png';
import { ProjectSettings } from 'src/components/ProjectSettings/ProjectSettings';
import { clsx } from 'clsx';
import { Menu } from 'src/components/Menu/Menu';
import { MobileMenuDrawer } from 'src/components/Menu/MobileMenu/MobileMenuDrawer';
import { CompanySelectComponent } from 'src/components/CompanySelectComponent/CompanySelectComponent';
import { useTheme } from 'src/provider/ThemeProvider';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { useGetAllProjectsQuery } from 'src/services';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { ChangePasswordModal } from 'src/components/ChangePasswordModal/ChangePasswordModal';
import { Skeleton } from 'src/components/ui/skeleton';
import { ROLES } from 'src/constants/roles';
import { ProductsTypeColor } from 'src/constants/products';
import { useAppSelector } from 'src/store';

const DashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loader = useLoader();
  const notification = useNotification();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { config, setTheme } = useTheme();
  const searchParams = new URLSearchParams(location.search);
  const searchProjectType = searchParams.get('type');

  const { projectType } = useAppSelector((s) => s.projectsSlices);

  const projectTypeVar = projectType || searchProjectType;

  useEffect(() => {
    if (searchProjectType) {
      setTheme(ProductsTypeColor[projectTypeVar]);
    }
  }, [searchProjectType, location]);

  const {
    data: userInfo,
    isFetching: isFetchingUserInfo,
    isError: userInfoIsError,
    error: userInfoError,
  } = useGetUsersMeQuery();

  const {
    data: projects = [],
    isSuccess: isSuccessProjects,
    isLoading: isLoadingProjects,
    isError: isErrorProjects,
    error: errorProjects,
  } = useGetAllProjectsQuery();

  const isDashboard = location.pathname === PATH_DASHBOARD.ROOT;

  useEffect(() => {
    if (isLoadingProjects) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingProjects]);

  useEffect(() => {
    if (!isLoadingProjects && !projects.length) {
      isDashboard && navigate(PATH_DASHBOARD.PROJECTS_NEW);
    }
    if (isSuccessProjects && projects.length) {
      isDashboard && navigate(`${PATH_DASHBOARD.PROJECTS}/${projects[0].id}`);
    }
  }, [projects, isSuccessProjects, isLoadingProjects]);

  useEffect(() => {
    if (isErrorProjects) {
      notification.error(getErrorMessage(errorProjects));
    }
    if (userInfoIsError) {
      notification.error(getErrorMessage(userInfoError));
    }
  }, [isErrorProjects, userInfoIsError]);

  const isProjectLocation = location.pathname.includes('projects');
  const isAccountLocation = location.pathname.includes('account');
  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  return (
    <React.Fragment>
      <div className={`dashboard-page-container ${config.className}`}>
        {!isLgDown && <Menu />}
        <div className="dashboard-container">
          <div className="dashboard-header min-h-[190px] md:min-h-[230px]">
            <div className="container pt-[18px] px-4 pb-[18px] md:px-[26px] md:pb-[80px] md:pt-[30px]">
              {!isLgDown && (
                <div
                  className={clsx(
                    'flex flex-col gap-[14px] relative',
                    isProjectLocation && 'gap-8',
                  )}
                >
                  <div
                    className={clsx(
                      'breadcrumbs-container flex gap-2.5 h-9 items-center justify-between',
                    )}
                  >
                    <div className="username-container">
                      <h1 className="text-sm flex items-center gap-2.5">
                        <span className="italic font-normal opacity-50">
                          Welcome to Client Cabinet,{' '}
                        </span>
                        {isFetchingUserInfo ? (
                          <Skeleton className="h-3.5 w-32" />
                        ) : (
                          <b>
                            {userInfo?.first_name} {userInfo?.last_name}!
                          </b>
                        )}
                      </h1>
                    </div>
                    <div className="flex gap-2.5">
                      {isProjectLocation && (
                        <div className="breadcrumbs">
                          <ProjectSettings />
                        </div>
                      )}
                      {isAccountLocation && (
                        <div className="breadcrumbs">
                          <ChangePasswordModal />
                        </div>
                      )}

                      {isCosupportManager && userInfo?.managed_accounts && (
                        <div className="breadcrumbs">
                          <CompanySelectComponent />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {isLgDown && (
                <div className="flex justify-between items-center">
                  <div className="username-container flex items-center gap-[12px]">
                    <img
                      src={Logo}
                      alt="Logo"
                      className="h-[37px] w-[37px] md:h-[40px] md:w-[40px]"
                    />
                    <div className="flex items-start flex-col">
                      <h1 className="text-base md:text-xl">
                        Hi{' '}
                        <span className="italic">
                          {userInfo?.first_name} {userInfo?.last_name}!
                        </span>
                      </h1>
                      <p className="text-[rgba(5,5,13,0.4)] text-sm md:text-base">
                        CoSupport Agent client cabinet
                      </p>
                    </div>
                  </div>
                  <MobileMenuDrawer />
                </div>
              )}
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
