import './KnowledgeBasePage.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNotification } from '../../hooks/useNotification';
import { useLoader } from '../../provider/LoaderProvider';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../components/CardContainer/CardContainer';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';
import {
  useGetProjectByIdQuery,
  useGetIntegrationFilesQuery,
  useAddIntegrationFileMutation,
  useUpdateIntegrationFilesMutation,
  useDeleteIntegrationFilesMutation,
  useAddIntegrationDocumentMutation,
  baseApi,
  useDeleteIntegrationFileMutation,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useDispatch } from 'react-redux';
import {
  createFile,
  createFolder,
  findActiveBranch,
  getCheckedFiles,
  getChildrenFiles,
  getFoldersStructure,
  setCheckedChildren,
  sortByType,
  updateBranchInTree,
  updateCheckedPaths,
} from 'src/utils/fileUtils';
import { FilesManagerListView } from 'src/components/FileManager/ListView/ListView';
import Button from 'src/components/Button/Button';
import { AddFolderPopup } from 'src/components/FileManager/AddFolderPopup/AddFolderPopup';
import { UploadFileIcon } from 'src/assets/icons/UploadFileIcon';
import { CreateDocumentPopup } from 'src/components/FileManager/CreateDocumentPopup/CreateDocumentPopup';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';
import {
  DeleteContentIcon,
  DeleteFilesIcon,
  MoveContentIcon,
  MoveFilesIcon,
} from 'src/assets/icons/FileIcons';
import { MoveFilesPopup } from 'src/components/FileManager/MoveFilesPopup/MoveFilesPopup';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';

const getInitialTree = () => {
  return {
    name: 'Knowledge Base',
    type: 'folder', // can be 'folder', 'file'
    path: '',
    root: true,
    parent: false,
    children: [],
  };
};

const KnowledgeBasePage = () => {
  const initialTree = getInitialTree();
  const [dragStarted, setDragStarted] = useState(false);
  const [tree, setTree] = useState(initialTree);
  const [activeBranch, setActiveBranch] = useState(initialTree);
  const [moveContentMode, setMoveContentMode] = useState(false);
  const [deleteContentMode, setDeleteContentMode] = useState(false);
  const [syncArg, setSyncArg] = useState(true);
  const notification = useNotification();
  const loader = useLoader();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const {
    data: project,
    isLoading,
    error,
    isError,
  } = useGetProjectByIdQuery({ id: id });

  const {
    data: integrationFilesResponse,
    error: errorIntegrationFiles,
    isError: isErrorIntegrationFiles,
    isLoading: isLoadingIntegrationFiles,
    refetch: refetchIntegrationFiles,
  } = useGetIntegrationFilesQuery(
    {
      projectId: id,
      integrationId: project?.integration?.id,
      sync: syncArg,
    },
    { skip: !project?.integration?.id },
  );

  const [
    addIntegrationFile,
    {
      error: errorAddIntegrationFile,
      isError: isErrorAddIntegrationFile,
      isSuccess: isSuccessAddIntegrationFile,
    },
  ] = useAddIntegrationFileMutation();

  const [
    addIntegrationDocument,
    {
      error: errorAddIntegrationDocument,
      isError: isErrorAddIntegrationDocument,
      isSuccess: isSuccessAddIntegrationDocument,
    },
  ] = useAddIntegrationDocumentMutation();

  const [
    deleteIntegrationFiles,
    {
      error: errorDeleteIntegrationFiles,
      isError: isErrorDeleteIntegrationFiles,
      isSuccess: isSuccessDeleteIntegrationFiles,
    },
  ] = useDeleteIntegrationFilesMutation();
  const [
    deleteIntegrationFile,
    {
      error: errorDeleteIntegrationFile,
      isError: isErrorDeleteIntegrationFile,
      isSuccess: isSuccessDeleteIntegrationFile,
    },
  ] = useDeleteIntegrationFileMutation();
  const [
    updateIntegrationFiles,
    {
      error: errorUpdateIntegrationFiles,
      isError: isErrorUpdateIntegrationFiles,
      isSuccess: isSuccessUpdateIntegrationFiles,
    },
  ] = useUpdateIntegrationFilesMutation();

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorIntegrationFiles) {
      notification.error(getErrorMessage(errorIntegrationFiles));
    }

    if (isErrorAddIntegrationFile) {
      notification.error(getErrorMessage(errorAddIntegrationFile));
    }

    if (isErrorUpdateIntegrationFiles) {
      notification.error(getErrorMessage(errorUpdateIntegrationFiles));
    }

    if (isErrorDeleteIntegrationFile) {
      notification.error(getErrorMessage(errorDeleteIntegrationFile));
    }

    if (isErrorDeleteIntegrationFiles) {
      notification.error(getErrorMessage(errorDeleteIntegrationFiles));
    }

    if (isErrorAddIntegrationDocument) {
      notification.error(getErrorMessage(errorAddIntegrationDocument));
    }
  }, [
    isErrorIntegrationFiles,
    isErrorAddIntegrationFile,
    isErrorUpdateIntegrationFiles,
    isErrorDeleteIntegrationFile,
    isErrorDeleteIntegrationFiles,
    isErrorAddIntegrationDocument,
  ]);

  useEffect(() => {
    if (isLoadingIntegrationFiles) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingIntegrationFiles]);

  useEffect(() => {
    if (integrationFilesResponse) {
      setFiles(integrationFilesResponse);
    }
  }, [integrationFilesResponse]);

  useEffect(() => {
    let timeout;

    if (files.length) {
      const foldersStructure = getFoldersStructure(files);
      const branch = findActiveBranch(foldersStructure);

      setTree(foldersStructure);
      updateActiveBranch(branch || foldersStructure);

      const deletingExist = files.some((file) => file.state === 'deleting');

      if (deletingExist) {
        timeout = setTimeout(async () => {
          const { isError, error } = await refetchIntegrationFiles();

          if (isError) {
            notification.error(getErrorMessage(error));
          }
        }, 2000);
      }
    } else {
      const emptyTree = getInitialTree();

      setTree(emptyTree);
      updateActiveBranch(emptyTree);
    }

    return () => timeout && clearTimeout(timeout);
  }, [files]);

  useEffect(() => {
    sessionStorage.setItem('fileManager-active-path', activeBranch.path);
  }, [activeBranch]);

  const updateActiveBranch = (branch) => {
    loader.show();
    sortByType(branch.children, 'folder');
    setActiveBranch(branch);
    loader.hide();
  };
  //
  // useEffect(() => {
  //   if (isSuccessAddIntegrationFile) {
  //     notification.success('Successfully added a file.');
  //   }
  //
  //   if (isSuccessUpdateIntegrationFiles) {
  //     notification.success('Successfully updated.');
  //   }
  //
  //   if (isSuccessDeleteIntegrationFile) {
  //     notification.success('Successfully deleted a file.');
  //   }
  //
  //   if (isSuccessDeleteIntegrationFiles) {
  //     notification.success('Successfully deleted.');
  //   }
  //
  //   if (isSuccessAddIntegrationDocument) {
  //     notification.success('Successfully created a file.');
  //   }
  // }, [
  //   isSuccessAddIntegrationFile,
  //   isSuccessUpdateIntegrationFiles,
  //   isSuccessDeleteIntegrationFile,
  //   isSuccessDeleteIntegrationFiles,
  //   isSuccessAddIntegrationDocument,
  // ]);

  const uploadFiles = async (files) => {
    const newFiles = [];

    loader.show({ progress: true });

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const request = new FormData();
        const filePath = activeBranch.path || '';

        request.append('file', file, file.name);
        request.append('file_path', filePath);

        const response = await addIntegrationFile({
          projectId: id,
          integrationId: project.integration.id,
          body: request,
        });

        loader.updateProgress(((i + 1) / files.length) * 100);

        if (response && response.data && !response.data.status) {
          newFiles.push(response.data);
        }
      }

      console.log('complete!');
    }

    loader.hide();

    return newFiles;
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = await uploadFiles(event.dataTransfer.files);

    addFilesToBranch(newFiles, activeBranch);
    setDragStarted(false);
  };

  const addNewFolder = (folder_name) => {
    const folder = createFolder(folder_name, activeBranch);
    const newActiveBranch = {
      ...activeBranch,
    };
    newActiveBranch.children.push(folder);
    updateBranchInTree(tree, activeBranch, newActiveBranch);
    updateActiveBranch(newActiveBranch);
  };

  const addFilesToBranch = (files, branch) => {
    const newBranch = {
      ...branch,
    };

    files.forEach((file) => {
      const fileItem = createFile(file, newBranch);

      newBranch.children.push(fileItem);
    });

    sortByType(newBranch.children, 'folder');
    updateBranchInTree(tree, branch, newBranch);

    if (branch === activeBranch) {
      updateActiveBranch(newBranch);
    }
  };

  const onFileChange = async (event) => {
    if (!event.target.files.length) {
      return;
    }

    const newFiles = await uploadFiles(event.target.files);

    addFilesToBranch(newFiles, activeBranch);
  };

  const createDocument = async (data) => {
    const newFiles = [];

    loader.show();
    const response = await addIntegrationDocument({
      projectId: id,
      integrationId: project.integration.id,
      body: {
        ...data,
        path: activeBranch.path,
      },
    });
    loader.hide();

    if (!response.data.status) {
      newFiles.push(response.data);
    }

    newFiles.length && addFilesToBranch(newFiles, activeBranch);
  };

  const handleMoveItems = async (moveToFolder) => {
    const checkedFiles = getCheckedFiles(tree);
    const filesWithUpdatedPaths = updateCheckedPaths(
      checkedFiles,
      moveToFolder.path,
    );
    const request = filesWithUpdatedPaths
      .filter((file) => file.type === 'file')
      .map((file) => {
        return {
          id: file.id,
          path: file.newPath.replace(`/${file.name}`, ''),
          name: file.name,
        };
      });

    if (request.length) {
      loader.show();
      await updateIntegrationFiles({
        projectId: id,
        integrationId: project?.integration?.id,
        body: request,
      });
      loader.hide();
      setMoveContentMode(false);
    }
  };

  const handleDeleteItems = async (files) => {
    const filesToDelete = files || getCheckedFiles(tree);
    const request = filesToDelete
      .filter((file) => file.type === 'file')
      .map((file) => file.id);

    if (request.length) {
      loader.show();
      await deleteIntegrationFiles({
        projectId: id,
        integrationId: project?.integration?.id,
        body: request,
      });

      if (syncArg) {
        setSyncArg(false);
      } else {
        const { isError, error } = await refetchIntegrationFiles();

        if (isError) {
          notification.error(getErrorMessage(error));
        }
      }

      loader.hide();
      setDeleteContentMode(false);
    }
  };

  const handleSingleItemMove = async (moveToFolder, item) => {
    item.checked = true;
    if (item.type === 'folder') {
      setCheckedChildren(item);
    }

    await handleMoveItems(moveToFolder);
  };
  const handleSingleItemRename = async (name, item) => {
    let files = [item];

    if (item.type === 'folder') {
      files = getChildrenFiles(item);
    }

    let request = files.map((file) => {
      if (item.type === 'file') {
        return {
          id: file.id,
          name: name,
          path: file.parent.path,
        };
      }
      if (item.type === 'folder') {
        const newPath = item.path.replace(`/${item.name}`, `/${name}`);

        return {
          id: file.id,
          name: file.name,
          path: file.path
            .replace(`${item.path}`, newPath)
            .replace(`/${file.name}`, ''),
        };
      }
    });

    if (request.length) {
      loader.show();
      await updateIntegrationFiles({
        projectId: id,
        integrationId: project?.integration?.id,
        body: request,
      });
      loader.hide();
    }
  };
  const handleSingleItemDelete = async (item) => {
    if (item.type === 'file') {
      loader.show();
      await deleteIntegrationFile({
        projectId: id,
        integrationId: project?.integration?.id,
        fileId: item.id,
      });
      loader.hide();
    }

    if (item.type === 'folder') {
      const files = getChildrenFiles(item);

      await handleDeleteItems(files);
    }
  };

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx(
          'knowledge-base-page-container',
          !isLgDown && 'relative',
        )}
      >
        <div className="z-10 gap-[10px] flex flex-col relative h-[100%]">
          <CardContainer>
            {project && project.integration ? (
              <>
                <div className={'knowledge-base-container'}>
                  <div className="knowledge-base-buttons flex justify-start gap-[8px]">
                    <div>
                      <AddFolderPopup
                        activeBranch={activeBranch}
                        addFolder={addNewFolder}
                      />
                    </div>
                    <div>
                      <Button
                        className="transparent black gap-[10px]"
                        onClick={() => inputFile.current.click()}
                      >
                        Upload file
                        <UploadFileIcon />
                      </Button>
                    </div>
                    <div>
                      <CreateDocumentPopup createDocument={createDocument} />
                    </div>
                    <div className="flex flex-1 justify-end items-center gap-[20px]">
                      <div
                        className={clsx(
                          moveContentMode && 'active',
                          'move-content-button',
                        )}
                        onClick={() => {
                          setMoveContentMode(!moveContentMode);
                          !moveContentMode && setDeleteContentMode(false);
                        }}
                      >
                        <MoveContentIcon />
                        Move content
                      </div>
                      <div
                        className={clsx(
                          deleteContentMode && 'active',
                          'delete-content-button',
                        )}
                        onClick={() => {
                          setDeleteContentMode(!deleteContentMode);
                          !deleteContentMode && setMoveContentMode(false);
                        }}
                      >
                        <DeleteContentIcon />
                        Delete content
                      </div>
                    </div>
                  </div>
                  <section
                    className={clsx(
                      dragStarted && 'drag-active',
                      'max-h-[70vh] overflow-hidden mt-[20px]',
                    )}
                    onDrop={handleDrop}
                    onDragEnter={(event) => {
                      event.preventDefault();
                      setDragStarted(true);
                    }}
                    onDragOver={(event) => {
                      event.preventDefault();
                    }}
                    onDragLeave={(event) => {
                      event.preventDefault();
                      setDragStarted(false);
                    }}
                  >
                    <input
                      type="file"
                      ref={inputFile}
                      className={'hidden'}
                      onChange={onFileChange}
                      multiple={true}
                      accept={KNOWLEDGE_BASE_FILE_EXTENSIONS.join(', ')}
                    />
                    {activeBranch && (
                      <FilesManagerListView
                        className={'h-[70vh] overflow-auto'}
                        handleSingleItemMove={handleSingleItemMove}
                        handleSingleItemRename={handleSingleItemRename}
                        handleSingleItemDelete={handleSingleItemDelete}
                        checkboxVisible={moveContentMode || deleteContentMode}
                        activeBranch={activeBranch}
                        updateActiveBranch={updateActiveBranch}
                      />
                    )}
                  </section>
                </div>
                <div className="flex justify-center">
                  {moveContentMode && (
                    <MoveFilesPopup
                      moveFilesCallback={handleMoveItems}
                      item={tree}
                      triggerEl={
                        <Button className="purple knowledge-base-move-files-button">
                          Move
                          <MoveFilesIcon />
                        </Button>
                      }
                    />
                  )}
                  {deleteContentMode && (
                    <DeletePopup
                      title={'Delete content'}
                      description={
                        'Are you sure you want to delete this content irretrievably?'
                      }
                      deleteHandler={handleDeleteItems}
                      triggerIcon={
                        <Button className="red knowledge-base-delete-files-button">
                          Delete content
                          <DeleteFilesIcon />
                        </Button>
                      }
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="flex items-center w-full md:p-[20px] p-[16px]">
                <p className="font-medium text-base text-ebony opacity-70">
                  There is no integrations yet.
                </p>
              </div>
            )}
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default KnowledgeBasePage;
