import React, { useEffect, useState } from 'react';
import Button from 'src/components/Button/Button';
import { PaymentMethodForm } from './PaymentMethodForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import AddButtonIcon from 'src/assets/img/add-button-icon.svg';
import { getServerEnvironment } from 'src/env/env';
import { loadStripe } from '@stripe/stripe-js';

export const AddPaymentMethodPopup = ({ teamMember, disabled = false }) => {
  const [open, setOpen] = useState(false);

  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (!stripe && open) {
      (async () => {
        const { STRIPE_PUBLIC_KEY } = await getServerEnvironment();
        const stripeObject = await loadStripe(STRIPE_PUBLIC_KEY);

        setStripe(stripeObject);
      })();
    }
  }, [stripe, open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <Button className="transparent">
          <p>
            Add <span className="max-md:hidden">payment method</span>
          </p>
          <img src={AddButtonIcon} alt="add icon" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add payment method</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <PaymentMethodForm stripe={stripe} handleClose={handleClose} />
          <span className="font-medium text-base opacity-50 text-ebony max-md:hidden">
            * this field is mandatory
          </span>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            disabled={!stripe}
            style={{ width: 'auto' }}
            type="submit"
            form="add-payment-method-form"
            className="green"
          >
            Save
          </Button>
        </DialogFooter>
        <span className="font-medium text-sm opacity-50 text-ebony md:hidden">
          * this field is mandatory
        </span>
      </DialogContent>
    </Dialog>
  );
};
