import React from 'react';
import { clsx } from 'clsx';

export const ProjectSettingButton = ({
  title,
  icon,
  disabled,
  onClick = () => {},
  children,
}) => {
  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={clsx(
        'text-xs font-medium p-3 rounded-md flex justify-between shadow items-center bg-[#E7F7F7] hover:bg-white transition cursor-pointer w-44',
        disabled && 'opacity-50 pointer-events-none',
      )}
    >
      <span>{title}</span>
      {children}
      {icon}
    </div>
  );
};
