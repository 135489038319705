import React from 'react';
import { Drawer } from 'vaul';
import { clsx } from 'clsx';

export const DrawerComponent = ({
  drawerTrigger,
  drawerContent,
  drawerTitle = 'Menu',
  triggerClassName,
  contentClassName,
  darkMode = false,
  setOpen,
  open,
}) => {
  return (
    <Drawer.Root direction="right" open={open} onOpenChange={setOpen}>
      <Drawer.Trigger asChild className={triggerClassName}>
        {drawerTrigger}
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40 backdrop-blur-sm z-40" />
        <Drawer.Content
          className={clsx(
            contentClassName,
            'flex flex-col h-full w-5/6 max-w-xl fixed bottom-0 right-0 z-50',
            darkMode ? 'bg-woodsmoke' : 'bg-light',
          )}
        >
          <Drawer.Title hidden>{drawerTitle}</Drawer.Title>
          <div
            className={clsx(
              darkMode ? 'bg-woodsmoke' : 'bg-light',
              'flex-1 h-full flex flex-col overflow-auto',
            )}
          >
            {drawerContent}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
