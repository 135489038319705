import './ProjectPage.css';
import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { CurrentProjectScreen } from './ProjectSreens/CurrentProjectScreen';
import { ProjectsScreen } from './ProjectSreens/ProjectsScreen';
import { SettingsScreen } from './ProjectSreens/SettingsScreen';
import { useGetAllProjectsQuery } from 'src/services';
import { useParams } from 'react-router-dom';
import { useTheme } from 'src/provider/ThemeProvider';

const ProjectPage = () => {
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const splideRef = useRef(null);
  const { id } = useParams();
  const { data: projectsData } = useGetAllProjectsQuery();
  const { setTheme } = useTheme();

  useEffect(() => {
    if (projectsData && splideRef.current?.splide.index !== 1) {
      splideRef.current?.splide.go(1);
    }
  }, [projectsData, id]);

  useEffect(() => {
    if (splideRef.current) {
      splideRef.current?.splide.on('move', function () {
        window.scrollTo(0, 0);
      });
    }
  }, [splideRef]);

  useEffect(() => {
    setTheme('blue');
  }, []);

  return (
    <React.Fragment>
      {isLgDown ? (
        <Splide
          ref={splideRef}
          className="project-mobile-slider"
          options={{
            start: 1,
            arrows: false,
            resetProgress: true,
          }}
        >
          <SplideSlide>
            <ProjectsScreen />
          </SplideSlide>
          <SplideSlide>
            <CurrentProjectScreen />
          </SplideSlide>
          <SplideSlide>
            <SettingsScreen />
          </SplideSlide>
        </Splide>
      ) : (
        <CurrentProjectScreen />
      )}
    </React.Fragment>
  );
};

export default ProjectPage;
