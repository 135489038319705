import FilterIcon from '../../assets/img/filter-icon';
import React from 'react';

export const FilterPopupButton = ({ disabled = false }) => {
  return (
    <button className="action-button w-auto bg-transparent" disabled={disabled}>
      <FilterIcon />
    </button>
  );
};
