import React from 'react';
import Input from '../Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import {
  emailScheme,
  passwordConfirmScheme,
  passwordScheme,
  stringScheme,
} from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';

export const EditUserForm = ({ user, onSubmit }) => {
  const notification = useNotification();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      password: '',
      password2: '',
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }).label('Email'),
        first_name: stringScheme({ required: true }).label('First name'),
        last_name: stringScheme({ required: false }).label('Last name'),
        password: passwordScheme({
          required: false,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Password'),
        password2: passwordConfirmScheme({
          required: false,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Confirm password'),
      }),
    ),
  });

  const submit = (formValues) => {
    const filteredData = Object.keys(dirtyFields).reduce((acc, key) => {
      if (dirtyFields[key]) {
        acc[key] = formValues[key];
      }
      return acc;
    }, {});

    if (!Object.keys(filteredData).length) {
      notification.info('Nothing changed');
    } else {
      onSubmit(filteredData);
    }
  };

  return (
    <form id="edit-user-form" onSubmit={handleSubmit(submit)}>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'email'}
              label={'Email'}
              placeholder={'email@email.com'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
              onBlur={() => trigger('email')}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="first_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              autoFocus
              type={'text'}
              label={'First Name'}
              placeholder={'Josh'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
              onBlur={() => trigger('firstName')}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="last_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Last Name'}
              placeholder={'Brawn'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'password'}
              label={'Password'}
              placeholder={'••••••••••••••••••••••••••••'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="password2"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'password'}
              label={'Confirm Password'}
              placeholder={'••••••••••••••••••••••••••••'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
    </form>
  );
};
