export default function CloseIcon({
  fillColor = 'none',
  className = '',
  stroke = '#05050D',
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 16 17"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="14.8497"
        y1="1.20711"
        x2="0.707548"
        y2="15.3492"
        stroke={stroke}
        strokeWidth="2"
      />
      <line
        x1="14.8495"
        y1="15.3492"
        x2="0.7074"
        y2="1.20706"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
}
