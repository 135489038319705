import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  managedAccountId: '',
  companyChanged: false,
};
const companySlice = createSlice({
  name: 'companySlice',
  initialState,
  reducers: {
    selectManagedAccountId: (state, action) => {
      state.managedAccountId = action.payload;
    },
    setIsCompanyChanged: (state, action) => {
      state.companyChanged = action.payload;
    },
  },
});

export const { selectManagedAccountId, setIsCompanyChanged } =
  companySlice.actions;
export default companySlice.reducer;
