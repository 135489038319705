import React, { useEffect } from 'react';
import Button from '../../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import {
  useActivateProjectMutation,
  useDeactivateProjectMutation,
} from 'src/services';
import { useParams } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import { getErrorMessage } from 'src/utils/get-error-message';

export const ProjectActivateContent = ({
  onlyForm = false,
  close = () => {},
  isActive,
}) => {
  const notification = useNotification();
  const { id } = useParams();
  const loader = useLoader();

  const [
    activateProject,
    {
      isLoading,
      isError: isActivateProjectError,
      error: activateProjectError,
      isSuccess,
    },
  ] = useActivateProjectMutation();

  const [
    deactivateProject,
    {
      isLoading: deactivateProjectIsLoading,
      isError: isDeactivateProjectError,
      error: deactivateProjectError,
      isSuccess: deactivateProjectIsSuccess,
    },
  ] = useDeactivateProjectMutation();

  useEffect(() => {
    if (isLoading || deactivateProjectIsLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoading, deactivateProjectIsLoading]);

  useEffect(() => {
    if (isSuccess || deactivateProjectIsSuccess) {
      close();
      notification.success(
        !isActive
          ? 'Project has been deactivated!'
          : 'Project has been activated!',
      );
    }
  }, [isSuccess, deactivateProjectIsSuccess]);

  useEffect(() => {
    if (isActivateProjectError) {
      notification.error(getErrorMessage(activateProjectError));
    }
    if (isDeactivateProjectError) {
      notification.error(getErrorMessage(deactivateProjectError));
    }
  }, [isActivateProjectError, isDeactivateProjectError]);

  const projectHibernateHandler = () => {
    if (isActive) {
      deactivateProject({ projectId: id });
    } else {
      activateProject({ projectId: id });
    }
  };

  return (
    <>
      <div className="content">
        <div className="sub-title flex flex-col gap-1.5">
          <h4>
            Are you sure you want to {isActive ? 'deactivate' : 'activate'} this
            project?.
          </h4>
        </div>
      </div>
      <div className="footer flex justify-end mt-5">
        <div className="help-container"></div>
        <div className="action-buttons flex gap-2.5">
          {!onlyForm && (
            <Button
              className="transparent black min-w-28"
              onClick={() => close()}
            >
              Cancel
            </Button>
          )}
          <Button onClick={projectHibernateHandler} className="blue min-w-28">
            {isActive ? 'Deactivate' : 'Activate'}
          </Button>
        </div>
      </div>
    </>
  );
};
