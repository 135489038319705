export const MetricsOptions = [
  {
    value: 'subdomain_ticket_closed_time',
    label: 'Full resolution time',
  },
  {
    value: 'subdomain_first_reply_time',
    label: 'First reply time',
  },
  {
    value: 'subdomain_delay_reply_time',
    label: 'Delay reply time',
  },
];
