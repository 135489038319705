import './UserAvatar.css';
import React from 'react';

export const UserAvatar = ({ user }) => {
  const first = user.first_name && user.first_name.length && user.first_name[0];
  const second = user.last_name && user.last_name.length && user.last_name[0];

  return (
    <div className={`user-avatar ${user.is_active ? 'active' : 'onhold'}`}>
      <span>{`${first || ''}${second || ''}`}</span>
    </div>
  );
};
