import React from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { useAuth } from 'src/provider/AuthProvider';
import { useNotification } from 'src/hooks/useNotification';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import {
  emailScheme,
  passwordScheme,
  stringScheme,
} from 'src/utils/validation-schemes';
import { useLoader } from 'src/provider/LoaderProvider';
import { ProductItems } from 'src/constants/products';

export const LoginForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const notification = useNotification();
  const loader = useLoader();

  const submit = async (data) => {
    const { email, password } = data;

    loader.show();
    const response = await auth.signin({ email, password });

    if (response) {
      loader.hide();
    }

    if (response instanceof Error) {
      return response.message && notification.error(response.message);
    }

    navigate('/dashboard');
  };

  const { handleSubmit, trigger, control, clearErrors } = useForm({
    defaultValues: {
      email: '',
      password: '',
      product: { label: 'CoSupport Agent' },
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }).label('Email'),
        product: object({
          label: stringScheme({ required: false }).label('Product'),
        }),
        password: stringScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Password'),
      }),
    ),
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(submit)}>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="product"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                selectOptions={ProductItems}
                type={'select'}
                label={'Choose the product you will work with:'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'email'}
                label={'Email'}
                placeholder={'email@email.com'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('email')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'password'}
                label={'Password'}
                placeholder={'••••••••••••••••••••••••••••'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('password')}
                onFocus={() => clearErrors('password')}
              />
            )}
          />
        </div>
        <Button type="submit" className="blue md:mt-7 mt-6">
          Log In
        </Button>
      </form>
    </React.Fragment>
  );
};
