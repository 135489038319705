import '../ProjectPage.css';
import React, { useEffect } from 'react';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import { CreateProjectModal } from '../CreateProject/CreateProjectModal';
import { useGetAllProjectsQuery } from '../../../services';
import CardContainer from '../../../components/CardContainer/CardContainer';
import { ButtonWithIcon } from '../../../components/ButtonWithIcon/ButtonWithIcon';
import { ArrowIcon } from '../../../assets/img/arrow-icon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../constants/spa-routes';

export const ProjectsScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: projectsData } = useGetAllProjectsQuery();

  const handleClickProject = (projectId) => {
    navigate(`${PATH_DASHBOARD.PROJECTS}/${projectId}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <PageContentWrapper
        className="project-page-container"
        isMobileMarginTop={false}
        isProjectBreadcrumbs={false}
      >
        <div className="z-10 flex flex-col gap-1.5">
          <CreateProjectModal />
          <CardContainer className="flex flex-col gap-1.5">
            <div className="card-header">
              <div className="title">
                <span>Choose other project</span>
              </div>
            </div>
            {projectsData &&
              projectsData.map((project) => {
                const isActiveProject = +project.id === +id;
                return (
                  <ButtonWithIcon
                    icon={!isActiveProject && <ArrowIcon variant="right" />}
                    key={project.id}
                    active={isActiveProject}
                    onClick={() => handleClickProject(project.id)}
                  >
                    {project.name}
                  </ButtonWithIcon>
                );
              })}
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};
