import './AddTeamMemberList.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../Button/Button';
import { useGetProjectByIdQuery } from 'src/services';
import { useGetUsersAllQuery } from 'src/services/usersService';

export const AddTeamMemberList = ({ onSubmit }) => {
  const { data: users } = useGetUsersAllQuery();
  const { id } = useParams();
  const { data: currentProject } = useGetProjectByIdQuery({ id: id });
  const [teamMembers, setTeamMembers] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (currentProject && users) {
      const members = users.filter((user) => {
        const userInProject = currentProject.team_members.find(
          (teamMember) => teamMember.user.id === user.id,
        );

        return !userInProject;
      });

      setTeamMembers(members);
    }
  }, [currentProject, users]);

  const submit = (event) => {
    event.preventDefault();

    if (!selected) {
      return;
    }

    const request = {
      user: selected,
    };

    onSubmit && onSubmit(request);
  };

  return (
    <>
      <form id="add-team-member-list-form" onSubmit={submit}>
        <ul className={'add-team-member-list'}>
          {teamMembers &&
            teamMembers.map((teamMember) => {
              return (
                <li className={'add-team-member-list-item'} key={teamMember.id}>
                  <label
                    htmlFor={`${teamMember.id}-team-member`}
                    onClick={() => setSelected(teamMember.id)}
                  >
                    <input
                      name={'team-member'}
                      value={teamMember.id}
                      id={`${teamMember.id}-team-member`}
                      type="radio"
                    />
                    <p>{`${teamMember.first_name} ${teamMember.last_name}`}</p>
                  </label>
                </li>
              );
            })}
        </ul>

        <div className={'input-container buttons'}>
          <Button
            type="submit"
            form="add-team-member-list-form"
            className="blue"
            disabled={!selected}
          >
            Add User
          </Button>
        </div>
      </form>
    </>
  );
};
