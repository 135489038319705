import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from 'src/lib/utils';
import { useAppSelector } from 'src/store';
import { ProductsType } from 'src/constants/products';

const Switch = React.forwardRef(({ className, ...props }, ref) => {
  const { projectType } = useAppSelector((s) => s.projectsSlices);
  const integrationTypes =
    projectType === ProductsType.agent || projectType === ProductsType.customer;
  return (
    <SwitchPrimitives.Root
      className={cn(
        'peer inline-flex h-6 w-11 md:h-7 md:w-14 p-0.5 shrink-0 cursor-pointer items-center rounded-full border border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-white data-[state=unchecked]:border-[#B8C1C666]',
        className,
        projectType === ProductsType.agent &&
          'data-[state=checked]:bg-[#75F3FB] data-[state=checked]:border-[#6DD9E0]',
        projectType === ProductsType.customer &&
          'data-[state=checked]:bg-[#A498FF] data-[state=checked]:border-[#9D87F3]',
        projectType === ProductsType.bi &&
          'data-[state=checked]:bg-[#E8FD65] data-[state=checked]:border-[#DFF800]',
        !projectType &&
          'data-[state=checked]:bg-[#75F3FB] data-[state=checked]:border-[#6DD9E0]',
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none block h-5 w-5 rounded-full data-[state=unchecked]:bg-black bg-background shadow-lg ring-0 transition-transform md:data-[state=checked]:translate-x-7 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
        )}
      />
    </SwitchPrimitives.Root>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
