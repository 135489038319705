export default function MenuIcon({ fillColor = 'none', className = '' }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#05050D" />
      <circle cx="2" cy="10" r="2" fill="#05050D" />
      <circle cx="2" cy="18" r="2" fill="#05050D" />
      <circle cx="10" cy="2" r="2" fill="#05050D" />
      <circle cx="10" cy="10" r="2" fill="#05050D" />
      <circle cx="10" cy="18" r="2" fill="#05050D" />
      <circle cx="18" cy="2" r="2" fill="#05050D" />
      <circle cx="18" cy="10" r="2" fill="#05050D" />
      <circle cx="18" cy="18" r="2" fill="#05050D" />
    </svg>
  );
}
