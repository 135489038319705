export const TimeRangeOptions = [
  {
    value: 'seconds',
    label: 'Seconds',
  },
  {
    value: 'minutes',
    label: 'Minutes',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
];
