import React, { useState } from 'react';
import Button from '../../Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import Input from 'src/components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { CreateDocumentIcon } from 'src/assets/icons/FileIcons';
import { getFileExtension } from 'src/utils/fileUtils';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';

export const CreateDocumentPopup = ({ createDocument, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      name: '',
      text: '',
    },
    resolver: yupResolver(
      object({
        name: stringScheme({ required: true, minLength: 3 }).label(
          'Document Name',
        ),
        text: stringScheme({ required: true, minLength: 3 }).label('Text'),
      }),
    ),
  });

  const submit = (data) => {
    const ext = getFileExtension(data.name);

    if (ext) {
      const allowedExtension = KNOWLEDGE_BASE_FILE_EXTENSIONS.includes(
        `.${ext}`,
      );

      data.name = allowedExtension
        ? data.name
        : data.name.replace(`.${ext}`, '.txt');
    } else {
      data.name = `${data.name}.txt`;
    }

    createDocument(data);
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          disabled={disabled}
          asChild
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer',
          )}
        >
          <Button className="transparent black gap-[10px]">
            Create document
            <CreateDocumentIcon />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New document</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="content">
              <form id="create-new-document" onSubmit={handleSubmit(submit)}>
                <div className={'input-container'}>
                  <Controller
                    control={control}
                    name="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        type={'text'}
                        label={'Document Name'}
                        placeholder={'New Document Name'}
                        invalidMessage={error?.message}
                        value={value}
                        isValid={!error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className={'input-container'}>
                  <Controller
                    control={control}
                    name="text"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        type={'textarea'}
                        label={'Text'}
                        placeholder={'some text...'}
                        invalidMessage={error?.message}
                        value={value}
                        isValid={!error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </form>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button style={{ width: 'auto' }} className="transparent black">
                Cancel
              </Button>
            </DialogClose>
            <Button
              style={{ width: 'auto' }}
              type="submit"
              form="create-new-document"
              className="purple"
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
    // <>
    //   <Popup
    //     trigger={AddIntegrationButton}
    //     modal
    //     nested
    //     className={'add-integration-popup'}
    //     lockScroll={true}
    //   >
    //     {(close) => (
    //       <div className="modal">
    //         <div className="header">
    //           <div className="title">Integrations</div>
    //           <div className="close-button" onClick={close}>
    //             <CloseIcon stroke="#B8C1C6" />
    //           </div>
    //         </div>
    //         <div className="content">
    //           <div className="sub-title">
    //             <h4>Available tools:</h4>
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //   </Popup>
    // </>
  );
};
