import React from 'react';
import { DateRangeOptions } from './DateRangeOptions';

export const DateRangeFilter = ({ value, onChange }) => {
  return (
    <>
      <ul className={'filter-list'}>
        {DateRangeOptions.map((dateRange) => {
          const id = `dateRange${dateRange.label.replace(/ /g, '')}`;

          return (
            <li key={dateRange.value} className={'filter-list-item'}>
              <label htmlFor={id}>
                <input
                  name={'date-range'}
                  value={dateRange.value}
                  id={id}
                  type="radio"
                  checked={value === dateRange.value}
                  onChange={() => onChange(dateRange.value)}
                />
                <p>{dateRange.label}</p>
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};
