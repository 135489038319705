import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import { MenuItem } from 'src/components/Menu/MenuItem';
import './Menu.css';
import { menuItemsGeneral } from 'src/components/Menu/menuItems';
import LogOutButton from 'src/components/LogOutButton/LogOutButton';
import { Drawer } from 'vaul';

export const GeneralItems = ({ isMobile }) => {
  const MenuItemWrapper = ({ children }) => {
    return isMobile ? <Drawer.Close>{children}</Drawer.Close> : children;
  };
  useEffect(() => {}, [isMobile]);
  return (
    <div
      className={clsx(
        'menu-container border-b border-shark general-menu py-7.5 px-6',
      )}
    >
      <p className="pb-1.5 pl-2 text-xs">GENERAL</p>
      {menuItemsGeneral.map((menuItem) => {
        return (
          <MenuItemWrapper key={menuItem.id}>
            <MenuItem
              text={menuItem.title}
              icon={menuItem.icon}
              navigateTo={menuItem.navigateTo}
              disabled={menuItem.disabled}
            />
          </MenuItemWrapper>
        );
      })}
      <LogOutButton className="flex flex-row-reverse" />
    </div>
  );
};
