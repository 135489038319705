import React, { useState } from 'react';
import Button from 'src/components/Button/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import DollarIcon from 'src/assets/img/dollar.svg';

export const PaymentDetailsPopup = ({
  disabled = false,
  subscriptionsData,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <Button className="transparent">
          <span>Payment details</span>
          <img src={DollarIcon} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Payment details</DialogTitle>
        </DialogHeader>
        <div className="lg:py-3.5 py-2 w-full text-right">
          <span className="lg:text-6xl text-4xl font-bold text-ebony">
            {subscriptionsData.cost?.toFixed(2)}$
          </span>
        </div>
        <div className="flex flex-col gap-5 py-4 border-t border-border">
          <table>
            <tbody className="[&_tr:not(:last-child)>td]:pb-5">
              {subscriptionsData.projects.map((project) => {
                return (
                  <tr key={project.id}>
                    <td className="min-w-10 flex items-start pr-4">
                      <span className="lg:text-base text-sm font-bold text-ebony">
                        {project.project_plan.plan?.price?.toFixed(2)}$
                      </span>
                    </td>
                    <td className="w-full">
                      <div className="flex flex-col gap-0.5 text-sm font-medium text-ebony">
                        <p>{project.project_plan.plan.name}</p>
                        <p className="opacity-50">{project.name}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  );
};
