import './ToggleButton.css';
import React, { useState } from 'react';
import Switch from 'react-switch';
export const ToggleButton = ({
  checked,
  onChange,
  label,
  height = 28,
  width = 56,
  handleDiameter = 20,
}) => {
  const handleChange = (nextChecked) => {
    onChange && onChange(nextChecked);
  };

  return (
    <>
      {label && <label>{label}</label>}
      <label>
        <Switch
          onChange={handleChange}
          checked={checked}
          className={checked ? 'toggle-button checked' : 'toggle-button'}
          onColor="#75F3FB"
          offColor="#FFF"
          onHandleColor="#FFF"
          offHandleColor="#05050D"
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow={'0px 1px 1px 0px rgba(0,0,0,0.3)'}
          handleDiameter={handleDiameter}
          borderRadius={50}
          height={height}
          width={width}
        />
      </label>
    </>
  );
};
