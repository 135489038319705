export const DeleteIcon = ({ className = '', fill = '#FF5151' }) => {
  return (
    <svg
      className={className}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4 13.5004L8 10.9004L10.6 13.5004L12 12.1004L9.4 9.50037L12 6.90037L10.6 5.50037L8 8.10037L5.4 5.50037L4 6.90037L6.6 9.50037L4 12.1004L5.4 13.5004ZM3 18.0004C2.45 18.0004 1.97933 17.8047 1.588 17.4134C1.19667 17.022 1.00067 16.551 1 16.0004V3.00037H0V1.00037H5V0.000366211H11V1.00037H16V3.00037H15V16.0004C15 16.5504 14.8043 17.0214 14.413 17.4134C14.0217 17.8054 13.5507 18.001 13 18.0004H3Z"
        fill={fill}
      />
    </svg>
  );
};
