import React from 'react';
import { TaskComplexityOptions } from './TaskComplexityOptions';

export const TaskComplexityFilter = ({ value, onChange }) => {
  return (
    <>
      <ul className={'filter-list'}>
        {TaskComplexityOptions.map((complexity) => {
          const id = `taskComplexity${complexity.label.replace(/ /g, '')}`;

          return (
            <li key={complexity.value} className={'filter-list-item'}>
              <label htmlFor={id}>
                <input
                  name={'task-complexity'}
                  value={complexity.value}
                  id={id}
                  type="radio"
                  checked={value === complexity.value}
                  onChange={() => onChange(complexity.value)}
                />
                <p>{complexity.label}</p>
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};
