import { baseApi } from './base.api';
import { STATISTICS_URL } from '../shared/constants';
import constants from './../pages/AnalyticsPage/constants';
import moment from 'moment';

const sortByDate = (parsedResponse) => {
  parsedResponse.data = parsedResponse.data.sort((item, item2) => {
    return (
      moment(item.name).format('YYYYMMDD') -
      moment(item2.name).format('YYYYMMDD')
    );
  });

  return parsedResponse;
};

const formatDateRange = (date) => {
  return date
    .split(' ')
    .map((date) => moment(date).format('ll'))
    .join(' - ');
};

const parseResponse = (request, response) => {
  const dataKey =
    request.type_of_statistic === 'normalized_metrics'
      ? `${request.metrics[0]}_nm`
      : request.metrics[0];
  const data = response.data[dataKey];
  const result = {
    isByUser: false,
    data: [],
  };

  if (!data) {
    return;
  }

  Object.keys(data).forEach((key) => {
    let isRange = key.includes(' ');
    const time = moment(key);

    result.isByUser = isRange
      ? !moment(key.split(' ')[0]).isValid()
      : !time.isValid();

    if (!result.isByUser) {
      result.data.push({
        date: isRange ? formatDateRange(key) : time.format('ll'),
        [constants[request.metrics[0]]]: data[key],
      });
    } else {
      Object.keys(data[key]).forEach((userKey) => {
        result[key] = result[key] || [];
        isRange = userKey.includes(' ');

        result[key].push({
          date: isRange
            ? formatDateRange(userKey)
            : moment(userKey).format('ll'),
          [constants[request.metrics[0]]]: data[key][userKey],
        });
      });
    }
  });

  return result;
};

export const analyticsService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchAnalytics: build.mutation({
      query: (params) => ({
        url: `/${STATISTICS_URL}`,
        method: 'POST',
        body: params,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        let parsedResponse = parseResponse(arg, baseQueryReturnValue);

        if (!parsedResponse) {
          return baseQueryReturnValue;
        }

        parsedResponse = sortByDate(parsedResponse);

        const { isByUser, data, ...rest } = parsedResponse;

        return {
          ...baseQueryReturnValue,
          parsedResponse: parsedResponse.isByUser ? rest : parsedResponse.data,
          isByUser: parsedResponse.isByUser,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: fetchedAnalytics } = await queryFulfilled;
          dispatch(
            analyticsService.util.updateQueryData(
              'fetchAnalytics',
              undefined,
              (draft) => {
                draft?.push(fetchedAnalytics);
              },
            ),
          );
        } catch {}
      },
    }),
  }),
});

export const { useFetchAnalyticsMutation } = analyticsService;
