import './TeamList.css';
import React, { useEffect } from 'react';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { EditUserPopup } from '../EditUserPopup/EditUserPopup';
import { EditUserLimitPopup } from '../EditUserPopup/EditUserLimitPopup';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { useParams } from 'react-router-dom';
import {
  useGetProjectByIdQuery,
  useNewLimitProjectTeamMembersMutation,
} from 'src/services';
import { clsx } from 'clsx';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useLoader } from 'src/provider/LoaderProvider';

export const TeamList = ({ team = [] }) => {
  const isMdDown = useMediaQuery('(max-width: 767px)');
  const notification = useNotification();
  const loader = useLoader();

  const { id } = useParams();

  const { data: userInfo } = useGetUsersMeQuery();

  const { data: currentProject } = useGetProjectByIdQuery({ id: id });

  const [
    newTeamMemberLimit,
    {
      isSuccess: isSuccessNewTeamMemberLimit,
      isError: isErrorNewTeamMemberLimit,
      error: errorNewTeamMemberLimit,
      isLoading: isLoadingNewTeamMemberLimit,
    },
  ] = useNewLimitProjectTeamMembersMutation();

  const newTeamMemberLimitHandler = ({ teamMemberId, limit }) => {
    newTeamMemberLimit({ id, teamMemberId: teamMemberId, limit });
  };

  useEffect(() => {
    if (isLoadingNewTeamMemberLimit) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingNewTeamMemberLimit]);

  useEffect(() => {
    if (isSuccessNewTeamMemberLimit) {
      notification.success('Limit was updated successfully.');
    }
  }, [isSuccessNewTeamMemberLimit]);

  useEffect(() => {
    if (isErrorNewTeamMemberLimit) {
      notification.error(getErrorMessage(errorNewTeamMemberLimit));
    }
  }, [isErrorNewTeamMemberLimit]);

  const isEmpty = !Boolean(team && team.length);

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;
  const balanceView = isCosupportManager;
  return isEmpty ? (
    <div className="empty-message">
      You are now the only user. Start with adding your support team.
    </div>
  ) : (
    <section className="team-list">
      {isMdDown ? (
        <div className="gap-[6px] flex flex-col mt-[10px]">
          {team &&
            team.map((team) => {
              const isActive = team.user.is_active;
              const usedRequests =
                Number(currentProject?.requests_left) -
                Number(team.requests_left) +
                Number(team.credit_count);

              const isOutOfLimit =
                Number(currentProject?.requests_left) < usedRequests;

              return (
                <div
                  key={team.id}
                  className="flex flex-col border-[rgba(184,193,198,0.4)] rounded-[5px] border"
                >
                  <div className="text-sm border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px]">
                    <div className="col name md:text-base text-sm flex justify-between">
                      <div className="flex items-center gap-[8px]">
                        <UserAvatar user={team.user} />
                        <span
                          className="truncate"
                          title={`${team.user.first_name} ${team.user.last_name}`}
                        >{`${team.user.first_name} ${team.user.last_name}`}</span>
                        <StatusBadge isActive={isActive} />
                      </div>
                      <EditUserPopup teamMember={team} />
                    </div>
                  </div>
                  <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Email:
                    </span>
                    <p title={team.user.email} className="truncate">
                      {team.user.email}
                    </p>
                  </div>
                  <div
                    className={clsx(
                      'flex items-center text-xs p-[15px] gap-[10px]',
                      balanceView &&
                        'border-[rgba(184,193,198,0.4)] border-b-[1px]',
                    )}
                  >
                    <span className="text-[rgba(184,193,198,1)] font-medium	">
                      Role:
                    </span>
                    <p>{team.user.role}</p>
                  </div>
                  {balanceView && (
                    <>
                      <div className="flex justify-between bg-malibu_light items-center text-xs p-[15px] gap-2.5 border-white border-b-2">
                        <span className="text-ebony font-semibold">
                          CoSupport AI Suggestion Balance
                        </span>
                        <EditUserLimitPopup
                          projectRequestLimit={currentProject?.requests_left}
                          teamMember={team}
                          newTeamMemberLimitHandler={newTeamMemberLimitHandler}
                        />
                      </div>
                      <div className="flex bg-malibu_light items-center text-xs p-[15px] gap-2.5 border-white border-b-2">
                        <span
                          className={clsx(
                            isOutOfLimit && 'text-monza',
                            'text-[rgba(184,193,198,1)] font-medium',
                          )}
                        >
                          Limit:
                        </span>
                        <p
                          className={clsx(
                            isOutOfLimit && 'text-monza',
                            'truncate font-medium text-sm',
                          )}
                        >
                          {currentProject?.requests_left}
                        </p>
                      </div>
                      <div className="flex bg-malibu_light items-center text-xs p-[15px] gap-2.5 border-white border-b-2 relative">
                        <span
                          className={clsx(
                            isOutOfLimit && 'text-monza',
                            'text-[rgba(184,193,198,1)] font-medium',
                          )}
                        >
                          Used:
                        </span>
                        <div className="flex items-center gap-2">
                          <p
                            className={clsx(
                              isOutOfLimit && 'text-monza',
                              'truncate font-medium text-sm',
                            )}
                          >
                            {usedRequests}
                          </p>
                          {isOutOfLimit && (
                            <div
                              className="rounded-[100px] bg-[#FFE4E4] flex items-center"
                              title={`Used ${team.credit_count} out of limit`}
                            >
                              <p className="text-[9px] font-medium text-monza px-[5px] leading-4">
                                Out of limit
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex bg-malibu_light items-center text-xs p-[15px] gap-2.5">
                        <span
                          className={clsx(
                            isOutOfLimit && 'text-monza',
                            'text-[rgba(184,193,198,1)] font-medium',
                          )}
                        >
                          Left:
                        </span>
                        <p
                          className={clsx(
                            isOutOfLimit && 'text-monza',
                            'truncate font-medium text-sm',
                          )}
                        >
                          {team.requests_left}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <Table wrapperClassName="pt-14 -mt-14">
            <TableHeader>
              <TableRow>
                <TableHead></TableHead>
                {balanceView && (
                  <TableHead className="bg-malibu_light flex items-center justify-between drop-shadow relative">
                    <div className="fixed h-14 bg-malibu_light -top-14 z-50 w-full left-0 rounded-t-[7px]">
                      <p className="w-full text-center pt-4 text-base text-ebony">
                        <b>CoSupport AI Suggestion Balance</b>
                      </p>
                    </div>
                    <div className="">Limit:</div>
                    <div className="">Used:</div>
                    <div className="">Left:</div>
                    {isCosupportManager && <div className=""></div>}
                  </TableHead>
                )}
                <TableHead>Role:</TableHead>
                <TableHead>Email address:</TableHead>
                {!balanceView && <TableHead>Status:</TableHead>}
                <TableHead className="text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {team &&
                team.map((team) => {
                  const isActive = team.user.is_active;
                  const usedRequests =
                    Number(currentProject?.requests_left) -
                    Number(team.requests_left) +
                    Number(team.credit_count);

                  const isOutOfLimit =
                    Number(currentProject?.requests_left) < usedRequests;

                  return (
                    <TableRow key={team.id}>
                      <TableCell className="font-medium table-fixed max-w-80">
                        <div className="col name md:text-base text-base">
                          <UserAvatar user={team.user} />
                          <span
                            title={`${team.user.first_name} ${team.user.last_name}`}
                          >{`${team.user.first_name} ${team.user.last_name}`}</span>
                        </div>
                      </TableCell>
                      {balanceView && (
                        <TableCell
                          style={{ borderTopWidth: '2px' }}
                          className="col  flex bg-malibu_light border-t-white items-center justify-between drop-shadow min-h-[70px]"
                        >
                          <div className="min-w-28 max-w-28">
                            <div className="text-base font-medium">
                              <p
                                className={clsx(
                                  isOutOfLimit && 'text-monza',
                                  'truncate',
                                )}
                              >
                                {currentProject?.requests_left}
                              </p>
                            </div>
                          </div>
                          <div className="min-w-28 max-w-28 ">
                            <div className="text-base font-medium relative">
                              {isOutOfLimit && (
                                <div
                                  className="absolute -top-[18px] rounded-[100px] bg-[#FFE4E4]"
                                  title={`Used ${team.credit_count} out of limit`}
                                >
                                  <p className="text-[9px] font-medium text-monza px-[5px] leading-4">
                                    Out of limit
                                  </p>
                                </div>
                              )}

                              <p
                                className={clsx(
                                  isOutOfLimit && 'text-monza',
                                  'truncate',
                                )}
                              >
                                {usedRequests}
                              </p>
                            </div>
                          </div>
                          <div className="min-w-28 max-w-28">
                            <div className="text-base font-medium">
                              <p
                                className={clsx(
                                  isOutOfLimit && 'text-monza',
                                  'truncate',
                                )}
                              >
                                {team.requests_left}
                              </p>
                            </div>
                          </div>
                          {isCosupportManager && (
                            <div className="">
                              <EditUserLimitPopup
                                projectRequestLimit={
                                  currentProject?.requests_left
                                }
                                teamMember={team}
                                newTeamMemberLimitHandler={
                                  newTeamMemberLimitHandler
                                }
                              />
                            </div>
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <div className="col role flex-5 text-base">
                          {team.user.role}
                        </div>
                      </TableCell>
                      <TableCell title={team.user.email} className="max-w-80">
                        <div
                          className="col col-email text-base"
                          title={team.user.email}
                        >
                          {team.user.email}
                        </div>
                      </TableCell>
                      {!balanceView && (
                        <TableCell className="text-right">
                          <div className="col status flex-5">
                            <StatusBadge isActive={isActive} />
                          </div>
                        </TableCell>
                      )}
                      <TableCell className="text-right">
                        <div className="col edit flex-5">
                          <EditUserPopup teamMember={team} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </section>
  );
};
