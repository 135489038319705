import './Header.css';
import React from 'react';

const Header = ({ children }) => {
  return (
    <React.Fragment>
      <div className={'auth-header'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <rect width="40" height="40" rx="4" fill="#75F3FB" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.9955 20.0612C32.9955 24.5122 29.3822 28.1204 24.925 28.1204C20.4678 28.1204 16.8545 24.5122 16.8545 20.0612C16.8545 15.6102 20.4678 12.002 24.925 12.0019C29.3822 12.0019 32.9955 15.6102 32.9955 20.0612ZM28.2283 20.0672C28.2283 21.8899 26.7486 23.3676 24.9232 23.3676C23.0979 23.3676 21.6182 21.8899 21.6182 20.0672C21.6182 18.2444 23.0979 16.7667 24.9232 16.7667C26.7486 16.7667 28.2283 18.2444 28.2283 20.0672ZM9.36185 14.3633C10.8754 12.8519 12.9802 12.0029 15.1206 12.0029L15.1206 20.0621L15.1206 28.1213C12.9802 28.1213 10.8754 27.2722 9.36185 25.7608C7.84833 24.2494 6.99805 22.1995 6.99805 20.0621C6.99805 17.9246 7.84833 15.8747 9.36185 14.3633Z"
            fill="#05050D"
          />
        </svg>
        {children ? (
          children
        ) : (
          <h1 className="auth-header-title">
            SuperCharge your
            <br />
            Business 360°
          </h1>
        )}
      </div>
    </React.Fragment>
  );
};

export default Header;
