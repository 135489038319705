import { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { auth } from '../services/auth';
import { useLocation, Navigate } from 'react-router-dom';
import { fetchRequestWithAccessToken } from '../services/FetchRequest';
import { environment } from '../env/env';
import {
  USER_UPDATE_URL,
  USERS_ALL_URL,
  USERS_CREATE_URL,
  USERS_ME_URL,
} from '../shared/constants';

export function useAuth() {
  return useContext(AuthContext);
}
let initialUser = localStorage.getItem('user');
export function AuthProvider({ children }) {
  const isUserExist = initialUser && initialUser !== 'undefined';
  let [user, setUser] = useState(isUserExist ? JSON.parse(initialUser) : null);
  let [userInfo, setUserInfo] = useState(null);
  let [users, setUsers] = useState(null);

  const signin = async (data) => {
    const response = await auth.signin(data);

    if (response instanceof Error) {
      return response;
    }

    setUser(response);
    localStorage.setItem('user', JSON.stringify(response));

    return response;
  };

  const signInWithGoogle = async (code) => {
    const response = await auth.signInWithGoogle(code);

    if (response instanceof Error) {
      return response;
    }

    setUser(response);
    localStorage.setItem('user', JSON.stringify(response));

    return response;
  };

  const signout = async () => {
    const response = await auth.signout();
    setUser(null);
    localStorage.clear();
    return response;
  };

  const signup = async (data) => {
    const password = data.password;
    const response = await auth.signup(data);

    if (response instanceof Error) {
      return response;
    }

    const newUser = {
      ...response,
      password,
    };

    setUser(newUser);

    return newUser;
  };

  const getUserInfo = async () => {
    const url = `${environment.BASE_URL}/${USERS_ME_URL}`;
    const response = await fetchRequestWithAccessToken('GET', url);

    if (response instanceof Error) {
      return response;
    }

    setUserInfo(response);

    return response;
  };
  const updateUserInfo = async (data) => {
    const url = `${environment.BASE_URL}/${USERS_ME_URL}`;
    const response = await fetchRequestWithAccessToken('PUT', url, data);

    if (response instanceof Error) {
      return response;
    }

    setUserInfo(response);

    return response;
  };

  const updateUserInfoById = async (userId, data) => {
    const url = `${environment.BASE_URL}/${USER_UPDATE_URL}`.replace(
      '{user_id}',
      userId,
    );
    const response = await fetchRequestWithAccessToken('PUT', url, data);

    if (response instanceof Error) {
      return response;
    }

    const updatedUsers = users.map((user) => {
      if (user.id === response.id) {
        return response;
      }

      return user;
    });

    setUsers(updatedUsers);

    return response;
  };

  const getAllUsers = async () => {
    const url = `${environment.BASE_URL}/${USERS_ALL_URL}`;
    const response = await fetchRequestWithAccessToken('GET', url);

    if (response instanceof Error) {
      return response;
    }

    setUsers(response);

    return response;
  };

  const createUser = async (data) => {
    const url = `${environment.BASE_URL}/${USERS_CREATE_URL}`;
    const response = await fetchRequestWithAccessToken('POST', url, data);

    if (response instanceof Error) {
      return response;
    }

    setUsers([...users, response]);

    return response;
  };

  let value = {
    user,
    users,
    userInfo,
    signin,
    signInWithGoogle,
    signout,
    signup,
    getUserInfo,
    createUser,
    updateUserInfo,
    updateUserInfoById,
    getAllUsers,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children, redirect = '/' }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to={redirect} state={{ from: location }} replace />;
  }

  return children;
}
