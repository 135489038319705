import { useContext, useState } from 'react';
import { LoaderContext } from '../context/LoaderContext';
import { Spinner } from '../components/Spinner/Spinner';
import { ProgressLoader } from 'src/components/ProgressLoader/ProgressLoader';

export function useLoader() {
  return useContext(LoaderContext);
}
export function LoaderProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isProgress, setIsProgress] = useState(false);
  const updateProgress = (value) => {
    setProgress(value);
  };
  const show = (options) => {
    setIsVisible(true);

    if (options && options.progress) {
      setIsProgress(options.progress);
    }
  };
  const hide = () => {
    setIsVisible(false);
    setIsProgress(false);
    setProgress(0);
  };

  let value = { isVisible, isProgress, progress, show, hide, updateProgress };

  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
}

export const Loader = () => {
  const { isVisible, isProgress, progress } = useLoader();

  return isVisible ? (
    isProgress ? (
      <ProgressLoader value={progress} fullScreen={true} />
    ) : (
      <Spinner fullScreen={true} />
    )
  ) : null;
};
