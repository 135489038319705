export const parseUrl = (url) => {
  const urlParsed = url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .reduce((memo, param) => {
      const data = { ...memo, [param.split('=')[0]]: param.split('=')[1] };
      if (Object.values(data)[0] !== undefined) {
        return data;
      }

      return {};
    }, {});

  return urlParsed;
};
