import React from 'react';

export const PaymentsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
  >
    <path
      d="M14.4 0.777771H1.6C1.17565 0.777771 0.768687 0.941659 0.468629 1.23338C0.168571 1.52511 0 1.92077 0 2.33333V11.6667C0 12.0792 0.168571 12.4749 0.468629 12.7666C0.768687 13.0583 1.17565 13.2222 1.6 13.2222H14.4C14.8243 13.2222 15.2313 13.0583 15.5314 12.7666C15.8314 12.4749 16 12.0792 16 11.6667V2.33333C16 1.92077 15.8314 1.52511 15.5314 1.23338C15.2313 0.941659 14.8243 0.777771 14.4 0.777771ZM14.4 6.22222H1.6V3.88888H14.4V6.22222Z"
      fill="white"
    />
  </svg>
);
