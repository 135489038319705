import './AddTeamMemberForm.css';
import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { useLoader } from 'src/provider/LoaderProvider';
import { useCreateUserMutation } from 'src/services/usersService';
import { getErrorMessage } from 'src/utils/get-error-message';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import {
  emailScheme,
  passwordConfirmScheme,
  passwordScheme,
  stringScheme,
} from 'src/utils/validation-schemes';

export const AddTeamMemberForm = ({ onSubmit }) => {
  const notification = useNotification();
  const [
    createUser,
    {
      data: createUserData,
      isSuccess: isSuccessCreateUser,
      error: errorCreateUser,
      isError: isErrorCreateUser,
      isLoading: isLoadingCreateUser,
    },
  ] = useCreateUserMutation();
  const loader = useLoader();

  const {
    handleSubmit,
    control,
    trigger,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password2: '',
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }).label('Email'),
        first_name: stringScheme({ required: true }).label('First name'),
        last_name: stringScheme({ required: false }).label('Last name'),
        password: passwordScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Password'),
        password2: passwordConfirmScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Confirm password'),
      }),
    ),
  });

  const submit = (data) => {
    const request = {
      email: data.email,
      password: data.password,
      password2: data.password2,
      first_name: data.first_name,
      last_name: data.last_name,
      role: 'support',
    };

    createUser(request);
  };

  useEffect(() => {
    if (isErrorCreateUser) {
      notification.error(getErrorMessage(errorCreateUser));
    }
  }, [isErrorCreateUser]);

  useEffect(() => {
    if (isSuccessCreateUser) {
      onSubmit && onSubmit(createUserData);
    }
  }, [isSuccessCreateUser]);

  useEffect(() => {
    if (isLoadingCreateUser) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingCreateUser]);

  return (
    <>
      <form id="add-team-member-form" onSubmit={handleSubmit(submit)}>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="first_name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                autoFocus
                type={'text'}
                label={'First Name'}
                placeholder={'Josh'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('firstName')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="last_name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'Last Name'}
                placeholder={'Brawn'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'email'}
                label={'Email'}
                placeholder={'email@email.com'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('email')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'password'}
                label={'Password'}
                placeholder={'••••••••••••••••••••••••••••'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="password2"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'password'}
                label={'Confirm Password'}
                placeholder={'••••••••••••••••••••••••••••'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={'input-container buttons'}>
          <Button type="submit" form="add-team-member-form" className="blue">
            Add User
          </Button>
        </div>
      </form>
    </>
  );
};
