import React from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { ProjectSettingsPopup } from './Popups/ProjectSettingsPopup';
import { ProjectSettingsNames } from 'src/constants/projects';
import { clsx } from 'clsx';

export const ProjectSettingDropdown = () => {
  const location = useLocation();

  const disabledEditName = location.pathname === PATH_DASHBOARD.PROJECTS_NEW;

  return (
    <div className={clsx('flex flex-col gap-1.5 transition')}>
      {Object.values(ProjectSettingsNames).map((variant, index) => {
        return (
          <ProjectSettingsPopup
            disabled={index === 0 && disabledEditName}
            key={`${variant}-button-popup`}
            variant={variant}
          />
        );
      })}
    </div>
  );
};
