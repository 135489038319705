import React from 'react';
import Button from '../../Button/Button';

export const ProjectHibernateContent = ({ close = () => {} }) => {
  const projectHibernateHandler = () => {};

  return (
    <>
      <div className="content">
        <div className="sub-title flex flex-col gap-1.5">
          <h4>
            If you select the Hibernate option, all data and statistics are
            stored, but not available for modification. The project is in ‘On
            Hold’ mode.
          </h4>
        </div>
      </div>
      <div className="footer flex justify-end mt-5">
        <div className="help-container"></div>
        <div className="action-buttons flex gap-2.5">
          <Button
            className="transparent black min-w-28"
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            onClick={projectHibernateHandler}
            type="submit"
            form="edit-user-form"
            className="blue min-w-28"
          >
            Done
          </Button>
        </div>
      </div>
    </>
  );
};
