import React, { useEffect } from 'react';
import { IntegrationSettingsPopup } from '../IntegrationSettingsPopup/IntegrationSettingsPopup';
import ZendeskListIcon from '../../assets/img/zendesk-integration-list-icon.svg';
import SlackListIcon from '../../assets/img/slack-integration-list-icon.svg';
import FreshdeskIcon from '../../assets/img/freshdesk-integration-list-icon.svg';
import ZendeskChat from '../../assets/img/zendesk-chat-integration-list-icon.svg';
import ZendeskEmail from '../../assets/img/zendesk-email-integration-list-icon.svg';
import ZohoChat from '../../assets/img/zoho-chat-integration-list-icon.svg';
import { INTEGRATION_SETTING_POPUP_ID } from 'src/shared/constants';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import { Table, TableBody, TableCell, TableRow } from 'src/components/ui/table';
import { clsx } from 'clsx';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { Switch } from 'src/components/ui/switch';
import {
  useDeleteIntegrationMutation,
  useUpdateIntegrationMutation,
} from 'src/services';
import { useParams } from 'react-router-dom';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import { StatusBadgeColors } from 'src/components/StatusBadgeColors/StatusBadgeColors';

const getIntegrationIcon = (type) => {
  switch (type) {
    case 'zendesk': {
      return ZendeskListIcon;
    }
    case 'slack': {
      return SlackListIcon;
    }
    case 'freshdesk': {
      return FreshdeskIcon;
    }
    case 'zendesk_chat': {
      return ZendeskChat;
    }
    case 'zoho_salesiq': {
      return ZohoChat;
    }
    case 'zendesk_email': {
      return ZendeskEmail;
    }
    default:
      return null;
  }
};
const getIntegrationName = (type) => {
  switch (type) {
    case 'zendesk': {
      return 'Zendesk Integration';
    }
    case 'freshdesk': {
      return 'Freshdesk Integration';
    }
    case 'slack': {
      return 'Slack Integration';
    }
    case 'zendesk_chat': {
      return 'Zendesk chat';
    }
    case 'zoho_salesiq': {
      return 'Zoho Desk Chat';
    }
    case 'zendesk_email': {
      return 'Zendesk Email';
    }
    default:
      return null;
  }
};
export const IntegrationsList = ({
  integrations = [],
  isEmpty,
  isFetchingProject,
}) => {
  const { id } = useParams();
  const notification = useNotification();
  const loader = useLoader();

  const [
    deleteIntegration,
    {
      isSuccess: isSuccessDeleteIntegration,
      isError: isErrorDeleteIntegration,
      error: errorDeleteIntegration,
      isLoading: isLoadingDeleteIntegration,
    },
  ] = useDeleteIntegrationMutation();

  const deleteIntegrationHandler = (integrationId) => {
    deleteIntegration({ projectId: id, integrationId });
  };

  useEffect(() => {
    if (isSuccessDeleteIntegration) {
      notification.success('Integration Deleted.');
    }
  }, [isSuccessDeleteIntegration]);

  useEffect(() => {
    if (isErrorDeleteIntegration) {
      notification.error(getErrorMessage(errorDeleteIntegration));
    }
  }, [isErrorDeleteIntegration]);

  useEffect(() => {
    if (isLoadingDeleteIntegration) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingDeleteIntegration]);
  return (
    <Table>
      <TableBody
        className={clsx(
          isLoadingDeleteIntegration &&
            integrations?.length &&
            'animate-pulse pointer-events-none blur-sm',
        )}
      >
        {isEmpty ? (
          integrations.map((integration) => {
            const isActive =
              integration.integration_data.is_active ||
              integration.status === 'active';
            const type = integration.integration_type;
            const openIntegrationId = localStorage.getItem(
              INTEGRATION_SETTING_POPUP_ID,
            );
            let isOpen =
              openIntegrationId && integration.id === +openIntegrationId;
            return (
              <TableRow key={integration.id} className={clsx('transition')}>
                <TableCell
                  className="font-medium table-fixed md:h-[60px] md:w-[50px] h-[55px] w-[45px] min-w-[45px]"
                  padding="p-2.5 pr-0"
                >
                  <div className="col w-fit">
                    <img
                      className="md:h-[40px] md:w-[40px] h-[35px] w-[35px]"
                      src={getIntegrationIcon(type)}
                    />
                  </div>
                </TableCell>
                <TableCell padding="p-2.5">
                  <div className="col role flex-5 text-base flex items-center gap-2.5">
                    <p className="font-semibold">{getIntegrationName(type)}</p>
                    <div className="integration-card-status">
                      <StatusBadgeColors status={integration.status} />
                    </div>
                  </div>
                </TableCell>
                <TableCell padding="p-2.5">
                  <div className="flex sm:gap-8 gap-2 items-center sm:justify-end justify-between">
                    <Switch
                      disabled
                      // id="airplane-mode"
                      // checked={permission}
                      // onCheckedChange={}
                    />
                    <IntegrationSettingsPopup
                      isOpen={isOpen}
                      integration={integration}
                    />
                    <DeletePopup
                      deleteHandler={() =>
                        deleteIntegrationHandler(integration.id)
                      }
                      description={`Are you sure you want to delete the integration?`}
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              padding="p-2.5"
              className="font-medium table-fixed max-w-80"
            >
              <div className="col md:h-10 h-8 flex items-center">
                <LoaderWrapper
                  loader={
                    <div className="flex md:gap-10 gap-4 w-full items-center">
                      <div className="flex items-center w-full gap-5">
                        <Skeleton className="md:h-10 h-8 md:w-10 w-8" />
                        <Skeleton className="h-6 min-w-20 w-4/12" />
                        <Skeleton className="md:h-6 h-3 md:w-14 w-3 rounded-full" />
                      </div>
                      <Skeleton className="min-w-6 h-6" />
                      <Skeleton className="min-w-6 h-6" />
                      <Skeleton className="min-w-6 h-6" />
                    </div>
                  }
                  isLoading={isFetchingProject}
                >
                  <p className="font-medium text-base text-ebony opacity-70">
                    There is no integrations yet.
                  </p>
                </LoaderWrapper>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
