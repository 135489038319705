import './EditUserPopup.css';
import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import Input from 'src/components/Input/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { EditIcon } from 'src/assets/icons/editIcon';

export const EditUserLimitPopup = ({
  teamMember,
  disabled = false,
  newTeamMemberLimitHandler,
  projectRequestLimit,
}) => {
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      limit: teamMember.requests_left,
    },
    resolver: yupResolver(
      object({
        limit: stringScheme({ required: true })
          .test('is-number', 'Limit must be a number', (value) => !isNaN(value))
          .test(
            'is-less-than-limit',
            `Limit must be less than or equal to ${projectRequestLimit}`,
            (value) => Number(value) <= projectRequestLimit,
          )
          .label('Limit'),
      }),
    ),
  });

  const limitWatch = watch('limit');

  useEffect(() => {
    if (Number(limitWatch) === Number(teamMember.requests_left)) {
      reset(watch(), {
        keepValues: false,
        keepDirty: false,
        keepDefaultValues: false,
      });
    }
    trigger('limit');
  }, [limitWatch, teamMember.requests_left, reset]);

  const submit = (formValues) => {
    newTeamMemberLimitHandler({
      teamMemberId: teamMember.id,
      limit: formValues.limit,
    });
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <div className="edit-button">
          <EditIcon fill="#07B5C0" />
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit suggestion balance</DialogTitle>
        </DialogHeader>
        <DialogDescription className="flex flex-col">
          <span className="pb-1">Current limit</span>
          <b className="text-xl	text-ebony font-bold">
            {teamMember.requests_left}
          </b>
        </DialogDescription>

        <div className="grid gap-4 pb-4">
          <form id="edit-user-form" onSubmit={handleSubmit(submit)}>
            <div className={'input-container'}>
              <Controller
                control={control}
                name="limit"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    type={'text'}
                    label={'New limit'}
                    placeholder={'3500'}
                    invalidMessage={error?.message}
                    value={value}
                    isValid={!error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </form>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            disabled={!isDirty}
            style={{ width: 'auto' }}
            type="submit"
            form="edit-user-form"
            className="blue"
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
