const ROOTS_AUTH = 'v1/auth';
const ROOTS_PROJECTS = 'v1/projects';
const ROOTS_USERS = 'v1/users';
const ROOTS_BILLING = 'v1/billing';
export const ACCOUNTS_URL = 'v1/accounts/';

export const PATH_AUTH_API = {
  ROOT: ROOTS_AUTH,
  LOGOUT: `${ROOTS_AUTH}/logout`,
  TOKEN_REFRESH: `${ROOTS_AUTH}/token/refresh`,
};

export const PATH_PROJECTS_API = {
  ROOT: ROOTS_PROJECTS,
  ALL: `${ROOTS_PROJECTS}/all`,
  TEAM_MEMBERS: `team-members`,
  NEW_LIMIT: `new-limit`,
  INTEGRATIONS: `integrations`,
  FILES: `files`,
  ADD_DOCUMENT: `documents`,
};

export const PATH_BILLING_API = {
  ROOT: ROOTS_BILLING,
  PAYMENTS: `${ROOTS_BILLING}/payments`,
  PLANS_ALL: `${ROOTS_BILLING}/plans/all`,
  PAYMENT_METHODS: `${ROOTS_BILLING}/payment-methods`,
  PAYMENT_METHOD_DEFAULT: `${ROOTS_BILLING}/payment-methods/default`,
  CREATE_PROJECT_PLANS: `${ROOTS_BILLING}/project-plans/create`,
  PROJECTS_SUBSCRIPTION: `${ROOTS_BILLING}/projects/subscriptions`,
  CREATE_SUBSCRIPTION: `${ROOTS_BILLING}/create-subscription`,
  DELETE_SUBSCRIPTION: `${ROOTS_BILLING}/delete-item/subscriptions`,
  CHANGE_SUBSCRIPTION: `${ROOTS_BILLING}/change-item/subscriptions`,
  CANCEL_SUBSCRIPTION: `${ROOTS_BILLING}/cancel-subscription`,
  SUBSCRIPTION_ADD_ITEM: `${ROOTS_BILLING}/add-item/subscriptions`,
  INVOICE_PDF: `${ROOTS_BILLING}/invoice/pdf`,
  EXTEND_PROJECT_PLAN: `${ROOTS_BILLING}/project-plans/extend/manual`,
  DE_EXTEND_PROJECT_PLAN: `${ROOTS_BILLING}/project-plans/de-extend/manual`,
  SUBSCRIPTION_PERMISSION_GET: `${ROOTS_BILLING}/project-plans/subscription/permission/get`,
  SUBSCRIPTION_PERMISSION_SET: `${ROOTS_BILLING}/project-plans/subscription/permission/set`,
};

export const PATH_USERS_API = {
  ROOT: ROOTS_USERS,
  ME: `${ROOTS_USERS}/me`,
  ALL: `${ROOTS_USERS}/all`,
  CREATE: `${ROOTS_USERS}/create`,
  UPDATE: `/update`,
};
