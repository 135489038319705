import './ActiveProductsCard.css';
import React from 'react';
import { ToggleButton } from '../ToggleButton/ToggleButton';

const ActiveProductsCard = ({
  icon,
  title = '',
  subTitle = '',
  checked = false,
  onChangeToggle = () => {},
  isMobile = false,
}) => {
  return !isMobile ? (
    <div className="card-body flex flex-row mt-0 gap-[20px] items-center flex-1">
      <div className="icon-wrapper">
        <img src={icon} />
      </div>
      <div className="text-container flex flex-col flex-1">
        <h4>{title}</h4>
        <p>{subTitle}</p>
      </div>
      <div className="toogle-button-container">
        <ToggleButton checked={checked} onChange={onChangeToggle} />
      </div>
    </div>
  ) : (
    <div className="card-body flex flex-row mt-0 gap-[20px] items-center flex-1 bg-[rgba(241,246,246,1)] p-[16px] rounded-[5px] shadow-[0px_1px_1px_0px_rgba(0,0,0,0.2)]">
      <div className="icon-wrapper">
        <img src={icon} height="34px" width="34px" />
      </div>
      <div className="text-container flex flex-col flex-1">
        <h4>{title}</h4>
      </div>
      <div className="toogle-button-container">
        <ToggleButton
          height={19}
          width={39}
          handleDiameter={14}
          checked={checked}
          onChange={onChangeToggle}
        />
      </div>
    </div>
  );
};

export default ActiveProductsCard;
