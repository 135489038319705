import React from 'react';

export const CheckIcon = ({
  height = '9',
  width = '11',
  fill = '#21C007',
  stroke = '#21C007',
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.7769L3.82857 7.94833L1 5.11976L1.72514 4.39462L3.82857 6.4929L9.27486 1.05176L10 1.7769Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
