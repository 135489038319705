import React, { useEffect } from 'react';
import { useTheme } from 'src/provider/ThemeProvider';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import { CurrentSubscriptionPlansSection } from './CurrentSubscriptionPlansSection/CurrentSubscriptionPlansSection';
import { PaymentHistorySection } from './PaymentHistorySection/PaymentHistorySection';
import { PaymentMethodCard } from './PaymentMethod/PaymentMethodCard';
import { PaymentDetailsCard } from './PaymentDetails/PaymentDetailsCard';
import { setIsCompanyChanged } from 'src/store/slices/companySlice';
import { useDispatch } from 'react-redux';

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('green');
    dispatch(setIsCompanyChanged(false));
  }, []);

  return (
    <React.Fragment>
      <PageContentWrapper className={'project-page-container'}>
        <div className={'flex md:flex-row flex-col gap-0.625-rem'}>
          <PaymentDetailsCard />
          <PaymentMethodCard />
        </div>
        <CurrentSubscriptionPlansSection />
        <PaymentHistorySection />
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default SubscriptionPage;
