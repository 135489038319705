import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { SplitElements } from './SplitElements';

export const PaymentMethodForm = ({ stripe, handleClose }) => {
  return (
    <Elements stripe={stripe}>
      <SplitElements handleClose={handleClose} />
    </Elements>
  );
};
