import './AddIntegrationPopup.css';
import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import AddButtonIcon from '../../assets/img/add-button-icon.svg';
import AddZendeskIcon from '../../assets/img/add-integration-zendesk-icon.svg';
import AddZendeskChatIcon from '../../assets/img/add-integration-zendesk-chat-icon.svg';
import AddZendeskEmailIcon from '../../assets/img/add-integration-zendesk-email-icon.svg';
import AddZohoChatIcon from '../../assets/img/add-integration-zoho-chat-icon.svg';
import AddSlackIcon from '../../assets/img/add-integration-slack-icon.svg';
import AddFreshdeskIcon from '../../assets/img/add-integration-freshdesk-icon.svg';
import {
  IntegrationTypeAgent,
  IntegrationTypeBi,
  IntegrationTypeCustomer,
} from './IntegrationTypes';
import { AddIntegrationListItem } from './AddIntegrationListItem';
import { useLoader } from 'src/provider/LoaderProvider';
import { useAddIntegrationMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/store';
import { ProductsType } from 'src/constants/products';

export const AddIntegrationPopup = ({ project, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const notification = useNotification();
  const { id } = useParams();
  const { projectType } = useAppSelector((s) => s.projectsSlices);
  const [
    addIntegration,
    {
      data: addIntegrationRes,
      error: errorAddIntegration,
      isError: isErrorAddIntegration,
      isLoading: isLoadingAddIntegration,
      isSuccess: isSuccessAddIntegration,
    },
  ] = useAddIntegrationMutation();
  const loader = useLoader();

  const createIntegration = (data) => {
    addIntegration({ id, body: data });
  };

  useEffect(() => {
    if (isSuccessAddIntegration) {
      notification.success(
        `Integration ${addIntegrationRes.integration_type} was added.`,
      );
      setOpen(false);
    }
  }, [isSuccessAddIntegration]);

  useEffect(() => {
    if (isLoadingAddIntegration) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingAddIntegration]);

  useEffect(() => {
    if (isErrorAddIntegration) {
      notification.error(getErrorMessage(errorAddIntegration));
    }
  }, [isErrorAddIntegration]);

  const integrationTypes =
    (projectType === ProductsType.agent && IntegrationTypeAgent) ||
    (projectType === ProductsType.customer && IntegrationTypeCustomer) ||
    (projectType === ProductsType.bi && IntegrationTypeBi);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          disabled={disabled}
          asChild
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer',
          )}
        >
          <Button className="transparent">
            <span>Add integration</span>
            <img src={AddButtonIcon} />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Integrations</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="content">
              <div className="sub-title">
                <h4>Available tools:</h4>
              </div>
              <ul className="add-integration-list">
                {integrationTypes &&
                  integrationTypes.map((integrationType) => {
                    const type = integrationType.integration_type;
                    const isExist = Boolean(project.integration);

                    if (isExist) {
                      return null;
                    }

                    switch (type) {
                      case 'zendesk':
                        return (
                          <AddIntegrationListItem
                            key={type}
                            icon={AddZendeskIcon}
                            name={'Zendesk'}
                            onClick={() => createIntegration(integrationType)}
                          />
                        );
                      case 'zoho_salesiq':
                        return (
                          <AddIntegrationListItem
                            key={type}
                            icon={AddZohoChatIcon}
                            name={'Zoho Desk Chat'}
                            onClick={() => createIntegration(integrationType)}
                          />
                        );
                      case 'zendesk_chat':
                        return (
                          <AddIntegrationListItem
                            key={type}
                            icon={AddZendeskChatIcon}
                            name={'Zendesk Chat'}
                            onClick={() => createIntegration(integrationType)}
                          />
                        );
                      case 'zendesk_email':
                        return (
                          <AddIntegrationListItem
                            key={type}
                            icon={AddZendeskEmailIcon}
                            name={'Zendesk Email'}
                            onClick={() => createIntegration(integrationType)}
                          />
                        );
                      case 'freshdesk':
                        return (
                          <AddIntegrationListItem
                            key={type}
                            icon={AddFreshdeskIcon}
                            name={'freshdesk'}
                            onClick={() => createIntegration(integrationType)}
                          />
                        );
                      case 'slack':
                        const request = {
                          ...integrationType,
                          integration_data: {
                            ...integrationType.integration_data,
                            project_name: project.name,
                          },
                        };
                        return (
                          <AddIntegrationListItem
                            key={type}
                            icon={AddSlackIcon}
                            name={'Slack'}
                            onClick={() => createIntegration(request)}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
              </ul>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
