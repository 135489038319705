import React, { useEffect, useState } from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import AnimateHeight from 'react-animate-height';
import { PaymentHistory } from './PaymentHistory';
import { ExpandIcon } from 'src/assets/icons/expandIcon';
import { PaymentHistoryFilterPopup } from './PaymentHistoryFilterPopup/PaymentHistoryFilterPopup';
import { useNotification } from 'src/hooks/useNotification';
import { useGetPaymentsQuery } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from 'src/components/ui/pagination';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import { useAppSelector } from 'src/store';

export const PaymentHistorySection = () => {
  const [subscriptionHeight, setSubscriptionHeight] = useState('auto');
  const [date, setDate] = useState({ from: '', to: '' });
  const [dateDefault, setDateDefault] = useState({ from: '', to: '' });
  const [startingAfter, setStartingAfter] = useState('');
  const [startingAfterArray, setStartingAfterArray] = useState(['']);

  const { companyChanged } = useAppSelector((s) => s.companySlices);

  const notification = useNotification();

  const {
    data: payments,
    error: paymentsError,
    isError: paymentsIsError,
    isFetching: paymentsIsFetching,
  } = useGetPaymentsQuery(
    {
      start_date: date.from,
      end_date: date.to,
      starting_after: startingAfter,
    },
    { refetchOnMountOrArgChange: companyChanged },
  );

  useEffect(() => {
    if (paymentsIsError) {
      notification.error(getErrorMessage(paymentsError));
    }
  }, [paymentsIsError]);

  const goNextPageHandler = () => {
    setStartingAfter(payments?.starting_after);
    setStartingAfterArray((prevState) => [
      payments?.starting_after,
      ...prevState,
    ]);
  };

  const goPrevPageHandler = () => {
    setStartingAfter(startingAfterArray[1]);
    setStartingAfterArray((prevState) => prevState.slice(1));
  };

  const submitDateFilter = (formData) => {
    setDateDefault(formData.date);
    setDate({
      from: transformDateHelper(
        formData.date.from,
        DateFormat.YEAR_MONTH_DAY_DASH_WITH_TIME,
      ),
      to: transformDateHelper(
        formData.date.to,
        DateFormat.YEAR_MONTH_DAY_DASH_WITH_TIME,
      ),
    });
  };

  return (
    <CardContainer className="integration">
      <div className="card-header z-50">
        <div
          className={subscriptionHeight ? 'title' : 'title collapsed'}
          onClick={() => setSubscriptionHeight(subscriptionHeight ? 0 : 'auto')}
        >
          <div className={'yellow-counter'}>{0}</div>
          <span>Payment history</span> <ExpandIcon />
        </div>
        <div className="add-button-container">
          <PaymentHistoryFilterPopup
            submitDateFilter={submitDateFilter}
            dateDefault={dateDefault}
          />
        </div>
      </div>
      <AnimateHeight height={subscriptionHeight}>
        <div className="card-body flex flex-col gap-3">
          <PaymentHistory
            payments={payments?.data}
            isFetching={paymentsIsFetching}
          />
          {payments?.data && (
            <Pagination className="justify-end">
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    disabled={startingAfterArray?.length === 1}
                    onClick={goPrevPageHandler}
                    navigationLabel
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    disabled={!payments?.starting_after}
                    onClick={goNextPageHandler}
                    navigationLabel
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          )}
        </div>
      </AnimateHeight>
    </CardContainer>
  );
};
