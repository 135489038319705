import './EditUserPopup.css';
import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import { useParams } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import { EditUserForm } from './EditUserForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { useDeleteProjectTeamMembersMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useUpdateUserMutation } from 'src/services/usersService';
import { EditIcon } from 'src/assets/icons/editIcon';

export const EditUserPopup = ({ teamMember, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const notification = useNotification();

  const { id } = useParams();
  const loader = useLoader();
  const [
    updateUserInfoById,
    {
      isSuccess: isSuccessUpdateUserInfoById,
      isError: isErrorUpdateUserInfoById,
      error: errorUpdateUserInfoById,
      isLoading: isLoadingUpdateUserInfoById,
    },
  ] = useUpdateUserMutation();
  const [
    deleteTeamMember,
    {
      isSuccess: isSuccessDeleteTeamMember,
      isError: isErrorDeleteTeamMember,
      error: errorDeleteTeamMember,
      isLoading: isLoadingDeleteTeamMember,
    },
  ] = useDeleteProjectTeamMembersMutation();

  const onDeleteTeamMemberClick = () => {
    deleteTeamMember({ id, teamMemberId: teamMember.id });
  };

  const submit = (formData) => {
    updateUserInfoById({ userId: teamMember.user.id, body: formData });
  };

  useEffect(() => {
    if (isSuccessUpdateUserInfoById) {
      notification.success('Team Member Updated.');
      setOpen(false);
    }
  }, [isSuccessUpdateUserInfoById]);

  useEffect(() => {
    if (isSuccessDeleteTeamMember) {
      notification.success('Team Member Deleted.');
      setOpen(false);
    }
  }, [isSuccessDeleteTeamMember]);

  useEffect(() => {
    if (isErrorDeleteTeamMember) {
      notification.error(getErrorMessage(errorDeleteTeamMember));
    }
    if (isErrorUpdateUserInfoById) {
      notification.error(getErrorMessage(errorUpdateUserInfoById));
    }
  }, [isErrorDeleteTeamMember, isErrorUpdateUserInfoById]);

  useEffect(() => {
    if (isLoadingDeleteTeamMember || isLoadingUpdateUserInfoById) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingDeleteTeamMember, isLoadingUpdateUserInfoById]);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <div className="edit-button">
          <EditIcon />
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit user information</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {teamMember && (
            <EditUserForm
              user={teamMember.user}
              onSubmit={(formData) => submit(formData)}
            />
          )}
        </div>
        <DialogFooter>
          <Button
            style={{ width: 'auto' }}
            className="transparent red"
            onClick={() => onDeleteTeamMemberClick()}
          >
            Delete
          </Button>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            style={{ width: 'auto' }}
            type="submit"
            form="edit-user-form"
            className="blue"
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
