import React, { useState } from 'react';
import Button from '../../../../components/Button/Button';
import { PaymentHistoryPdfPopupForm } from './PaymentHistoryPdfPopupForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';

export const PaymentHistoryPdfPopup = ({ disabled = false, payment }) => {
  const [open, setOpen] = useState(false);

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <div className="hover:[&_svg]:opacity-100 [&_svg]:transition-opacity [&_svg]:duration-300 [&_svg]:opacity-50">
          <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 15L12 11L10.6 9.6L9 11.15V7H7V11.15L5.4 9.6L4 11L8 15ZM2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196666 19.0217 0.000666667 18.5507 0 18V6L6 0H14C14.55 0 15.021 0.196 15.413 0.588C15.805 0.98 16.0007 1.45067 16 2V18C16 18.55 15.8043 19.021 15.413 19.413C15.0217 19.805 14.5507 20.0007 14 20H2ZM2 18H14V2H6.85L2 6.85V18Z"
              fill="#05050D"
              fillOpacity="1"
            />
          </svg>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Download the receipt</DialogTitle>
          <DialogDescription>
            Do you really want to download the receipt?
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="border-t w-full" />
          <PaymentHistoryPdfPopupForm payment={payment} close={closeHandler} />
          <div className="border-t w-full" />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            style={{ width: 'auto' }}
            type="submit"
            form="download-receipt-form"
            className="green"
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
