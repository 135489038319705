import { useContext, useState } from 'react';
import { ProjectContext } from '../context/ProjectContext';
import {
  fetchRequestWithAccessToken,
  uploadFileWithAccessToken,
} from '../services/FetchRequest';
import { environment } from '../env/env';
import {
  ALL_PROJECTS_URL,
  INTEGRATION_URL,
  PROJECTS_URL,
  INTEGRATION_FILES_URL,
  ADD_INTEGRATION_URL,
  ADD_TEAM_MEMBER_URL,
  GET_TEAM_MEMBERS_URL,
  INTEGRATION_FILES_ADD_URL,
  INTEGRATION_FILES_DELETE_URL,
  INTEGRATION_DOCUMENT_ADD_URL,
  INTEGRATION_SETTING_POPUP_ID,
  DELETE_TEAM_MEMBERS_URL,
} from '../shared/constants';

export function useProject() {
  return useContext(ProjectContext);
}
export function ProjectProvider({ children }) {
  let [project, setProject] = useState({});

  const getProjectById = async (id) => {
    const url = `${environment.BASE_URL}/${PROJECTS_URL}/${id}`;
    const response = await fetchRequestWithAccessToken('GET', url);

    if (response instanceof Error) {
      return response;
    }

    setProject({
      ...project,
      [id]: response,
    });

    return response;
  };

  const getIntegrationFiles = async (projectId, integrationId) => {
    const integrationFilesUrl = INTEGRATION_FILES_URL.replace(
      '{project_id}',
      projectId,
    ).replace('{integration_id}', integrationId);

    const url = `${environment.BASE_URL}/${integrationFilesUrl}?project_type=cocustomer`;
    const response = await fetchRequestWithAccessToken('GET', url);

    if (response instanceof Error) {
      return response;
    }

    project[projectId].integration.files = response;

    setProject({ ...project });
  };

  const updateIntegration = async (projectId, integrationId, data) => {
    const integrationUrl = INTEGRATION_URL.replace(
      '{project_id}',
      projectId,
    ).replace('{integration_id}', integrationId);

    const url = `${environment.BASE_URL}/${integrationUrl}`;
    const response = await fetchRequestWithAccessToken('PUT', url, data);

    if (response instanceof Error) {
      return response;
    }

    project[projectId].integration = response;
    setProject({ ...project });

    return response;
  };

  const deleteIntegration = async (projectId, integrationId) => {
    const integrationUrl = INTEGRATION_URL.replace(
      '{project_id}',
      projectId,
    ).replace('{integration_id}', integrationId);

    const url = `${environment.BASE_URL}/${integrationUrl}`;
    const response = await fetchRequestWithAccessToken('DELETE', url);

    if (response instanceof Error) {
      return response;
    }

    project[projectId].integration = null;
    setProject({ ...project });
  };
  const deleteTeamMember = async (projectId, teamMemberId) => {
    const deleteTeamMemberUrl = DELETE_TEAM_MEMBERS_URL.replace(
      '{project_id}',
      projectId,
    ).replace('{team_member_id}', teamMemberId);

    const url = `${environment.BASE_URL}/${deleteTeamMemberUrl}`;
    const response = await fetchRequestWithAccessToken('DELETE', url);

    if (response instanceof Error) {
      return response;
    }

    const teamMembers = project[projectId].team_members.filter(
      (teamMember) => teamMember.id !== teamMemberId,
    );

    setProject({
      ...project,
      [projectId]: {
        ...project[projectId],
        team_members: teamMembers,
      },
    });
  };

  const createIntegration = async (projectId, data) => {
    const integrationUrl = ADD_INTEGRATION_URL.replace(
      '{project_id}',
      projectId,
    );
    const url = `${environment.BASE_URL}/${integrationUrl}`;
    const response = await fetchRequestWithAccessToken('POST', url, data);

    if (response instanceof Error) {
      return response;
    }

    project[projectId].integration = response;
    localStorage.setItem(INTEGRATION_SETTING_POPUP_ID, response.id);
    setProject({ ...project });

    return response;
  };
  const addTeamMember = async (projectId, data) => {
    const teamMembersUrl = ADD_TEAM_MEMBER_URL.replace(
      '{project_id}',
      projectId,
    );
    const url = `${environment.BASE_URL}/${teamMembersUrl}`;
    const response = await fetchRequestWithAccessToken('POST', url, data);

    if (response instanceof Error) {
      return response;
    }

    project[projectId].team_members.push(response);

    setProject({ ...project });

    return response;
  };

  const getAllTeamMembers = async (projectId) => {
    const teamMembersUrl = GET_TEAM_MEMBERS_URL.replace(
      '{project_id}',
      projectId,
    );
    const url = `${environment.BASE_URL}/${teamMembersUrl}`;
    const response = await fetchRequestWithAccessToken('GET', url);

    if (response instanceof Error) {
      return response;
    }

    if (project && project[projectId]) {
      project[projectId].team_members = response;
      setProject({ ...project });
    }

    return response;
  };

  const addIntegrationFile = async (projectId, integrationId, data) => {
    const addFileUrl = INTEGRATION_FILES_ADD_URL.replace(
      '{project_id}',
      projectId,
    ).replace('{integration_id}', integrationId);
    const url = `${environment.BASE_URL}/${addFileUrl}`;
    const response = await uploadFileWithAccessToken('POST', url, data);

    if (response instanceof Error) {
      return response;
    }

    const fileName = data.get('file').name;

    project[projectId].integration.files = [
      ...project[projectId].integration.files,
      fileName,
    ];

    setProject({ ...project });

    return response;
  };
  const addIntegrationDocument = async (projectId, integrationId, data) => {
    const addDocumentUrl = INTEGRATION_DOCUMENT_ADD_URL.replace(
      '{project_id}',
      projectId,
    ).replace('{integration_id}', integrationId);
    const url = `${environment.BASE_URL}/${addDocumentUrl}`;
    const response = await fetchRequestWithAccessToken('POST', url, data);

    if (response instanceof Error) {
      return response;
    }

    const fileName = data.name;

    project[projectId].integration.files = [
      ...project[projectId].integration.files,
      fileName,
    ];

    setProject({ ...project });

    return response;
  };
  const deleteIntegrationFile = async (projectId, integrationId, request) => {
    const deleteFileUrl = INTEGRATION_FILES_DELETE_URL.replace(
      '{project_id}',
      projectId,
    ).replace('{integration_id}', integrationId);
    const url = `${environment.BASE_URL}/${deleteFileUrl}`;
    const response = await fetchRequestWithAccessToken('DELETE', url, request);

    if (response instanceof Error) {
      return response;
    }

    const fileName = request.filename;

    project[projectId].integration.files = project[
      projectId
    ].integration.files.filter((file) => file !== fileName);

    setProject({ ...project });

    return response;
  };

  let value = {
    project,
    setProject,
    getProjectById,
    updateIntegration,
    getIntegrationFiles,
    createIntegration,
    deleteIntegration,
    addTeamMember,
    deleteTeamMember,
    getAllTeamMembers,
    addIntegrationFile,
    addIntegrationDocument,
    deleteIntegrationFile,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}
