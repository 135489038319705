import './FiltersPopup.css';
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import React, { useState } from 'react';
import Button from '../../../components/Button/Button';
import { MetricsFilter } from './MetricsFilter/MetricsFilter';
import { Accordion } from '../../../components/Accorion/Accrdion';
import { DateRangeFilter } from './DateRangeFilter/DateRangeFilter';
import { TimeRangeFilter } from './TimeRangeFilter/TimeRangeFilter';
import { TaskComplexityFilter } from './TaskComplexityFilter/TaskComplexityFilter';
// import {DataVisualizationFilter} from "./DataVisualizationFilter/DataVisualizationFilter";
const FiltersButton = () => {
  return (
    <Button className="transparent filters-button">
      <span>More Filters</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
      >
        <path
          d="M11.805 0H0.180011C0.130937 0.00385609 0.0851092 0.0264716 0.051724 0.0633088C0.0183387 0.100146 -0.000134235 0.148479 1.15866e-05 0.198611V0.855555C-0.000439034 0.92289 0.0122585 0.989644 0.0373659 1.05194C0.0624732 1.11423 0.0994893 1.17081 0.146261 1.2184L4.64626 5.80174V9.62118L7.365 11V5.7941L11.865 1.21076C11.9511 1.11618 11.9993 0.99217 12 0.863195V0.198611C12 0.145936 11.9795 0.0954187 11.9429 0.0581719C11.9063 0.0209251 11.8567 0 11.805 0Z"
          fill="#05050D"
        />
      </svg>
    </Button>
  );
};
export const FiltersPopup = ({ request, onClose }) => {
  const [metrics, setMetrics] = useState(request.metrics[0]);
  const [dateRange, setDateRange] = useState(request.date_range);
  const [timeRange, setTimeRange] = useState(request.time_axis);
  const [taskComplexity, setTaskComplexity] = useState(request.quantiles[0]);
  // const [dataVisualization, setDataVisualization] = useState(request.data_visualization);

  const reset = () => {
    setMetrics(request.metrics[0]);
    setDateRange(request.date_range);
    setTimeRange(request.time_axis);
    setTaskComplexity(request.quantiles[0]);
    // setDataVisualization(request.data_visualization);
  };

  const closeWithReset = (close) => {
    reset();
    close();
  };

  const submit = (close) => {
    onClose &&
      onClose({
        ...request,
        metrics: [metrics],
        date_range: dateRange,
        time_axis: timeRange,
        quantiles: [taskComplexity],
        type_of_statistic: 'quantiles',
        // type_of_statistic:
        //   metrics === 'subdomain_first_reply_time'
        //     ? 'quantiles'
        //     : 'normalized_metrics',
        // data_visualization: dataVisualization
      });
    close();
  };

  return (
    <>
      <Popup
        trigger={FiltersButton}
        modal
        nested
        className={'filters-popup'}
        lockScroll={true}
      >
        {(close) => (
          <div className="modal">
            <div className="header">
              <div className="title">Customize your statistics</div>
              <div
                className="close-button"
                onClick={() => closeWithReset(close)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <line
                    x1="16.8497"
                    y1="3.20711"
                    x2="2.70755"
                    y2="17.3492"
                    stroke="#B8C1C6"
                    strokeWidth="2"
                  />
                  <line
                    x1="16.8495"
                    y1="17.3493"
                    x2="2.7074"
                    y2="3.20712"
                    stroke="#B8C1C6"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="sub-title">
              <h4>Available filters:</h4>
            </div>
            <div className="content">
              <Accordion
                className={'filters-metrics-accordion'}
                title={'Metrics'}
              >
                <MetricsFilter value={metrics} onChange={setMetrics} />
              </Accordion>
              <Accordion
                className={'filters-date-range-accordion'}
                title={'Date range (x)'}
              >
                <DateRangeFilter value={dateRange} onChange={setDateRange} />
              </Accordion>
              <Accordion
                className={'filters-time-range-accordion'}
                title={'Time range (y)'}
              >
                <TimeRangeFilter value={timeRange} onChange={setTimeRange} />
              </Accordion>
              <Accordion
                className={'filters-tax-complexity-accordion'}
                title={'Task complexity'}
              >
                <TaskComplexityFilter
                  value={taskComplexity}
                  onChange={setTaskComplexity}
                />
              </Accordion>
              {/*<Accordion className={'filters-data-visualization-accordion'} title={'Data visualization'}>*/}
              {/*    <DataVisualizationFilter value={dataVisualization} onChange={setDataVisualization} />*/}
              {/*</Accordion>*/}
            </div>
            <div className="footer">
              <div className="help-container"></div>
              <div className="action-buttons">
                <Button
                  className="transparent black"
                  onClick={() => closeWithReset(close)}
                >
                  Cancel
                </Button>
                <Button className="blue" onClick={() => submit(close)}>
                  Done
                </Button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};
