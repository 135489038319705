import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import Button from 'src/components/Button/Button';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import {
  passwordConfirmScheme,
  passwordScheme,
} from 'src/utils/validation-schemes';
import { PasswordIcon } from 'src/assets/icons/PasswordIcon';
import Input from 'src/components/Input/Input';
import { useUpdateUsersMeMutation } from 'src/services/usersService';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';

export const ChangePasswordModal = ({ isMobile = false }) => {
  const notification = useNotification();
  const loader = useLoader();
  const [open, setOpen] = React.useState(false);
  const [
    updateUserInfo,
    {
      isLoading: isLoadingUpdateUserInfo,
      isError: isUpdateUserInfoError,
      error: updateUserInfoError,
      isSuccess: isSuccessUpdateUserInfo,
    },
  ] = useUpdateUsersMeMutation();
  const {
    handleSubmit,
    control,
    trigger,
    clearErrors,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      password: '',
      password2: '',
    },
    resolver: yupResolver(
      object({
        password: passwordScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('New password'),
        password2: passwordConfirmScheme({
          required: true,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Confirm new password'),
      }),
    ),
  });

  useEffect(() => {
    if (isSuccessUpdateUserInfo) {
      setOpen(false);
    }
  }, [isSuccessUpdateUserInfo]);
  useEffect(() => {
    if (isUpdateUserInfoError) {
      notification.error(getErrorMessage(updateUserInfoError));
    }
  }, [isUpdateUserInfoError]);

  const submit = async (request) => {
    await updateUserInfo(request);
  };
  useEffect(() => {
    if (isLoadingUpdateUserInfo) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingUpdateUserInfo]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild className={clsx('cursor-pointer')}>
        <div
          className={clsx(
            !isMobile &&
              'border border-ebony border-opacity-10 hover:border-opacity-100',
            'flex max-h-[45px] h-screen items-center gap-2.5 py-3.5 px-2.5 rounded transition [&_p]:hover:opacity-100 [&_svg]:hover:opacity-100',
          )}
        >
          <PasswordIcon
            fill={'#05050D'}
            className="opacity-50 transition hover:opacity-100"
          />
          <p
            title=""
            className="text-ebony opacity-50 text-xs font-medium transition max-w-40 overflow-hidden overflow-ellipsis text-nowrap"
          >
            {isMobile ? 'Set New Password' : 'Set New Account Password'}
          </p>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set new account password</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <form onSubmit={handleSubmit(submit)} id="change-password-form">
            <div className={'input-container'}>
              <Controller
                control={control}
                name="password"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    type={'password'}
                    label={'New password'}
                    placeholder={'••••••••••••••••••••••••••••'}
                    invalidMessage={error?.message}
                    value={value}
                    isValid={!error?.message}
                    onChange={onChange}
                    onBlur={() => trigger('password')}
                    onFocus={() => clearErrors('password')}
                  />
                )}
              />
            </div>
            <div className={'input-container'}>
              <Controller
                control={control}
                name="password2"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    type={'password'}
                    label={'Confirm new password'}
                    placeholder={'••••••••••••••••••••••••••••'}
                    invalidMessage={error?.message}
                    value={value}
                    isValid={!error?.message}
                    onChange={onChange}
                    onBlur={() => trigger('password2')}
                    onFocus={() => clearErrors('password2')}
                  />
                )}
              />
            </div>
          </form>
        </div>
        <DialogFooter>
          <div className={'flex-row flex gap-2.5'}>
            <DialogClose asChild>
              <Button className="transparent black">Cancel</Button>
            </DialogClose>
            <Button
              autoFocus
              type="submit"
              form="change-password-form"
              className="green"
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
