export const PaidCardIcon = ({
  height = '38',
  width = '38',
  fill = '#05050D',
  stroke = '#05050D',
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" rx="19" fill="#F1F3F4" />
      <rect
        x="9"
        y="12.5625"
        width="20"
        height="12.875"
        rx="1.5"
        stroke={stroke}
        strokeOpacity="0.7"
      />
      <path
        d="M9 18.55C8.75147 18.55 8.55 18.7515 8.55 19C8.55 19.2485 8.75147 19.45 9 19.45L9 18.55ZM9 19.45L29 19.45L29 18.55L9 18.55L9 19.45Z"
        fill={fill}
        fillOpacity="0.7"
      />
      <path
        d="M9 21.55C8.75147 21.55 8.55 21.7515 8.55 22C8.55 22.2485 8.75147 22.45 9 22.45L9 21.55ZM9 22.45L29 22.45L29 21.55L9 21.55L9 22.45Z"
        fill={fill}
        fillOpacity="0.7"
      />
    </svg>
  );
};
