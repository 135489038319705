export const ArrowIcon = ({ variant = 'black', className = '', direction }) => {
  const color = variant === 'black' ? '#05050D' : '#07B5C0';
  switch (direction) {
    case 'down':
      return (
        <svg
          className={className}
          width="22"
          height="13"
          viewBox="0 0 22 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.999986 12.0001L11.5 2.00024L21 12.0001"
            stroke={color}
            strokeWidth="2"
          />
        </svg>
      );
    case 'up':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="13"
          viewBox="0 0 22 13"
          fill="none"
        >
          <path
            d="M21 1.00011L10.5 11L1.00001 1.0001"
            stroke={color}
            strokeWidth="2"
          />
        </svg>
      );
    case 'left':
      return (
        <svg
          className={className}
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99999 15L1.00017 7.65011L7.99999 1.00022"
            stroke={color}
            strokeWidth="1.2"
          />
        </svg>
      );
    default:
      return (
        <svg
          className={className}
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.00001 0.99999L7.99983 8.34989L1.00001 14.9998"
            stroke={color}
            strokeWidth="1.2"
          />
        </svg>
      );
  }
};
