import React from 'react';
import authBg from '../../assets/img/auth-bg.png';

export const AuthWrapper = ({ title = '', children }) => {
  return (
    <div className="flex min-h-screen w-full justify-end lg:flex-row flex-col-reverse bg-[#F1F6F6]">
      <div className="lg:p-[min(5vw,80px)] lg:w-[45%] w-full h-full flex justify-center">
        <div className="max-w-md lg:p-0 px-4 py-6">
          <h1 className="font-semibold md:text-2xl text-lg text-black md:mb-5 mb-3">
            {title}
          </h1>
          {children}
        </div>
      </div>
      <img
        className="object-cover lg:w-[55%] w-full lg:max-h-full max-h-[70px]"
        alt="auth background"
        src={authBg}
      />
    </div>
  );
};
