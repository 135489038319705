import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import React from 'react';

export const DropdownMenuDemo = ({
  rowsPerPageOptions = [],
  rowsPerPage,
  onRowsPerPageChange = () => {},
}) => {
  const handleRowsPerPageChange = (rows) => {
    onRowsPerPageChange(Number(rows));
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="opacity-60 hover:opacity-100 transition"
        >
          {rowsPerPage}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-20">
        <DropdownMenuLabel>Rows per page</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={rowsPerPage}
          onValueChange={handleRowsPerPageChange}
        >
          {rowsPerPageOptions?.map((option, i) => (
            <DropdownMenuRadioItem key={i} value={option}>
              {option}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// {rowsPerPageOptions?.map((option, i) => (
//   <DropdownMenuItem
//     onClick={() => handleRowsPerPageChange(option)}
//     key={i}
//   >
//     {option}
//   </DropdownMenuItem>
// ))}
