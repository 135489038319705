import React from 'react';
import { TimeRangeOptions } from './TimeRangeOptions';

export const TimeRangeFilter = ({ value, onChange }) => {
  return (
    <>
      <ul className={'filter-list'}>
        {TimeRangeOptions.map((timeRange) => {
          const id = `timeRange${timeRange.label.replace(/ /g, '')}`;

          return (
            <li key={timeRange.value} className={'filter-list-item'}>
              <label htmlFor={id}>
                <input
                  name={'time-range'}
                  value={timeRange.value}
                  id={id}
                  type="radio"
                  checked={value === timeRange.value}
                  onChange={() => onChange(timeRange.value)}
                />
                <p>{timeRange.label}</p>
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};
