import { clsx } from 'clsx';

export const ProductIcon = ({
  fillColor = 'none',
  width = '32',
  height = '32',
  className = '',
  variant = 'agent',
}) => {
  const colorVariant = () => {
    switch (variant) {
      case 'bi':
        return '#DFF800';
      case 'customer':
        return '#A498FF';
      default:
        return '#75F3FB';
    }
  };
  return (
    <svg
      className={clsx(className, 'max-md:h-5 max-md:w-5')}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.26667C0 1.91025 1.91025 0 4.26667 0H27.7333C30.0898 0 32 1.91025 32 4.26667V27.7333C32 30.0898 30.0898 32 27.7333 32H4.26667C1.91025 32 0 30.0898 0 27.7333V4.26667Z"
        fill={colorVariant()}
      />
      <path
        d="M12.1314 9.93112C10.5326 9.93112 8.96027 10.5708 7.82969 11.7094C6.69912 12.848 6.06396 14.3923 6.06396 16.0025C6.06396 17.6128 6.69912 19.1571 7.82969 20.2957C8.96027 21.4343 10.5326 22.074 12.1314 22.074L12.1314 16.0025L12.1314 9.93112Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.878 22.0738C23.229 22.0738 25.9455 19.3555 25.9455 16.0023C25.9455 12.6492 23.229 9.93091 19.878 9.93091C16.527 9.93091 13.8105 12.6492 13.8105 16.0023C13.8105 19.3555 16.527 22.0738 19.878 22.0738ZM19.869 18.4838C21.2398 18.4838 22.3511 17.3718 22.3511 16C22.3511 14.6283 21.2398 13.5163 19.869 13.5163C18.4981 13.5163 17.3868 14.6283 17.3868 16C17.3868 17.3718 18.4981 18.4838 19.869 18.4838Z"
        fill="#05050D"
      />
    </svg>
  );
};
