import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { cn } from 'src/lib/utils';
import { useTheme } from 'src/provider/ThemeProvider';

const Progress = React.forwardRef(({ className, value, ...props }, ref) => {
  const { config } = useTheme();

  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        'relative h-4 w-full overflow-hidden rounded-full bg-secondary',
        className,
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className="h-full w-full flex-1 bg-primary transition-all"
        style={{
          transform: `translateX(-${100 - (value || 0)}%)`,
          backgroundColor: config.colorFill,
        }}
      />
      <span className={'select-none'}>{parseInt(value)}%</span>
    </ProgressPrimitive.Root>
  );
});
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
