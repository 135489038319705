import React from 'react';
import { Progress } from 'src/components/ui/progress';
import './ProgressLoader.css';

export const ProgressLoader = ({ value, fullScreen = false }) => {
  return fullScreen ? (
    <div className="progress-loader-wrapper">
      <Progress value={value} className="progress-loader" />
    </div>
  ) : (
    <Progress value={value} className="progress-loader" />
  );
};
