import React from 'react';

import './UninstallExtension.css';
import LogoImg from '../../assets/img/icon-128.png';

const UninstallExtension = () => {
  return (
    <React.Fragment>
      <img className={'App_Logo'} src={LogoImg} alt="logo" />
      <h2 className={'Main_Title'}>Thank you for using our extension</h2>
    </React.Fragment>
  );
};

export default UninstallExtension;
