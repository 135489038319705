import React from 'react';
import { SettingIcon } from 'src/assets/img/setting-icon';
import { ProjectSettingDropdown } from './ProjectSettingDropdown';
import { clsx } from 'clsx';
import { useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';

export const ProjectSettings = ({ disabled = false }) => {
  const location = useLocation();

  const disabledSettings = location.pathname === PATH_DASHBOARD.PROJECTS_NEW;

  return (
    <div
      className={clsx(
        (disabled || disabledSettings) && 'pointer-events-none opacity-50',
        'flex items-center relative z-50',
      )}
    >
      <Popover>
        <PopoverTrigger asChild>
          <div className="dropdown-trigger flex gap-2 text-[#05050D] cursor-pointer py-3.5 px-2.5 border rounded border-ebony transition border-opacity-10 [&_span]:hover:opacity-100 hover:border-opacity-100 [&_svg]:hover:opacity-100">
            <SettingIcon className="opacity-50 transition" />
            <span className="text-xs font-medium opacity-50 transition">
              Project settings
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent className="shadow transition bg-[#FFFFFF66] backdrop-blur rounded-lg w-max h-max">
          <ProjectSettingDropdown />
        </PopoverContent>
      </Popover>
    </div>
  );
};
