import moment from 'moment/moment';

const DATE_FORMAT = 'DD MMM YYYY, HH:mm:ss';

export const getFileExtension = (path) => {
  const basename = path.split(/[\\/]/).pop();
  const pos = basename.lastIndexOf('.');

  if (basename === '' || pos < 1) {
    return '';
  }

  return basename.slice(pos + 1);
};

export const getFoldersStructure = (files) => {
  const tree = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const isInRoot = !Boolean(file.path) || file.path === '/';
    const path = isInRoot ? [''] : file.path.split('/');
    let currentLevel = tree;
    let parent;

    path.push(file.name);

    for (let j = 0; j < path.length; j++) {
      const isLastItem = j === path.length - 1;
      const part = path[j];
      const ext = getFileExtension(part);
      const existingPath = findWhere(currentLevel, 'name', part);

      if (existingPath) {
        currentLevel = existingPath.children;
        parent = existingPath;
      } else {
        let newPart = isLastItem
          ? {
              ...file,
              parent,
              type: 'file',
              disabled: file.state !== 'uploaded',
              path: `${file.path || ''}/${file.name}`.replace('//', '/'),
              localDate: getLocalFileDate(file.updated_at),
            }
          : {
              name: part,
              parent,
              type: 'folder',
              path: parent ? `${parent.path}/${part}`.replace('//', '/') : '/',
              children: [],
            };

        currentLevel.push(newPart);
        currentLevel = newPart.children;
        parent = newPart;
      }
    }
  }

  tree[0].root = true;

  return tree[0];

  function findWhere(array, key, value) {
    let t = 0;
    while (t < array.length && array[t][key] !== value) {
      t++;
    }

    return t < array.length ? array[t] : false;
  }
};

export const sortByType = (array, type) => {
  return array
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (a.type === type ? -1 : 1));
};

export const createFolder = (name, parent) => {
  return {
    name,
    parent: parent,
    type: 'folder',
    path: `${parent ? parent.path : ''}/${name}`.replace('//', '/'),
    children: [],
  };
};

export const createFile = (file, parent) => {
  moment.utc(file.updated_at).toDate();
  return {
    ...file,
    parent,
    type: 'file',
    path: `${file.path}/${file.name}`,
    localDate: getLocalFileDate(file.updated_at),
  };
};

export const updateBranchInTree = (tree, branch, newBranch) => {
  if (tree.path === branch.path) {
    Object.assign(tree, { ...newBranch });

    return tree;
  } else {
    for (let i = 0; i < tree.children.length; i++) {
      if (tree.children[i].type === 'folder') {
        updateBranchInTree(tree.children[i], branch, newBranch);
      }
    }
  }
};

export const findActiveBranch = (tree) => {
  const activePath = sessionStorage.getItem('fileManager-active-path');
  let result;

  if (!activePath) {
    return false;
  }

  if (tree.path === activePath) {
    return tree;
  }

  if (tree.children && tree.children.length) {
    for (let i = 0; i < tree.children.length; i++) {
      if (tree.children[i].type === 'folder') {
        result = findActiveBranch(tree.children[i]);

        if (result) {
          return result;
        }
      }
    }
  }
};

export const getLocalFileDate = (date) => {
  const utcDate = moment.utc(date).toDate();

  return moment(utcDate).format(DATE_FORMAT);
};

export const getFoldersArrayFromTree = (tree) => {
  let folders = [];

  if (tree.type === 'folder') {
    folders.push(tree);
  }

  for (let i = 0; i < tree.children.length; i++) {
    const children = tree.children[i];

    if (children.type === 'folder') {
      const childFolders = getFoldersArrayFromTree(children);

      folders = [...folders, ...childFolders];
    }
  }

  return folders;
};

export const getCheckedFiles = (branch) => {
  let folders = [];

  if (branch.checked) {
    folders.push(branch);
  }

  for (let i = 0; i < branch.children.length; i++) {
    const children = branch.children[i];

    if (children.type === 'folder') {
      const childFolders = getCheckedFiles(children);

      folders = [...folders, ...childFolders];
    } else if (children.checked) {
      folders.push(children);
    }
  }

  return folders;
};

export const setCheckedChildren = (branch, checked) => {
  checked = checked === undefined ? branch.checked : checked;

  for (let i = 0; i < branch.children.length; i++) {
    const children = branch.children[i];

    children.checked = checked;

    if (children.type === 'folder') {
      setCheckedChildren(children);
    }
  }
};

export const updateCheckedPaths = (files, path) => {
  return files.map((file) => {
    if (file.parent && file.parent.checked) {
      file.newPath = `${file.parent.newPath}/${file.name}`;
    } else {
      file.newPath = `${path}/${file.name}`;
    }

    return file;
  });
};

export const getRootFolder = (item) => {
  let result;

  if (item.root) {
    result = item;
  }

  if (item.parent) {
    result = getRootFolder(item.parent);
  }

  return result;
};

export const getChildrenFiles = (item) => {
  let files = [];

  for (let i = 0; i < item.children.length; i++) {
    const children = item.children[i];

    if (children.type === 'folder') {
      const childFolders = getChildrenFiles(children);

      files = [...files, ...childFolders];
    } else {
      files.push(children);
    }
  }

  return files;
};
